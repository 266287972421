import instance from "./axios";

export const customerTransferApproval = async (id, data) => {
  return instance
    .put(`/customers/wallet/transfer/approval/${id}`, data)
    .then((res) => res);
};

export const getWalletTransferData = async (
  startDate = "",
  endDate = "",
  status = "",
  page = 1,
  limit = 10
) => {
  return instance
    .get(
      `/customers/wallet/transfer?startDate=${startDate}&endDate=${endDate}&status=${status}&page=${page}&limit=${limit}`
    )
    .then((res) => res);
};
