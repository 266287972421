import instance from "./axios";

export const getTelopayHistory = async (
  limit,
  activePage,
  paymentMethod,
  service,
  startDate,
  endDate
) => {
  try {
    return instance.get(
      `/telopay/purchaseItem?limit=${limit}&page=${activePage}&paymentMethod=${paymentMethod}&service=${service}&startDate=${startDate}&endDate=${endDate}`
    );
  } catch (err) {}
};

export const getTelopayCardData = () => {
  return instance.get(`/telopay/purchaseItem/stats`);
};
