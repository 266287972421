import axios from "axios";
import { Auth } from "aws-amplify";
const instance = axios.create({
  baseURL: `https://${process.env.REACT_APP_BASEURL}/direct-payments/1`,
});
instance.interceptors.request.use(
	async (config) => {
    try{
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      config.headers.Authorization = `Bearer ${token}`;
  
      
    }
    
     catch (e){
     }
     return config;
		// Add authorization token to the request headers
		
	},
	(error) => {
		// Handle request error here.
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
  (response) => {
    // Add any response data modifications here.
    return response;
  },
  (error) => {
    // Handle response error here.
    return Promise.reject(error);
  }
);

export default instance;
