import React, { useState, useEffect } from "react";
import "./SideDrawer.css";
import {
  RiUpload2Fill,
  RiAdvertisementFill,
  RiCoupon3Line,
} from "react-icons/ri";
import { FaMoneyCheckAlt, FaTruck } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import {
  MdInsertChart,
  MdTransferWithinAStation,
  MdCreditCard,
  MdAddLocation,
} from "react-icons/md";
import { IoMdWallet, IoMdBusiness } from "react-icons/io";
import { BiTransfer } from "react-icons/bi";
import { HiOutlineBookmark } from "react-icons/hi";
import { VscSettings } from "react-icons/vsc";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  TrendingUp,
  ChevronLeft,
  ChevronRight,
  Category,
} from "@material-ui/icons";
import { TbBusinessplan } from "react-icons/tb";
import { useTranslation } from "react-i18next";

const Sidebar = ({ children, isOpen }) => {
  const { t, i18n } = useTranslation();

  const useStyles = makeStyles((theme) => ({
    listItemText: {
      fontSize: "13px",
      color: "#fff",
    },
    listItem: {
      "&:hover": {
        backgroundColor: "rgba(14, 75, 116, 0.4) ",
      },
    },
    icon: {
      color: "red",
    },
  }));
  const classes = useStyles();
  const [isOpenState, setIsOpen] = useState(null);
  const [routes, setRoutes] = useState([
    {
      id: 1,
      path: "/",
      name: t("Stats"),
      Icon: <TrendingUp style={{ color: "#fff" }} />,
      active: true,
      hide: true,
    },
    {
      id: 2,
      path: "/Kpi",
      name: t("KPI"),
      Icon: <MdInsertChart color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 22,
      path: "/Transactions",
      name: t("Transactions"),
      Icon: <MdCreditCard color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 19,
      path: "/Commission",
      name: t("Commission"),
      Icon: <TbBusinessplan style={{ color: "#fff" }} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 33,
      path: "/Refund",
      name: t("Refund"),
      Icon: <MdCreditCard color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    // {
    //   id: 23,
    //   path: `Transactions?type=link`,
    //   name: "Link",
    //   active: false,
    //   hide: false
    // },
    // {
    //   id: 24,
    //   path: `Transactions?type=qr`,
    //   name: "QR Code",
    //   active: false,
    //   hide: false
    // },
    // {
    //   id: 25,
    //   path: `Transactions?type=pickup`,
    //   name: "Bayqi Pickup",
    //   active: false,
    //   hide: false
    // },
    // {
    //   id: 26,
    //   path: `Transactions?type=delivery`,
    //   name: "Bayqi Delivery",
    //   active: false,
    //   hide: false
    // },
    {
      id: 3,
      path: "/Finances",
      name: t("Finances"),
      Icon: <FaMoneyCheckAlt color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 4,
      path: "/TransferFriend",
      name: t("Transfer Friend"),
      Icon: <MdTransferWithinAStation color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 5,
      path: "/UploadWallet",
      name: t("Upload Wallet"),
      Icon: <RiUpload2Fill color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 88,
      path: "/Sellers",
      name: t("Sellers"),
      Icon: <ImUsers color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 6,
      path: "/BankTransferUser",
      name: t("User Bank Transfer"),
      Icon: <BiTransfer color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 7,
      path: "/BankTransferSeller",
      name: t("Seller Bank Transfer"),
      Icon: <BiTransfer color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 8,
      path: "/Users",
      name: t("Users"),
      Icon: <ImUsers color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 9,
      path: "/WalletBalance",
      name: t("Wallet Balance"),
      Icon: <IoMdWallet color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 5,
      path: "/Advertisements",
      name: t("Advertisements"),
      Icon: <RiAdvertisementFill color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 6,
      path: "/PromosAndDiscounts",
      name: t("Promos & Discounts"),
      Icon: <RiCoupon3Line color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 7,
      path: "/Blogs",
      name: t("Blogs"),
      Icon: <HiOutlineBookmark color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 8,
      path: "/Telopay",
      name: t("Telopay"),
      Icon: <MdCreditCard color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    // {
    //   id: 9,
    //   path: "/Companies",
    //   name: "Companies",
    //   Icon: <IoMdBusiness color={"white"} size={"1.3rem"} />,
    //   active: false,
    //   hide: false,
    // },
    {
      id: 101,
      path: "/ShopCenters",
      name: t("Shop Centers"),
      Icon: <VscSettings color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    // {
    //   id: 10,
    //   path: "/DeliveryZones",
    //   name: t("Delivery Zones"),
    //   Icon: <MdAddLocation color={"white"} size={"1.3rem"} />,
    //   active: false,
    //   hide: false,
    // },
    {
      id: 10,
      path: "/BayqiLogistics",
      name: t("Bayqi Logistics"),
      Icon: <FaTruck color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 11,
      path: "/Services",
      name: t("Domains"),
      Icon: <VscSettings color={"white"} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
    {
      id: 12,
      path: "/Categories",
      name: t("Categories"),
      Icon: <Category style={{ color: "#fff" }} size={"1.3rem"} />,
      active: false,
      hide: false,
    },
  ]);
  const selectTab = (index) => {
    let dupRoutes = [...routes];
    dupRoutes.forEach((element, i) => {
      if (index === i) {
        dupRoutes[i].active = true;
      } else {
        dupRoutes[i].active = false;
      }
    });
    setRoutes(dupRoutes);
  };
  const updateDimensions = () => {
    if (window.innerWidth < 900) {
      setIsOpen(false);
    } else if (window.innerWidth > 900) {
      setIsOpen(true);
    }
  };
  const [transactionListVisibility, setTransactionListVisibility] =
    React.useState(false);
  const [logisticsListVisibility, setLogisticListVisibility] =
    React.useState(false);
  const [showTransactionChildren, setShowTransactionChildren] =
    React.useState(false);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  useEffect(() => {
    setIsOpen(isOpen);
    return () => {
      setIsOpen(!isOpen);
    };
  }, [isOpen]);

  return (
    <div
      className="hideScrollbar"
      style={{
        display: "flex",
        backgroundColor: "#f7f7f7",
        width: "100%",
        overflowY: "scroll",
        marginTop: "30px",
        position: "absolute",
        bottom: "0",
      }}
    >
      {isOpenState ? (
        <div
          className="open hideScrollbar"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            paddingTop: "70px",
            paddingBottom: "60px",
          }}
        >
          <List>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2rem",
                paddingRight: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <ChevronLeft
                onClick={() => setIsOpen(!isOpenState)}
                style={{ color: "#fff", cursor: "pointer" }}
              />
            </div>
            {routes.map((item, index) => (
              <ListItem
                key={index}
                disableRipple
                className={classes.listItem}
                button
                component={Link}
                to={item.path}
                onClick={() => {
                  selectTab(index);
                }}
                style={{
                  backgroundColor: item.active
                    ? "rgba(14, 75, 116, 0.8)"
                    : "rgba(0,0,0,0)",
                }}
              >
                <ListItemIcon>{item.Icon}</ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={item.name}
                />
              </ListItem>
            ))}
          </List>
        </div>
      ) : (
        <div
          // previous working fine things
          // className={"side-drawer"} style={{ overflow: 'hidden' }}

          // mine
          className="side-drawer hideScrollbar"
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            paddingTop: "70px",
          }}
        >
          <List>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2rem",
                paddingBottom: "1rem",
              }}
            >
              <ChevronRight
                onClick={() => setIsOpen(!isOpenState)}
                style={{ color: "#fff", cursor: "pointer" }}
              />
            </div>
            {routes.map((item, index) => (
              <Tooltip placement="right-end" title={item.name} key={index}>
                <ListItem
                  className={classes.listItem}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  button
                  component={Link}
                  to={item.path}
                  key={index}
                >
                  <ListItemIcon
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {item.Icon}
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </div>
      )}
      <div
        style={{
          width: "100%",
          backgroundColor: "#f7f7f7",
          // padding: "3% 0px 3% 0px",
          height: "100vh",
          padding: "90px 24px 0px 24px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
