import instance from "./axios";

export const getAllTransactions = async (
  limit,
  activePage,
  transactionType,
  startDate,
  endDate,
  paymentType
) => {
  try {
    return instance.get(
      `/transactions/history?limit=${limit}&page=${activePage}&customerId&transactionId=&status&transactionType=${transactionType}&paymentType=${paymentType}&startDate=${startDate}&endDate=${endDate}`
    );
  } catch (err) {
    return err;
  }
};

export const getDeliveryTransactions = async (
  startDate,
  endDate,
  activePage,
  limit,
  selectedStatus
) => {
  try {
    return instance.get(
      `/transactions/history?deliveryMethod=Admin Delivery&startDate=${startDate}&endDate=${endDate}&page=${activePage}&limit=${limit}&orderStatus=${selectedStatus}`
    );
  } catch (err) {
    return err;
  }
};


export const getEcomTransaction = async (
  limit,
  activePage,
  selectedStatus,
  selectedMethod,
  startDate,
  endDate,
  transactionId
) => {
  try {
    return instance.get(
      `/transactions/history?limit=${limit}&orderStatus=${selectedStatus}&page=${activePage}&paymentMethod=${selectedMethod}&startDate=${startDate}&endDate=${endDate}&transactionId=${transactionId}&transactionType=MARKET_PLACE`
    );
  } catch (err) {
    return err;
  }
};

export const getLinkTransactions = async (
  limit,
  selectedStatus,
  activePage,
  selectedMethod,
  startDate,
  endDate
) => {
  try {
    return instance.get(
      `/transactions/history?limit=${limit}&orderStatus=${selectedStatus}&page=${activePage}&method=${selectedMethod}&startDate=${startDate}&endDate=${endDate}&transactionType=PAYMENT_PER_LINK`
    );
  } catch (err) {
    return err;
  }
};

export const getPickupTransaction = async (
  startDate = "",
  endDate = "",
  page = 1,
  limit = 10,
  selectedStatus = ""
) => {
  try {
    return instance.get(
      `/transactions/history?limit=${limit}&page=${page}&method=&sellerId=&orderStatus=${selectedStatus}&customerId=&startDate=${startDate}&endDate=${endDate}&transactionType=&deliveryMethod=Bayqi Pickup`
    );
  } catch (err) {
    return err;
  }
};

export const getPostOfficeTransactions = async (
  startDate = "",
  endDate = "",
  page = 1,
  limit = 10,
  selectedStatus = ""
) => {
  try {
    return instance.get(
      `/transactions/history?limit=${limit}&page=${page}&method=&sellerId=&orderStatus=${selectedStatus}&customerId=&startDate=${startDate}&endDate=${endDate}&transactionType=&deliveryMethod=Post Office`
    );
  } catch (err) {
    return err;
  }
};

export const getQrCatalogueTransactions = async (
  limit,
  activePage,
  selectedMethod,
  startDate,
  endDate
) => {
  try {
    return instance.get(
      `/transactions/history?limit=${limit}&page=${activePage}&transactionType=QR_CATALOGE_SCAN&paymentMethod=${selectedMethod}&startDate=${startDate}&endDate=${endDate}`
    );
  } catch (err) {
    return err;
  }
};


