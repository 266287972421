import React, { useState } from "react";
import { Row, Col,Spinner } from "react-bootstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import {
  updateRequestStatus
} from "../../../api/UploadWallet/api";

export default function ModalLayout({ toggle, data, getUploadWalletRequests }) {
    const { t, i18n } = useTranslation();
    // react-select styles
    const customStyles = {
      option: (defaultStyles) => ({
        ...defaultStyles,
        fontSize: "12px",
      }),
      singleValue: (defaultStyles) => ({
        ...defaultStyles,
        fontSize: "12px",
      }),
  
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          fontSize: "12px",
        };
      },
    };
    const [loading, setLoading] = useState(false);
  
    const [status, setStatus] = useState({
      value: data.status,
      label: data.status,
    });
  
    const updateStatus = () => {
      setLoading(true);
      
      // axios
      //   .put(`${baseUrl}/customers/wallet/upload/approval/${data._id}`, {
      //     status: status.value,
      //   })
      
      updateRequestStatus(data._id, {
        status: status.value,
      })
      
        .then((res) => {
          setLoading(false);
          toggle(true);
          getUploadWalletRequests();
        })
        .catch((err) => {
          setLoading(false);
        }).finally(
          ()=>{
            toggle(true);
          }
        )
        
        
    };
  
    return (
      <div>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Transaction ID")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.transactionId}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Date & Time")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {new Date(data.createdAt).toLocaleDateString()}
              {" - "}
              {new Date(data.createdAt).toLocaleTimeString()}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Wallet ID")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.walletId}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Name")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {`${data.customerDetails.firstName} ${data.customerDetails.surName}`}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Amount")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {" "}
              {new Intl.NumberFormat().format(data.amount)} AKZ
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Operation Number")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.operationNumber}
            </span>
          </Col>
        </Row>
  
        {/* <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Status")}
            </span>
          </Col>
          <Col md={4}>
            <Select
              styles={customStyles}
              value={status}
              options={[{ value: "APPROVED", label: "APPROVED" },
              { value: "REJECTED", label: "REJECTED" }]}
              onChange={(item) => setStatus(item)}
            />
          </Col>
          <Col md={2} className={{ padding: "0px" }}>
            {status.value !== data.status && (
              <button
                type="button"
                style={{
                  backgroundColor: "#049EFF",
                  color: "#fff",
                  border: "none",
                  fontSize: "12px",
                  padding: "8px",
                  borderRadius: "4px",
                  fontWeight: "bold",
                }}
                onClick={updateStatus}
              >
                {loading ? (
                  <Spinner
                    size="sm"
                    style={{ color: "white" }}
                    animation="border"
                  />
                ) : (
                  t("Update")
                )}
              </button>
            )}
          </Col>
        </Row> */}
        <Row>
  <Col md={6}>
    <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
      {t("Status")}
    </span>
  </Col>
  <Col md={4}>
    {status.value === "PENDING" ? (
      <Select
        styles={customStyles}
        value={status}
        options={[
          { value: "APPROVED", label: "APPROVED" },
          { value: "REJECTED", label: "REJECTED" }
        ]}
        onChange={(item) => setStatus(item)}
      />
    ) : (
      <span>{status.value}</span>
    )}
  </Col>
  <Col md={2} className={{ padding: "0px" }}>
    {status.value !== data.status && (
      <button
        type="button"
        style={{
          backgroundColor: "#049EFF",
          color: "#fff",
          border: "none",
          fontSize: "12px",
          padding: "8px",
          borderRadius: "4px",
          fontWeight: "bold",
        }}
        onClick={updateStatus}
      >
        {loading ? (
          <Spinner
            size="sm"
            style={{ color: "white" }}
            animation="border"
          />
        ) : (
          t("Update")
        )}
      </button>
    )}
  </Col>
          </Row>

      </div>
    );
}

