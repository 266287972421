import instance from "./axios";

export const updateDeliveryStatus = async (id, status) => {
  try {
    return instance.put(`/delivery-zones/${id}`, {
      status,
    });
  } catch (e) {
    return e;
  }
};

export const deleteDeliveryZones = async (id) => {
  try {
    return instance.delete(`/delivery-zones/${id}`);
  } catch (e) {
    return e;
  }
};

export const getDeliveryZones = async (activePage = "", limit = "") => {
  try {
    return instance.get(`/delivery-zones?page=${activePage}&limit=${limit}`);
  } catch (e) {
    return e;
  }
};
