import React from "react";
import { useHistory } from "react-router-dom";
import { Image, Spinner, Row, Col } from "react-bootstrap";
import Logo from "../../Assets/images/logo.png";
import { useAppContext } from "../../lib/contextLib";
import "./Login.scss";

import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";

// user created with following credentials
// const signUp = async () => {
//   try {
//     const { user } = await Auth.signUp({
//       username: "+923045003046",
//       password: "Test@123",
//       attributes: {
//         email: "test@example.com",
//       },
//     });
//     
//   } catch (error) {
//     
//   }
// };

// const confirmSignUp = async () => {
//   try {
//     await Auth.confirmSignUp("noumanjaved3341", "399236");
//   } catch (error) {
//     
//   }
// };

export default function Login() {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [buttonDisable, setButtonDisable] = React.useState(false);

  // context
  const { isAuthenticated, userHasAuthenticated } = useAppContext();

  // sign in function
  async function signIn(e) {
    try {
      
      setError(null);
      setLoading(true);
      setButtonDisable(true);
      e.preventDefault();
      if (username === "" || password === "")
        setError(t("*All fields are required"));
      else {
        const user = await Auth.signIn(username, password);
        userHasAuthenticated(true);
        history.push("/");
        
      }
    } catch ({ message }) {
      if (message === "User does not exist.") {
        setError(t("*User does not exist."));
      } else if (message === "Incorrect username or password.") {
        setError(t("*Incorrect username or password."));
      } else {
        setError(t("*Something went wrong. Try again"));
      }
    } finally {
      setLoading(false);
      setButtonDisable(false);
    }
  }

  // const checkSession = async () => {
  //   try {
  //     const result = await Auth.currentSession();
  //     
  //   } catch (error) {
  //     
  //   }
  // };

  return (
    <div className="LoginRootContainer">
      <form
        onSubmit={signIn}
        className="d-flex flex-column col-11 col-sm-6 col-md-4 col-lg-3  text-end formContainer"
      >
        <img src={Logo} width="50%" alt="" className="m-auto" />
        <br />
        <label className="text-start inpLabel">{t("User Name")}</label>
        <input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="formInp"
        />
        <label className="text-start inpLabel mt-3">{t("Password")}</label>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          className="formInp"
        />
        {error && <p className="formErr">{error}</p>}
        <p className="forgotTxt">{t("Forgot Password?")}</p>

        <button
          type="submit"
          disabled={buttonDisable ? true : false}
          className="loginBtn"
        >
          {loading ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            "LOG IN"
          )}
        </button>
      </form>
      <span className="copyrightTxt">
        {t("Copyright © All rights reserved. BayQi 2021.")}
      </span>
    </div>
    // <div
    //   style={{
    //     width: "100%",
    //     height: "100vh",
    //     display: "flex",
    //     flexDirection: "column",
    //     // justifyContent: "center",
    //     alignItems: "center",
    //     // backgroundColor: "#fff",
    //   }}
    // >
    //   {/* <button className="btn btn-primary" onClick={signUp}>signUp</button>
    // <button className="btn btn-primary" onClick={confirmSignUp}>confirm signup</button> */}
    //   <div
    //     style={{
    //       marginTop: "7%",
    //       width: "28%",
    //       // height: "62%",
    //       // backgroundColor: "#fff",
    //       display: "flex",
    //       flexDirection: "column",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       borderRadius: "16px",
    //       boxShadow: "0 4px 16px 0 rgba(0,0,0,0.1)",
    //     }}
    //   >
    //     <div
    //       style={{
    //         width: "80%",
    //         height: "100%",
    //         // background: "green",
    //       }}
    //     >
    //       {/* <button onClick={checkSession}>click</button> */}

    //       <form onSubmit={signIn}>
    //         {/* <h3>Sign In</h3> */}
    //         <Image
    //           style={{ width: "80%", height: "80%" }}
    //           src={Logo}
    //           alt="logo"
    //         />
    //         <div style={{ width: "100%" }} className="form-group">
    //           <label>User name</label>
    //           <input
    //             value={username}
    //             onChange={(e) => setUsername(e.target.value)}
    //             className="form-control"
    //             // placeholder="Enter email"
    //           />
    //         </div>

    //         <div className="form-group">
    //           <label>Password</label>
    //           <input
    // value={password}
    // onChange={(e) => setPassword(e.target.value)}
    // type="password"
    //             className="form-control"
    //             // placeholder="Enter password"
    //           />
    //         </div>

    //         {error && <p style={{ color: "red", fontSize: "14px" }}>{error}</p>}
    //         <div className="form-group mt-3">
    //           <div className="custom-control custom-checkbox">
    //             <input
    //               type="checkbox"
    //               className="custom-control-input"
    //               id="customCheck1"
    //             />
    //             <label
    //               color={"#049EFF"}
    //               className="custom-control-label"
    //               htmlFor="customCheck1"
    //             >
    //               Remember me
    //             </label>
    //           </div>
    //         </div>
    //         {/* <Link to="/"> */}
    //         <button
    // type="submit"
    // disabled={buttonDisable ? true : false}
    //           style={{
    //             marginTop: "2%",
    //             background: "#049EFF",
    //             borderColor: "#049EFF",
    //             boxShadow: "0 4px 16px 0 rgba(0,0,0,0.3)",
    //             width: "150px",
    //           }}
    //           className="btn btn-primary"
    //         >
    // {loading ? (
    //   <Spinner animation="border" variant="light" size="sm" />
    // ) : (
    //   "LOG IN"
    // )}
    //         </button>

    //         {/* </Link> */}
    //         <div style={{ marginTop: "5%", marginBottom: "12%" }}>
    //           <a
    //             style={{ fontSize: "15px", color: "#049EFF" }}
    //             href="#"
    //             className="forgot-password text-right"
    //           >
    //             Forgot password?
    //           </a>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    //   <span style={{ marginTop: "4%", color: "#333", fontSize: "13px" }}>
    //     Copyright © All rights reserved. © BayQi 2021.
    //   </span>
    // </div>
  );
}
