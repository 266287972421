import instance from "./axios";

export const createShopCenters = async (body) => {
  try {
    return instance.post(`/shopping-centers`, body);
  } catch (err) {
    return err;
  }
};

export const deleteShopCenters = async (id) => {
  try {
    return instance.delete(`/shopping-centers?id=${id}`);
  } catch (err) {
    return err;
  }
};

export const updateShopCenters = async (id, body) => {
  try {
    return instance.update(`/shopping-centers?id=${id}`, body);
  } catch (err) {
    return err;
  }
};

export const getShopCenters = async () => {
  try {
    return instance.get(`/shopping-centers?name=&limit=&page=`);
  } catch (err) {}
};
