import instance from "./axios";

export const updateRequestStatus = async (id, body) => {
  try {
    return instance.put(`/customers/wallet/upload/approval/${id}`, body);
  } catch (e) {
    return e;
  }
};

export const getUploadWalletRequests = async (
  startDate = "",
  endDate = "",
  status = "",
  page = 1,
  limit = 10
) => {
  return instance
    .get(
      `/customers/wallet/upload?startDate=${startDate}&endDate=${endDate}&status=${status}&page=${page}&limit=${limit}`
    )
    .then((res) => res);
};
