import React from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import "./StatsCard.scss";
import { useTranslation } from "react-i18next";

const StatsCard = (props) => {
  const { t, i18n } = useTranslation();
  const handleDetailsClick = () => {
    props.setChart(props.chartData);
  };

  return (
    <>
      <div style={{ marginTop: "10px" }} className="statsCard">
        <h5 className="myh5"> {props.title}</h5>
        <p className="h2 font-weight-bold mb-0">
          {new Intl.NumberFormat().format(props.amount)} {props.currency === true && "AKZ"}
        </p>

        {props.chartData !== undefined && (
          <span
            style={{
              marginTop: "10px",
              justifyContent: "center",
              width: "100%",
              color: "#0070c4",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={handleDetailsClick}
          >
            {t("Details")}
          </span>
        )}
        {props.increase !== undefined && (
          <p
            className="text-muted text-sm mt-3 mb-0"
            style={{ position: "absolute", bottom: "15px" }}
          >
            <span style={{ marginRight: "2px" }}>
              {props.increase ? (
                <AiOutlineArrowUp
                  style={{
                    color: "#198754",
                    fontSize: "18px",
                    marginTop: "-5px",
                    stroke: "#198754",
                    strokeWidth: "40px",
                  }}
                />
              ) : (
                <AiOutlineArrowDown
                  style={{
                    fontSize: "18px",
                    marginTop: "-5px",
                    color: "#DC3545",
                    stroke: "#DC3545",
                    strokeWidth: "40px",
                  }}
                />
              )}
            </span>
            <span
              style={{
                marginRight: "5px",
                color: props.increase ? "#198754" : "#DC3545",
              }}
            >
              {props.value}
            </span>
            <span className="text-nowrap">{props.when}</span>
          </p>
        )}
      </div>
    </>
  );
};

export default StatsCard;
