import instance from "./axios";

const createAd = async (data) => {
  try {
    return instance.post(`/advertisement`, data);
  } catch (e) {
    return e;
  }
};

const getAds = async (page, limit, platform) => {
  try {
    return instance.get(
      `/advertisement?page=${page}&limit=${limit}&platform=${platform}`
    );
  } catch (e) {
    return e;
  }
};

const updateAdd = async (id, data) => {
  return instance.put(`/advertisement?id=${id}`, data).then((res) => res);
};

const deleteAdd = async (id) => {
  return instance.delete(`/advertisement?id=${id}`).then((res) => res);
};

export { createAd, getAds, updateAdd, deleteAdd };
