import instance from "./axios";

export const updateTransferStatus = async (data) => {
  return instance.put(`/sellers/withdraw/payment`, data).then((res) => res);
};

export const getSellerTransferHistory = async (
  limit = 10,
  activePage = 1,
  startDate,
  endDate,
  searchDebounce = "",
  status = ""
) => {
  return instance.get(
    `/sellers/withdraw/payment?limit=${limit}&page=${activePage}&startDate=${startDate}&endDate=${endDate}&name=${searchDebounce}&status=${status}`
  );
};
