import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { AiFillDelete } from "react-icons/ai";
import Select from "react-select";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

import { updatePostOffice } from "../../../api/BayqiLogistics/api";
import { useTranslation } from "react-i18next";

const UpdatePostOfficeModal = ({ toggle, item }) => {
  const { t, i18n } = useTranslation();
  const [title, setTitle] = useState(item.title);
  const [country, setCountry] = useState(item.country);
  // const [province, setProvince] = useState(item.province);
  // const [address, setAddress] = useState(item.address);
  const [status, setStatus] = useState(item.status);
  const [cost, setCost] = useState(item.cost);
  const [est, setEst] = useState(item.deliveryTime);
  const [extras, setExtras] = useState(item.extras);
  const [extraItems, setExtraItems] = useState("");
  const [extraCost, setExtraCost] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getEst = (val) => {
    const retValues = [
      { value: "0", label: t("Same day") },
      { value: "1", label: t("1 day") },
      { value: "2", label: t("2 days") },
      { value: "3", label: "3 days" },
      { value: "4", label: "4 days" },
      { value: "5", label: "5 days" },
      { value: "6", label: "6 days" },
      { value: "7", label: "7 days" },
      { value: "8", label: "8 days" },
      { value: "9", label: "9 days" },
      { value: "10", label: "10 days" },
      { value: "11", label: "11 days" },
      { value: "12", label: "12 days" },
      { value: "13", label: "13 days" },
      { value: "14", label: "14 days" },
      { value: "15", label: "15 days" },
    ];

    return retValues[val];
  };

  const editPostOffice = () => {
    setLoading(true);
    if (
      title !== item.title ||
      status !== item.status ||
      cost !== item.cost ||
      est !== item.estimateTime ||
      // address !== item.deliveryTime ||
      country !== item.country ||
      // province !== item.province ||
      extras !== item.extras
    ) {
      if (title !== "") {
        updatePostOffice(item._id, {
          title: title,
          country,
          cost,
          extras,
          deliveryTime: est,
          // province,
          // address,
          status: status,
        })
          .then((res) => {
            toggle(true);
            setLoading(false);
          })
          .catch((res) => {
            setLoading(false);
          });
      } else {
        setError(t("(*) fields are mandatory"));
        setLoading(false);
      }
    } else {
      setError(t("Change Values to Update"));
      setLoading(false);
    }
  };

  return (
    <div>
      <Row className="d-flex justify-content-between">
        <Col className="col-12">
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Title")}
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
            className="inputField"
            id="Title"
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col className="col-12">
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Country")}
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <CountryDropdown
            defaultOptionLabel="Select a country"
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            value={country}
            onChange={(val) => {
              setCountry(val);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="sm-col-12 md-col-6">
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Cost")}
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
              marginBottom: "8px",
            }}
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            placeholder="0.00 AKZ"
            className="inputField"
            id="Cost"
            type="number"
          />
        </Col>
      </Row>
      {extras?.length > 0 &&
        extras.map((item) => (
          <div
            style={{
              backgroundColor: "lightgray",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <p
              style={{
                display: "inline",
                marginBottom: "0",
                marginLeft: "10px",
              }}
            >
              {t("Items")}: {item.extraItems}, {t("Cost")}: {item.extraCost}
            </p>
            <AiFillDelete
              onClick={() => {
                let filteredExtras = extras.filter((curr) => curr !== item);
                setExtras(filteredExtras);
              }}
              style={{
                color: "red",
                cursor: "pointer",
                marginRight: "6px",
              }}
              size={"1.2rem"}
            />
          </div>
        ))}
      <Row
        className="d-flex justify-content-between pb-3"
        style={{ backgroundColor: "#f0f0f0" }}
      >
        <Col>
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Items")}
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            value={1}
            onChange={(e) => setExtraItems(e.target.value)}
            placeholder="Enter Items"
            className="inputField"
            type="number"
            readOnly 
          />
        </Col>
        <Col>
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Cost")}
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            value={extraCost}
            onChange={(e) => setExtraCost(e.target.value)}
            placeholder="0.00"
            className="inputField"
            type="number"
          />
        </Col>
        <Col className="col-2 d-flex justify-content-end mt-4">
          <button
            disabled={!extraCost || extras.length > 0}
            onClick={() => {
              setExtras((prevData) => [...prevData, { extraItems: 1, extraCost }]);
              setExtraCost("");
            }}
          >
            {t("Add")}
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Delivery time")} (EST)
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <Select
            value={getEst(est)}
            options={[
              { value: "0", label: t("Same day") },
              { value: "1", label: t("1 day") },
              { value: "2", label: t("2 days") },
              { value: "3", label: t("3 days") },
              { value: "4", label: t("4 days") },
              { value: "5", label: t("5 days") },
              { value: "6", label: "6 days" },
              { value: "7", label: "7 days" },
              { value: "8", label: "8 days" },
              { value: "9", label: "9 days" },
              { value: "10", label: "10 days" },
              { value: "11", label: "11 days" },
              { value: "12", label: "12 days" },
              { value: "13", label: "13 days" },
              { value: "14", label: "14 days" },
              { value: "15", label: "15 days" },
            ]}
            onChange={({ value }) => setEst(value)}
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Col md={12}>
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Status")}
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <UncontrolledDropdown style={{ width: "100%" }}>
            <DropdownToggle
              style={{
                backgroundColor: "#fff",
                borderColor: "#CED4DA",
                color: "#333",
                fontSize: "0.9rem",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              caret
            >
              <span
                style={{
                  color: "#333",
                  fontSize: "0.9rem",
                }}
              >
                {status
                  ? status === "enable" ||
                    status === "Enable" ||
                    status === "Active"
                    ? "Active"
                    : status === "disable" ||
                      status === "Disable" ||
                      status === "Inactive"
                    ? "Inactive"
                    : status
                  : "Select"}
              </span>
            </DropdownToggle>
            <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
              <DropdownItem onClick={() => setStatus("Active")}>
                {t("Active")}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => setStatus("Inactive")}>
                {t("Inactive")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>

      {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
      <ButtonToggle
        onClick={editPostOffice}
        disabled={loading ? true : false}
        style={{
          margin: "20px 0px",
          backgroundColor: "#049EFF",
          borderWidth: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ color: "#fff" }}>
          {loading ? <Spinner size="sm" animation="border" /> : t("Update")}{" "}
        </span>
      </ButtonToggle>
    </div>
  );
};

export default UpdatePostOfficeModal;
