import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Modal,
  Spinner
} from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import noDataFound from "../../Assets/images/nodata.png";
import {
  getAds
} from "../../../api/Advertisements/api";
import { v4 as uuid } from "uuid";

import Pages from "../../Components/Pagination/Pagination";
import CreateAdModal from "./CreateAdModal";
import DeleteAddModal from "./DeleteAddModal";
import UpdateAddModal from "./UpdateAddModal";
import { useTranslation } from "react-i18next";
const Advertisements = () => {
  const { t, i18n } = useTranslation();

  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const [updateItem, setUpdateItem] = useState({});
  const [platform, setPlatform] = useState("");

  const fetchAdds = () => {
    setLoading(true);
    getAds(activePage, limit, platform)
      .then((res) => {
        setTableData(res.data.result);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const updateModalToggle = (refreshFlag = false) => {
    setUpdateModal(!updateModal);
    if (refreshFlag) {
      setLoading(true);
      // setIsRefresh(false);
      fetchAdds();
    }
  };

  const deleteModalToggle = (refreshFlag = false) => {
    setDeleteModal(!deleteModal);
    if (refreshFlag) {
      setLoading(true);
      // setIsRefresh(false);
      fetchAdds();
    }
  };

  const toggle = () => {
    setModal(!modal);
    if (isRefresh) {
      setLoading(true);
      setIsRefresh(false);
      fetchAdds();
    }
  };
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    fetchAdds();
  }, [limit, activePage, platform]);

  // if (loading) {
  //   return (
  //     <span className="spinnerContainer">
  //       <Spinner animation="border" variant="primary" />
  //     </span>
  //   );
  // }

  return (
    <div className="">
      <div className="financesHeader">
        <Row
          style={{
            width: "100%",
          }}
        >
          <Col
            xl={8}
            lg={8}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div>
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Advertisement")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Platform")}
            </span>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {platform
                  ? platform?.charAt(0).toUpperCase() +
                    platform?.slice(1).toLowerCase()
                  : t("Show All")}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setPlatform("")}>
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem onClick={() => setPlatform("Both")}>
                  {t("Both")}
                </DropdownItem>
                <DropdownItem onClick={() => setPlatform("Web")}>
                  {t("Web")}
                </DropdownItem>
                <DropdownItem onClick={() => setPlatform("Mobile")}>
                  {t("Mobile")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              {t("Platform")}
            </span>
            <div className="d-grid">
              <Button
                onClick={() => {
                  toggle();
                  setIsRefresh(true);
                }}
                type="button"
                style={{
                  backgroundColor: `${blue}`,
                  color: "#fff",
                  border: "none",
                }}
              >
                {t("Create Ad")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {/* Table Content */}

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Advertisement ID")}
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Index")}
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Image")}
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>{t("Type")}</th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Platform")}
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Status")}
              </th>
            </tr>
          </thead>
          {tableData.length > 0 ? (
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  onClick={() => {
                    setUpdateItem(item);
                    updateModalToggle();
                  }}
                  style={{
                    backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                    fontSize: "12px",
                    color: "#4f4f4f",
                    fontFamily: "sans-serif",
                  }}
                >
                  <td style={{ padding: "20px" }}>{item.advertisementId}</td>
                  <td style={{ padding: "20px" }}>{item.index}</td>
                  <td style={{ padding: "20px" }}>
                    <img
                      alt=""
                      src={item.image}
                      width={65}
                      height={65}
                      style={{ borderRadius: "8px", cursor: "pointer" }}
                    />
                  </td>

                  <td style={{ padding: "20px" }}>{item.type}</td>
                  <td style={{ padding: "20px" }}>{item.platform}</td>
                  <td style={{ padding: "20px" }}>
                    {item.status === "active" ? "Active" : "Inactive"}
                  </td>
                  <td style={{ padding: "20px" }}>
                    <span
                      style={{ color: "red", textDecoration: "underline" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteItemId(item._id);
                        deleteModalToggle();
                      }}
                    >
                      {t("Delete")}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={1000}>
                  <div className="noDataContainer">
                    <img alt="no data found" src={noDataFound} width="200px" />
                    <p>{t("No data available")}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      )}

      {tableData.length === 0 && (
        <div className="noDataContainer">
          <img src={noDataFound} width="200px" alt="no data" />
          <p>{t("No data available")}</p>
        </div>
      )}

      {tableData.length > 0 && (
        <div style={{ height: "100px" }}>
          <Row style={{ display: "block", paddingBottom: "0px" }}>
            <div>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* Pagination */}
                  <Pages
                    noOfPages={noOfPages}
                    activePage={activePage}
                    setActivePage={setActivePage}
                  />
                </div>
                {/* No of products on page toggler */}
                <div
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <UncontrolledDropdown style={{ width: "100%" }}>
                    <DropdownToggle
                      style={{
                        backgroundColor: "#fff",
                        color: "#333",
                        borderColor: "#CED4DA",
                        fontSize: "0.9rem",
                        width: "100%",
                        margin: "auto",
                      }}
                      caret
                    >
                      <span
                        style={{
                          color: "#333",
                          fontSize: "0.7rem",
                        }}
                      >
                        {t("Items per page")}
                      </span>
                      <span
                        style={{
                          marginLeft: "5px",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {limit}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                      <DropdownItem
                        onClick={() => {
                          setLimit(1);
                          setActivePage(1);
                        }}
                      >
                        1
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setLimit(5);
                          setActivePage(1);
                        }}
                      >
                        5
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setLimit(10);
                          setActivePage(1);
                        }}
                      >
                        10
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Col>
            </div>
          </Row>
        </div>
      )}

      <Modal show={modal} onHide={toggle}>
        <AiFillCloseCircle
          onClick={toggle}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "red",
            cursor: "pointer",
          }}
          size={"1.5rem"}
        />
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>{t("New Ad")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <CreateAdModal toggle={toggle} />
        </Modal.Body>
      </Modal>

      <Modal show={deleteModal} onHide={deleteModalToggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Confirm Delete Add")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <DeleteAddModal toggle={deleteModalToggle} id={deleteItemId} />
        </Modal.Body>
      </Modal>

      <Modal show={updateModal} onHide={updateModalToggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Update Add Details")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <UpdateAddModal toggle={updateModalToggle} item={updateItem} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Advertisements;
