import React from "react";
import { v4 as uuid } from "uuid";

import {
  ButtonToggle,
} from "reactstrap";
import {
  deleteBlogCategory,
} from "../../../api/Blogs/api";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

export default function CategoryDetails({ id, toggleCatDetailModal }) {
    const { t, i18n } = useTranslation();
  
    const deleteCategory = () => {
      deleteBlogCategory(id)
        .then((res) => {
          toggleCatDetailModal(true);
        })
        .catch(
          (error) => {}
        );
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonToggle
          onClick={deleteCategory}
          // disabled={loading ? true : false}
          style={{
            margin: "20px 0px",
            backgroundColor: "#E65B65",
            borderWidth: 0,
            marginRight: "10px",
            // width: "100%",
            // display: "flex",
            // flexDirection: "row",
            // alignItems: "center",
            // justifyContent: "center",
          }}
        >
          {t("Yes")}
          {/* <span style={{ color: "#fff" }}>
          {loading ? <Spinner size="sm" animation="border" /> : "Create"}{" "}
        </span> */}
        </ButtonToggle>
  
        <ButtonToggle
          onClick={toggleCatDetailModal}
          // disabled={loading ? true : false}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            // width: "100%",
            // display: "flex",
            // flexDirection: "row",
            // alignItems: "center",
            // justifyContent: "center",
          }}
        >
          {t("No")}
          {/* <span style={{ color: "#fff" }}>
          {loading ? <Spinner size="sm" animation="border" /> : "Create"}{" "}
        </span> */}
        </ButtonToggle>
      </div>
    );
}


