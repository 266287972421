import React, { useState } from "react";
import {
  Row,
  Col
} from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import { Storage } from "aws-amplify";
import {
  updateCategories,
} from "../../../api/Categories/api";

export default function UpdateCategoryModal({ data, toggle, getCategories }) {
    const { t, i18n } = useTranslation();
    const [images, setImages] = useState(data.icon);
    const [showDeleteText, setShowDeleteText] = useState(false);
    const [name, setName] = useState(data.title);
    const [domain, setDomain] = useState(data.businessDomain);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState("");
  
    const onFileChange = async (e) => {
      setLoading(true);
      const files = e.target.files;
      // const filePath = URL.createObjectURL(e.target.files[0]);
      // setPath(filePath);
  
      try {
        const fileKey = uuid() + files[0].name.replace(/\s/g, "-").toLowerCase();
        await Storage.put(fileKey, files[0]).then((res) => {
          setLoading(false);
          setImages(`${process.env.REACT_APP_S3_BUCKET}/public/${res.key}`);
        });
      } catch (error) {
        setLoading(false);
      }
    };
  
    const onDeleteImage = async (file) => {
      file = file.split("public/")[1];
      setLoading(true);
  
      try {
        await Storage.remove(file).then((res) => {
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);
      }
    };
  
    const updateCategory = () => {
      if (
        name !== data.title ||
        images !== data.icon
        // || domain !== data.businessDomain
      ) {
        if (name === "" || images === "") {
          setApiError(t("(*) fields are mandatory"));
        } else {
          setLoading(true);
          // axios
          //   .put(`${baseUrl}/categories/${data._id}`, {
          //     title: name,
          //     icon: images,
          //     // domain: domain,
          //   })
          updateCategories(data._id, {
            title: name,
            icon: images,
          })
            .then((res) => {
              setLoading(false);
              getCategories();
              toggle();
            })
            .catch((err) => {
              setLoading(false);
            });
        }
      } else {
        setApiError(t("change values to update!"));
      }
    };
    return (
      <div>
        <Row className="d-flex flex-column">
          <Col className="col-12 mb-2">
            <span
              style={{
                fontWeight: "bold",
                color: "#333",
                fontSize: "1rem",
                marginBottom: "15px",
              }}
            >
              {t("Category Icon")}
            </span>
          </Col>
        </Row>
        <Row className="align-items-start flex-row">
          <Col style={{ display: "flex", flexDirection: "row" }}>
            {images != "" ? (
              <img
                alt={""}
                src={images}
                width={"65px"}
                height={"65px"}
                style={{ marginRight: "5px", cursor: "pointer" }}
                onClick={() => {
                  setImages("");
                  onDeleteImage(images);
                }}
                onMouseEnter={() => setShowDeleteText(true)}
                onMouseLeave={() => setShowDeleteText(false)}
              />
            ) : (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Image")}</p>
                  <p style={{ color: "red", display: "inline" }}> *</p>
                </label>
              </>
            )}
          </Col>
          {images != "" && (
            <p
              style={{
                color: "red",
                fontSize: "12px",
                visibility: !showDeleteText ? "hidden" : "visible",
              }}
            >
              *{toString("click to delete")}
            </p>
          )}
        </Row>
        <Row style={{ marginBottom: "5px" }}>
          <Col md={3}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </span>
          </Col>
          <Col md={9}>
            <input
              style={{ color: "#333", fontSize: "0.8rem" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
        </Row>
  
        {apiError && <p style={{ color: "red", fontSize: "12px" }}>{apiError}</p>}
  
        {/* <Row style={{ marginBottom: "5px" }}>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              Domain
            </span>
          </Col>
          <Col md={6}>
            <input
              style={{ color: "#333", fontSize: "0.8rem" }}
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            />
          </Col>
        </Row> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "5px",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={() => toggle()}
            type="button"
            style={{
              backgroundColor: "gray",
              color: "#fff",
              border: "none",
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={updateCategory}
            disabled={loading || (name === data.title && images === data.icon)}
            type="button"
            style={{
              backgroundColor: `${blue}`,
              color: "#fff",
              border: "none",
            }}
          >
            {loading ? "loading..." : "Update"}
          </Button>
        </div>
      </div>
    );
}

