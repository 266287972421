import React, { memo }  from "react";
import bayqiLogo from "../../Assets/images/logo.png";
import {
  Document,
  StyleSheet,
  PDFDownloadLink,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import { blue } from "../../Globals/colors";
import { useTranslation } from "react-i18next";

const TransactionInvoice = memo(({ item }) => {
  const { t, i18n } = useTranslation();
  const Invoice = () => (
    <Document>
      <Page size="A4">
        <View style={{ padding: "30px" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "20px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "flex-start",
                width: "30%",
              }}
            >
              <Text style={{ fontSize: "10px" }}>Parceiro Oficial</Text>
              <Image source={bayqiLogo} style={{ width: "70px" }} />
            </View>

            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "30px",
                width: "40%",
              }}
            >
              <Image
                source={
                  item.sellerDetail.businessInfo.storeLogo
                    ? item.sellerDetail.businessInfo.storeLogo
                    : "Empty"
                }
                style={{ width: "100px" }}
              />
            </View>

            <View
              style={{
                width: "30%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <View style={{ width: "75%" }}>
                <Text style={{ fontSize: "10px" }}>
                  {item.sellerDetail.businessInfo.storeName}
                </Text>

                {item.sellerDetail.businessInfo.nif && (
                  <Text style={{ fontSize: "10px" }}>
                    NIF: {item.sellerDetail.businessInfo.nif}
                  </Text>
                )}

                {item.sellerDetail.businessInfo.phoneNumber && (
                  <Text style={{ fontSize: "10px" }}>
                    Tel: {item.sellerDetail.businessInfo.phoneNumber}
                  </Text>
                )}

                {item.sellerDetail.businessInfo.Email && (
                  <Text style={{ fontSize: "10px" }}>
                    Email: {item.sellerDetail.businessInfo.Email}
                  </Text>
                )}

                <Text style={{ fontSize: "10px" }}>
                  Endereço: {item.sellerDetail.businessAddress.address}
                </Text>
                <Text style={{ fontSize: "10px" }}>
                  {item.sellerDetail.businessAddress.city}
                  -Angola
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "25px",
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: "15px" }}>
              Comprovativo de Pagamento N°{" "}
              {Math.floor(Math.random() * (9999 - 1000) + 1000)}
            </Text>
          </View>

          <View style={{ marginTop: "25px", marginLeft: "10px" }}>
            <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
              Detalhe (Details)
            </Text>
          </View>

          <View
            style={{
              marginTop: "4px",
              borderBottomColor: "#d9d9d9",
              borderBottomWidth: "1px",
            }}
          />

          <View
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <View style={{ display: "flex", flexDirection: "column" }}>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Date & Time")}
              </Text>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Transaction ID")}
              </Text>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Customer Name")}
              </Text>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Total Amount")}
              </Text>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Contact Number")}
              </Text>

              {(item.transactionType === "MARKET_PLACE" ||
                (item.transactionType === "PAYMENT_PER_LINK" &&
                  item.type == "PRODUCT")) && (
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {t("Delivery by Cartel")}
                  
                </Text>
              )}
              {(item.transactionType === "MARKET_PLACE" ||
                item.transactionType === "PAYMENT_PER_LINK") && (
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {t("Address")}
                </Text>
              )}
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Payment Method")}
              </Text>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Payment Type")}
              </Text>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Payment Status")}
              </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "column" }}>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {item.transactionType === "MARKET_PLACE"
                  ? `${new Date(
                      item.createdAt
                    ).toLocaleDateString()}, ${new Date(
                      item.createdAt
                    ).toLocaleTimeString()}`
                  : `${new Date(
                      item.createdAt
                    ).toLocaleDateString()}, ${new Date(
                      item.createdAt
                    ).toLocaleTimeString()}`}
              </Text>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {item.transactionType === "MARKET_PLACE"
                  ? item._id
                  : item.transactionId}
              </Text>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {item.transactionType === "MARKET_PLACE" ||
                item.transactionType === "PAYMENT_PER_LINK"
                  ? item.customerName
                  : item.transactionType === "QR_CATALOGE_SCAN"
                  ? `${item.customerDetail.firstName} ${item.customerDetail.surName}`
                  : `${item.customer.firstName} ${item.customer.surName}`}
              </Text>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {item.transactionType === "MARKET_PLACE" ||
                item.transactionType === "PAYMENT_PER_LINK"
                  ? `${new Intl.NumberFormat().format(item.totalPrice)} AKZ`
                  : `${new Intl.NumberFormat().format(item.amount)} AKZ`}
              </Text>

              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {item.transactionType === "MARKET_PLACE" ||
                item.transactionType === "PAYMENT_PER_LINK"
                  ? item.customerPhone
                  : item.transactionType === "QR_CATALOGE_SCAN"
                  ? item.customerDetail.phoneNumber
                  : item.customer.phoneNumber}
              </Text>
              {(item.transactionType === "MARKET_PLACE" ||
                (item.transactionType === "PAYMENT_PER_LINK" &&
                  item.type == "PRODUCT")) && (
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {item.totalDeliveryCost}
                </Text>
              )}
              {(item.transactionType === "MARKET_PLACE" ||
                item.transactionType === "PAYMENT_PER_LINK") && (
                <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {item.shippingAddress}
                </Text>
              )}
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {item.transactionType === "MARKET_PLACE" ||
                item.transactionType === "PAYMENT_PER_LINK"
                  ? item.paymentMethodDetail.paymentMethod === 'BAYQI'
                  ? 'BayQi'
                  :  item.paymentMethodDetail.paymentMethod?.charAt(0).toUpperCase() +
                  item.paymentMethodDetail.paymentMethod?.slice(1).toLowerCase()
                  : item.paymentMethod === 'BAYQI'
                  ? 'BayQi'
                  :  item.paymentMethod?.charAt(0).toUpperCase() +
                  item.paymentMethod?.slice(1).toLowerCase()
                  }
              </Text>

              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {item.transactionType?.slice(1).toLowerCase().replace(/_/g, " ")
                === "Payment per link"
                ? `${t("Payment per link")}`
                 : item.transactionType?.charAt(0).toUpperCase() +
                 item.transactionType?.slice(1).toLowerCase().replace(/_/g, " ")=== 'Qr cataloge scan'
                 ? `${t("Qr catalogo scan")}`
                 : item.transactionType?.charAt(0).toUpperCase() +
                 item.transactionType?.slice(1).toLowerCase().replace(/_/g, " ")
                 }
              </Text>
              <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                {item.transactionType === "PAYMENT_PER_LINK"
                 ?  item.orderStatusDetail?.charAt(0).toUpperCase() +
                 item.orderStatusDetail?.slice(1).toLowerCase() === "Completed"
                 ? `${t("Completed")}`
                  : `${t("Pending")}`
                  : item.paymentStatus?.charAt(0).toUpperCase() +
                  item.paymentStatus?.slice(1).toLowerCase()=== "Completed"
                  ? `${t("Completed")}`
                   : `${t("Pending")}`
                  }
                  
              </Text>
            </View>
          </View>

          <View style={{ marginTop: "25px", marginLeft: "10px" }}>
            <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
              Produtos (Products or Services)
            </Text>
          </View>

          <View
            style={{
              marginTop: "4px",
              borderBottomColor: "#d9d9d9",
              borderBottomWidth: "1px",
            }}
          />

          <View style={{ marginTop: "10px" }}>
            {item.transactionType === "MARKET_PLACE" ||
            item.transactionType === "QR_CATALOGE_SCAN" ||
            item.transactionType === "PAYMENT_PER_LINK" ? (
              <View>
                {item.products.map((product, key) => {
                  return (
                    <View
                      key={key}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                        marginLeft: "25px",
                      }}
                    >
                      <Image
                        source={{
                          uri: product.image,
                          method: "GET",
                          headers: { "Cache-Control": "no-cache" },
                          body: "",
                        }}
                        style={{ width: "50px" }}
                      />
                      <View style={{ marginLeft: "10px" }}>
                        <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                          {product.name}
                        </Text>
                        <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                          {new Intl.NumberFormat().format(product.price)} AKZ
                        </Text>
                        <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
                          {product.qty}
                        </Text>
                      </View>
                    </View>
                  );
                })}

                <Text
                  style={{
                    marginTop: "20px",
                    fontSize: "12px",
                    marginLeft: "25px",
                  }}
                >
                  {t("Total")}
                </Text>

                <View
                  style={{
                    marginTop: "4px",
                    borderBottomColor: "#d9d9d9",
                    borderBottomWidth: "1px",
                  }}
                />

                <Text
                  style={{
                    marginTop: "4px",
                    fontSize: "12px",
                    marginLeft: "75px",
                  }}
                >
                  {item.transactionType === "MARKET_PLACE" ||
                  item.transactionType === "PAYMENT_PER_LINK"
                    ? new Intl.NumberFormat().format(item.totalPrice)
                    : new Intl.NumberFormat().format(item.amount)}{" "}
                  AKZ
                </Text>
              </View>
            ) : (
              <Text style={{ marginLeft: "25px", fontSize: "12px" }}>
                {item.description}
              </Text>
            )}
          </View>

          <View
            style={{
              marginTop: "4px",
              borderBottomColor: "#d9d9d9",
              borderBottomWidth: "1px",
            }}
          />

          <View
            fixed
            style={{
              marginTop: "25px",
              marginLeft: "10px",
              textAlign: "center",
            }}
          >
            <Text style={{ fontSize: "10px", padding: "20px" }}>
              <Text>{item.sellerDetail.businessInfo.storeName}</Text>
              {", "}
              <Text>{item.sellerDetail.businessAddress.address}</Text>
              {" - "}
              <Text>{item.sellerDetail.businessAddress.city}</Text>

              {item.sellerDetail.businessInfo.nif && (
                <Text style={{ fontSize: "10px" }}>
                  {`, NIF:${item.sellerDetail.businessInfo.nif}`}
                </Text>
              )}

              {item.sellerDetail.businessInfo.phoneNumber && (
                <Text style={{ fontSize: "10px" }}>
                  {`, Tel :${item.sellerDetail.businessInfo.phoneNumber}`}
                </Text>
              )}

              {item.sellerDetail.businessInfo.Email && (
                <Text style={{ fontSize: "10px" }}>
                  {`, Email:${item.sellerDetail.businessInfo.Email}`}
                </Text>
              )}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFDownloadLink
        style={{ backgroundColor: "#049EFF",width:"100%",marginTop:"5px" }}
        className="btn btn-primary"
        document={<Invoice />}
        const fileName = {t('Invoice') + '.pdf'}
      >
        {({ blob, url, loading, error }) => {
          if (error) {
            return "Error...";
          } else if (loading) {
            return t("Loading Document...");
          } else {
            return t("Download Invoice");
          }
        }}
      </PDFDownloadLink>
    </div>
  );
});

export default TransactionInvoice;
