import React, { useState } from "react";
import { Row, Col, Table, Modal, Spinner, Tabs, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import noDataFound from "../../Assets/images/nodata.png";

import PostOfficeTab from "./PostOfficeTab";
import CreateDeliveryModal from "./CreateDeliveryModal";
import CreatePickupModal from "./CreatePickupModal";
import DeleteDeliveryModal from "./DeleteDeliveryModal";
import DeletePickupModal from "./DeletePickupModal";
import UpdateDeliveryModal from "./UpdateDeliveryModal";
import UpdatePickupModal from "./UpdatePickupModal";
import {
  getPickupPoints,
  getAdminDeliveries,
} from "../../../api/BayqiLogistics/api";
import { useTranslation } from "react-i18next";



const BayqiLogistics = () => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([0]);
  const [modal, setModal] = useState(false);
  const [updatePickupModal, setUpdatePickupModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [updatePickupItem, setUpdatePickupItem] = useState({});
  const [deletePickupId, setDeletePickupId] = useState("");
  const [deletePickupModal, setDeletePickupModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    if (isRefresh) {
      setLoading(true);
      setIsRefresh(false);
      getPickupPoints()
        .then((res) => {
          
          setTableData(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
        });
    }
  };

  const deletePickupToggle = (flag = false) => {
    setDeletePickupModal(!deletePickupModal);
    if (flag) {
      setLoading(true);
      setIsRefresh(false);
      getPickupPoints()
        .then((res) => {
          
          setTableData(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
        });
    }
  };

  const updatePickupToggle = () => {
    setUpdatePickupModal(!updatePickupModal);
    if (isRefresh) {
      setLoading(true);
      setIsRefresh(false);
      getPickupPoints()
        .then((res) => {
          
          setTableData(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
        });
    }
  };

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getPickupPoints()
      .then((res) => {
        setTableData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
      });
  }, []);

  const [deliveryLoading, setDeliveryLoading] = useState(true);
  const [deliveryData, setDeliveryData] = useState([]);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [updateDeliveryModal, setUpdateDeliveryModal] = useState(false);
  const [updateDeliveryItem, setUpdateDeliveryItem] = useState({});
  const [deleteDeliveryItemId, setDeleteDeliveryItemId] = useState("");
  const [deleteDeliveryModal, setDeleteDeliveryModal] = useState(false);

  const toggleDeleteDeliveryModal = (refresh = false) => {
    setDeleteDeliveryModal(!deleteDeliveryModal);
    if (refresh) {
      setDeliveryLoading(true);
      getAdminDeliveries()
        .then((res) => {
          
          setDeliveryData(res.data.result);
          setDeliveryLoading(false);
        })
        .catch((err) => {
        });
    }
  };

  const toggleAddDeliveryModal = (refresh = false) => {
    setDeliveryModal(!deliveryModal);
    if (refresh) {
      setDeliveryLoading(true);
      getAdminDeliveries()
        .then((res) => {
          
          setDeliveryData(res.data.result);
          setDeliveryLoading(false);
        })
        .catch((err) => {
        });
    }
  };

  const toggleUpdateDeliveryModal = (refresh = false) => {
    setUpdateDeliveryModal(!updateDeliveryModal);
    if (refresh) {
      setDeliveryLoading(true);
      getAdminDeliveries()
        .then((res) => {
          
          setDeliveryData(res.data.result);
          setDeliveryLoading(false);
        })
        .catch((err) => {
        });
    }
  };

  React.useEffect(() => {
    getAdminDeliveries()
      .then((res) => {
        
        setDeliveryData(res.data.result);
        setDeliveryLoading(false);
      })
      .catch((err) => {
      });
  }, []);

  return (
    <>
      <Tabs
        defaultActiveKey="delivery"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {/* <Tab eventKey="pickup" title="Pickup">
          <div className="">
            <div className="financesHeader">
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: "20px",
                  paddingRight: "20px",
                }}
              >
                <Col
                  xl={3}
                  lg={3}
                  style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
                >
                  <div>
                    <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      {t("Bayqi Pickup")}
                    </span>
                  </div>
                </Col>
                <Col xl={2} lg={2}>
                  <div className="d-grid">
                    <Button
                      onClick={() => {
                        toggle();
                        setIsRefresh(true);
                      }}
                      type="button"
                      style={{
                        backgroundColor: `${blue}`,
                        color: "#fff",
                        border: "none",
                      }}
                    >
                      {t("Add")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            {loading ? (
              <span className="spinnerContainer">
                <Spinner animation="border" variant="primary" />
              </span>
            ) : (
              <Table
                style={{
                  cursor: "pointer",
                  marginTop: "25px",
                  backgroundColor: "#f7f7f7",
                  borderRadius: "25px",
                }}
                borderless={true}
                hover
              >
                <thead>
                  <tr>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("Name")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("Number")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("City")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("Street Address")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("Mobile Phone")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("Cost")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => (
                    <tr
                      onClick={() => {
                        setUpdatePickupItem(item);
                        updatePickupToggle();
                        setIsRefresh(true);
                      }}
                      style={{
                        backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                        fontSize: "12px",
                        color: "#4f4f4f",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <td style={{ padding: "20px" }}>{item.name}</td>
                      <td style={{ padding: "20px" }}>{item.number}</td>
                      <td style={{ padding: "20px" }}>{item.city}</td>
                      <td style={{ padding: "20px" }}>{item.address}</td>
                      <td style={{ padding: "20px" }}>{item.phoneNumber}</td>
                      <td style={{ padding: "20px" }}>{item.cost}</td>
                      <td style={{ padding: "20px" }}>
                        <span
                          style={{ color: "red", textDecoration: "underline" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeletePickupId(item._id);
                            deletePickupToggle();
                          }}
                        >
                          {t("Delete")}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {!tableData && (
              <div className="noDataContainer">
                <img src={noDataFound} width="200px" />
                <p>{t("No data available")}</p>
              </div>
            )}

            <Modal show={modal} onHide={toggle}>
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: "20px" }}>
                  {t("Add Pickup Details")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-5">
                <CreatePickupModal toggle={toggle} />
              </Modal.Body>
            </Modal>

            <Modal show={updatePickupModal} onHide={updatePickupToggle}>
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: "20px" }}>
                  {t("Update Pickup Details")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-5">
                <UpdatePickupModal
                  item={updatePickupItem}
                  toggle={updatePickupToggle}
                />
              </Modal.Body>
            </Modal>

            <Modal show={deletePickupModal} onHide={deletePickupToggle}>
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: "20px" }}>
                  {t("Delete Pickup Confirm")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-5">
                <DeletePickupModal
                  id={deletePickupId}
                  toggle={deletePickupToggle}
                />
              </Modal.Body>
            </Modal>
          </div>
        </Tab> */}
        <Tab eventKey="delivery" title="Delivery">
          <div className="">
            {/* Header */}
            <div className="financesHeader">
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: "20px",
                  paddingRight: "20px",
                }}
              >
                <Col
                  xl={3}
                  lg={3}
                  style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
                >
                  <div>
                    <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      {t("Bayqi Delivery")}
                    </span>
                  </div>
                </Col>
                <Col xl={2} lg={2}>
                  <div className="d-grid">
                    <Button
                      onClick={() => toggleAddDeliveryModal()}
                      type="button"
                      style={{
                        backgroundColor: `${blue}`,
                        color: "#fff",
                        border: "none",
                      }}
                    >
                      {t("Add")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            {/* Table Content */}
            {deliveryLoading ? (
              <span className="spinnerContainer">
                <Spinner animation="border" variant="primary" />
              </span>
            ) : (
              <Table
                style={{
                  cursor: "pointer",
                  marginTop: "25px",
                  backgroundColor: "#f7f7f7",
                  borderRadius: "25px",
                }}
                borderless={true}
                hover
              >
                <thead>
                  <tr>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("Country")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("Province")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("Name")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("Delivery Time")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("Cost")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "16px" }}>
                      {t("State")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {deliveryData.map((item, index) => (
                    <tr
                      onClick={() => {
                        setUpdateDeliveryItem(item);
                        toggleUpdateDeliveryModal();
                      }}
                      style={{
                        backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                        fontSize: "12px",
                        color: "#4f4f4f",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <td style={{ padding: "20px" }}>{item.country}</td>
                      <td style={{ padding: "20px" }}>{item.province}</td>
                      <td style={{ padding: "20px" }}>{item.title}</td>
                      <td style={{ padding: "20px" }}>
                        {item.estimateTime > 0
                          ? `${item.estimateTime} ${
                              item.estimateTime > 1 ? "days" : "day"
                            }`
                          : "Same day"}
                      </td>
                      <td style={{ padding: "20px" }}>
                        {new Intl.NumberFormat().format(item.cost)} AKZ
                      </td>
                      <td style={{ padding: "20px" }}>{item.status}</td>
                      <td style={{ padding: "20px" }}>
                        <span
                          style={{ color: "red", textDecoration: "underline" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteDeliveryItemId(item._id);
                            toggleDeleteDeliveryModal();
                          }}
                        >
                          {t("Delete")}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {/* <div style={{ height: '100px' }}>
        <Row style={{ display: 'block', paddingBottom: '0px' }}>
          <div>
          <Col
          style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              >
              <div
              style={{
                flexDirection: "column",
                display: "flex",
                  justifyContent: "center",
                }}
              >
                Pagination 
                <Pagination>
                  {[1, 2, 3, 4, 5].map((item, index) => (
                    <Pagination.Item
                    onClick={() => setActivePage(item)}
                      active={activePage === item}
                    >
                    {item}
                    </Pagination.Item>
                    ))}
                </Pagination>
                </div>
                No of products on page toggler 
              <div
                style={{
                  marginLeft: "10px",
                }}
                >
                <UncontrolledDropdown style={{ width: "100%" }}>
                <DropdownToggle
                    style={{
                      backgroundColor: "#fff",
                      color: "#333",
                      borderColor: "#CED4DA",
                      fontSize: "0.9rem",
                      width: "100%",
                    }}
                    caret
                  >
                  <span
                      style={{
                        color: "#333",
                        fontSize: "0.7rem",
                      }}
                    >
                    Items per page
                    </span>
                    <span
                    style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                      >
                      1
                      </span>
                      </DropdownToggle>
                  <DropdownMenu style={{ marginTop: "5px" }}>
                  <DropdownItem>1</DropdownItem>
                  <DropdownItem>2</DropdownItem>
                  <DropdownItem>3</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                </div>
            </Col>
            </div>
            </Row>
      </div>
    */}

            {/* <div className="noDataContainer">
              <img src={noDataFound} width="200px" />
              <p>No data available</p>
            </div> */}

            <Modal show={deliveryModal} onHide={toggleAddDeliveryModal}>
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: "20px" }}>
                  {t("Add Delivery Option")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-5">
                <CreateDeliveryModal toggle={toggleAddDeliveryModal} />
              </Modal.Body>
            </Modal>

            <Modal
              show={updateDeliveryModal}
              onHide={toggleUpdateDeliveryModal}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: "20px" }}>
                  {t("Update Delivery Option")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-5">
                <UpdateDeliveryModal
                  toggle={toggleUpdateDeliveryModal}
                  item={updateDeliveryItem}
                />
              </Modal.Body>
            </Modal>

            <Modal
              show={deleteDeliveryModal}
              onHide={toggleDeleteDeliveryModal}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: "20px" }}>
                  {t("Confirm Delete Delivery")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-5">
                <DeleteDeliveryModal
                  toggle={toggleDeleteDeliveryModal}
                  id={deleteDeliveryItemId}
                />
              </Modal.Body>
            </Modal>
          </div>
        </Tab>
        <Tab eventKey="post" title="Post Office">
          <PostOfficeTab />
        </Tab>
      </Tabs>
    </>
  );
};

export default BayqiLogistics;
