import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../config";
import Pages from "../../Components/Pagination/Pagination";
import { Row, Col, Table, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { set } from "date-fns";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import noDataFound from "../../Assets/images/nodata.png";
import { useTranslation } from "react-i18next";
import { getRequestHistory } from "../../../api/TransferFriends/api";
import ModalLayout from "./ModalLayout";


const TransferFriend = () => {
  const { t, i18n } = useTranslation();

  const [activeTab, setActiveTab] = useState("send");
  const [currRecord, setCurrRecord] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = React.useState(true);

  // const [noOfPagesSend, setNoOfPagesSend] = useState([]);
  // const [activePageSend, setActivePageSend] = useState(1);
  // const [limitSend, setLimitSend] = useState(10);
  // const [startDateSend, setStartDateSend] = useState("");
  // const [endDateSend, setEndDateSend] = useState("");
  // const [sendHistoryData, setSendHistoryData] = useState([]);

  // React.useEffect(() => {
  //   setNoOfPagesSend([]);
  //   setLoading(true);
  //   getSendHistory(activePageSend, limitSend, startDateSend, endDateSend)
  //     .then((res) => {
  //       setSendHistoryData(res.data.data);
  //       setLoading(false);
  //       let num = res.data.count / limitSend;
  //       if (num < 1) setNoOfPagesSend([1]);
  //       else {
  //         num = Math.ceil(num);
  //         for (let i = 0; i < num; i++) {
  //           setNoOfPagesSend((oldArray) => [...oldArray, i + 1]);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       
  //     });
  // }, [activePageSend, limitSend, startDateSend, endDateSend]);

  const [noOfPagesRequest, setNoOfPagesRequest] = useState([]);
  const [activePageRequest, setActivePageRequest] = useState(1);
  const [limitRequest, setLimitRequest] = useState(10);
  const [requestHistoryData, setRequestHistoryData] = useState([]);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  React.useEffect(() => {
    setNoOfPagesRequest([]);
    setLoading(true);
    getRequestHistory(
      activePageRequest,
      limitRequest,
      status,
      type,
      startDate,
      endDate
    )
      .then((res) => {
        setRequestHistoryData(res.data.result);
        setLoading(false);
        let num = res.data.total / limitRequest;
        if (num < 1) setNoOfPagesRequest([1]);
        else {
          num = Math.ceil(num);
          for (let i = 0; i < num; i++) {
            setNoOfPagesRequest((oldArray) => [...oldArray, i + 1]);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [activePageRequest, limitRequest, status, type, startDate, endDate]);

  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/friends/1/friends/transfer/wallet/money/export?status=${status}&type=${type}&startDate=${startDate}&endDate=${endDate}`,
        ""
      );
    } catch (e) {
    }
  };

  // if (loading) {
  //   return (
  //     <span className="spinnerContainer">
  //       <Spinner animation="border" variant="primary" />
  //     </span>
  //   );
  // }

  return (
    <div className="">
      {/* Header  */}
      <div className="financesHeader">
        <Row style={{ width: "100%" }}>
          <Col
            xl={2}
            lg={2}
            // style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  visibility: "hidden",
                }}
              >
                show all
              </span>

              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: "1.5rem",
                }}
              >
                {t("Friend History")}
              </span>
            </div>
          </Col>

          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              {t("Start Date")}
            </span>

            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "30%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Status")}
            </span>

            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span className="showAllSpan">
                  {status
                    ? status?.charAt(0).toUpperCase() +
                      status?.slice(1).toLowerCase()
                    : "Show All"}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem onClick={() => setStatus("")}>
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem onClick={() => setStatus("PENDING")}>
                  {t("Pending")}
                </DropdownItem>
                <DropdownItem onClick={() => setStatus("COMPLETED")}>
                  {t("Completed")}
                </DropdownItem>
                <DropdownItem onClick={() => setStatus("REJECTED")}>
                  {t("Rejected")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Type")}
            </span>

            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span className="showAllSpan">
                  {type ? type.replace("_", " ") : "Show All"}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem onClick={() => setType("")}>
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem onClick={() => setType("REQUEST_MONEY")}>
                  {t("REQUEST MONEY")}
                </DropdownItem>
                <DropdownItem onClick={() => setType("SEND_MONEY")}>
                  {t("SEND MONEY")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </div>

      {/* Table Content */}
      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <>
          {" "}
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Date & Time")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Transaction Id")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Requester Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Requester Wallet Number")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Receiver Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Receiver Wallet Number")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Type")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Status")}
                </th>
              </tr>
            </thead>
            {requestHistoryData.length > 0 ? (
              <tbody>
                {requestHistoryData.map((item, index) => (
                  <tr
                    onClick={() => {
                      setCurrRecord(item);
                      toggle();
                    }}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>
                      {new Date(item.createAt).toDateString()}
                    </td>
                    <td style={{ padding: "20px" }}>{item.transactionId}</td>
                    <td style={{ padding: "20px" }}>
                      {item.senderData.firstName} {item.senderData.surName}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.senderData.phoneNumber}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.receiverData.firstName} {item.receiverData.surName}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.receiverData.phoneNumber}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.type.replace("_", " ")}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.amount.toLocaleString()} AKZ
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.status?.charAt(0).toUpperCase() +
                        item.status?.slice(1).toLowerCase()}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={100}>
                    <div className="noDataContainer">
                      <img alt="no data" src={noDataFound} width="200px" />
                      <p>{t("No data available")}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
          {requestHistoryData.length > 0 && (
            <div style={{ height: "100px" }}>
              <Row style={{ display: "block", paddingBottom: "0px" }}>
                <div>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {/* Pagination */}
                      <Pages
                        noOfPages={noOfPagesRequest}
                        activePage={activePageRequest}
                        setActivePage={setActivePageRequest}
                      />
                    </div>
                    {/* No of products on page toggler */}
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle
                          style={{
                            backgroundColor: "#fff",
                            color: "#333",
                            borderColor: "#CED4DA",
                            fontSize: "0.9rem",
                            width: "100%",
                          }}
                          caret
                        >
                          <span
                            style={{
                              color: "#333",
                              fontSize: "0.7rem",
                            }}
                          >
                            {t("Items per page")}
                          </span>
                          <span
                            style={{
                              marginLeft: "5px",
                              fontWeight: "bold",
                              marginRight: "5px",
                            }}
                          >
                            {limitRequest}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu style={{ marginTop: "5px" }}>
                          <DropdownItem
                            onClick={() => {
                              setLimitRequest(1);
                              setActivePageRequest(1);
                            }}
                          >
                            1
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimitRequest(5);
                              setActivePageRequest(1);
                            }}
                          >
                            5
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimitRequest(10);
                              setActivePageRequest(1);
                            }}
                          >
                            10
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}
        </>
      )}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <ModalLayout data={currRecord} tab={activeTab} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
      {/* Transactions Details Modal */}
      {/* <Modal centered isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Transaction Details</ModalHeader>
            <ModalBody>
              <ModalLayout />
            </ModalBody>
            <div style={{ height: "5px", width: "100%" }} />
          </Modal> */}
    </div>
    //     </Tab>
    //   </Tabs>
  );
};

export default TransferFriend;
