import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col, Spinner } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { baseUrl } from "../../../config";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { updateRefundOrder } from "../../../api/Refund/api";

const RefundDetailsModal = ({ toggle, modal, item }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const updateStatus = () => {
    setLoading(true);
    // axios
    //   .put(`${baseUrl}/orders/refund`, {
    //     id: item._id,
    //   })
    updateRefundOrder(item._id)
      .then((res) => {
        setLoading(false);
        toggle(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Modal
      centered
      isOpen={modal}
      toggle={() => {
        toggle(false);
      }}
    >
      <AiFillCloseCircle
        onClick={() => {
          toggle(false);
        }}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          color: "red",
          cursor: "pointer",
        }}
        size={"1.5rem"}
      />
      <ModalHeader
        toggle={() => {
          toggle(false);
        }}
      >
        {t("Transaction Details")}
      </ModalHeader>
      <ModalBody>
        <div>
          <Row>
            <Col md={6}>
              <span
                style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}
              >
                {t("Refund Id")}
              </span>
            </Col>
            <Col md={6}>
              <span style={{ color: "#333", fontSize: "0.8rem" }}>
                {item.refundId}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <span
                style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}
              >
                {t("Date")}
              </span>
            </Col>
            <Col md={6}>
              <span style={{ color: "#333", fontSize: "0.8rem" }}>
                {new Date(item.createdAt).toLocaleDateString()}
                {" - "}
                {new Date(item.createdAt).toLocaleTimeString()}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <span
                style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}
              >
                {t("Transaction ID")}
              </span>
            </Col>
            <Col md={6}>
              <span style={{ color: "#333", fontSize: "0.8rem" }}>
                {item.transactionId}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <span
                style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}
              >
                {t("Payment Method")}
              </span>
            </Col>
            <Col md={6}>
              <span style={{ color: "#333", fontSize: "0.8rem" }}>
                {item.paymentMethod?.charAt(0).toUpperCase() +
                  item.paymentMethod?.slice(1).toLowerCase()}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <span
                style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}
              >
                {t("Amount")}
              </span>
            </Col>
            <Col md={6}>
              <span style={{ color: "#333", fontSize: "0.8rem" }}>
                {new Intl.NumberFormat().format(item.totalPrice)} AKZ
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <span
                style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}
              >
                {t("Status")}
              </span>
            </Col>
            <Col md={6}>
              <span style={{ color: "#333", fontSize: "0.8rem" }}>
                {item.status?.charAt(0).toUpperCase() +
                  item.status?.slice(1).toLowerCase()}
              </span>
            </Col>
          </Row>
          <Row>
          <Col md={3} className={{ padding: "0px" }}>
            {item.status !== "REFUNDED" && (
              <button
                type="button"
                style={{
                  backgroundColor: "#049EFF",
                  color: "#fff",
                  border: "none",
                  padding: "8px",
                  marginTop: "20px",
                  borderRadius: "4px",
                  width: "100%",
                }}
                onClick={updateStatus}
              >
                {loading ? (
                  <Spinner
                    size="sm"
                    style={{ color: "white" }}
                    animation="border"
                  />
                ) : (
                  t("Approve")
                )}
              </button>
            )}
          </Col>
        </Row>
        </div>
      </ModalBody>
      <div style={{ height: "5px", width: "100%" }} />
    </Modal>
  );
};

export default RefundDetailsModal;
