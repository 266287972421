import instance from "./axios";

export const createPostOffice = (data) => {
  return instance.post(`/admin/post-office`, data).then((res) => res);
};

export const addPickupPoints = async (data) => {
  try {
    return instance.post(`/delivery-points`, data);
  } catch (e) {
    return e;
  }
};

export const getPickupPoints = async () => {
  try {
    return instance.get(`/delivery-points`);
  } catch (e) {
    return e;
  }
};

export const updatePickupPoints = async (id, data) => {
  return instance.put(`/delivery-points/${id}`, data).then((res) => res);
};

export const deletePickup = async (id) => {
  return instance.delete(`/delivery-points/${id}`).then((res) => res);
};

export const getAdminDeliveries = async (
  page = 1,
  limit = 100,
  title = "",
  status = ""
) => {
  return instance
    .get(
      `/admin/delivery-zones?limit=${limit}&page=${page}&title=${title}&status=${status}`
    )
    .then((res) => res);
};

export const createAdminDelivery = async (data) => {
  return instance.post(`/admin/delivery-zones`, data).then((res) => res);
};

export const updateAdminDelivery = (id, data) => {
  return instance
    .put(`/admin/delivery-zones?id=${id}`, data)
    .then((res) => res);
};

export const deletePostOffice = (id) => {
  return instance
    .put(`/admin/post-office?id=${id}`, { isDelete: true })
    .then((res) => res);
};

export const getPostOfficeData = (
  title = "",
  status = "",
  limit = 100,
  page = 1
) => {
  return instance
    .get(
      `/admin/post-office?limit=${limit}&page=${page}&title=${title}&status=${status}`
    )
    .then((res) => res);
};

export const updatePostOffice = (id, data) => {
  return instance.put(`/admin/post-office?id=${id}`, data).then((res) => res);
};
