import React, { useState } from "react";
import {
  Row,
  Col,
} from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

import {
  ButtonToggle,
} from "reactstrap";
import Select from "react-select";
import AddAttribute from "./AddAttribute";
import "./Categories.scss";
import { Storage } from "aws-amplify";
import {
  fetchCategories,
  createCategories
} from "../../../api/Categories/api";

export default function AddCategoryModal({ toggle, getTableCategories }) {
    const { t, i18n } = useTranslation();
    const [attributeList, setAttributeList] = useState([]);
    const [path, setPath] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const [apiData, setApiData] = useState({});
  
    // all attributes data
    const [attrData, setAttrData] = useState([]);
    const [fieldsEditable, setFieldsEditable] = useState(false);
  
    // temp
    const onAddAttribute = () => {
      setAttributeList(
        attributeList.concat(
          <AddAttribute
            fieldsEditable={fieldsEditable}
            setFieldsEditable={setFieldsEditable}
            setAttrData={setAttrData}
            key={attributeList.length}
          />
        )
      );
    };
  
    const onFileChange = (e) => {
      const filePath = URL.createObjectURL(e.target.files[0]);
      setPath(filePath);
  
      Storage.put(e.target.files[0].name, e.target.files[0])
        .then((res) => {
          setImageUrl(`${process.env.REACT_APP_S3_BUCKET}/public/${res.key}`);
        })
        .catch((error) => {
        });
    };
  
    const categories = [
      { value: "Shopping", label: t("Shopping") },
      { value: "Event", label: t("Event") },
      { value: "Book", label: t("Book") },
      { value: "Shopping Center", label: t("Shopping Center") },
      { value: "Fast Food", label: t("Fast Food") },
      { value: "Cantinas", label: t("Cantinas") },
      { value: "Bar & Restaurant", label: t("Bar & Restaurant") },
      { value: "Supermarket", label: t("Supermarket") },
    ];
    const [domain, setDomain] = useState("");
    const [dbDomain, setDbDomain] = useState("");
    const [title, setTitle] = useState("");
    // categories related
    var o = [];
    var c = 0;
    var [count, setCount] = useState([]);
    const [options, setOptions] = useState([]);
    var [isDisabled, setIsDisabled] = useState(true);
    const [description, setDescription] = useState("");
    const [prodCategories, setProdCategories] = useState([]);
    const [currCatId, setCurrCatId] = useState(null);
    // cat api loading boolean
    const [creatingCategory, setCreatingCategory] = useState(false);
    // cat api error message
    const [apiError, setApiError] = useState("");
    const [catNames, setCatNames] = useState([]);
    const [disableSelect, setDisableSelect] = useState(false);
    const [cat, setCat] = useState({ value: "", label: "Select..." });
  
    React.useEffect(() => {
      getCategories("");
      setCat({ value: "", label: "Select..." });
      setCatNames([]);
      setCurrCatId("");
      // setProdCategories([]);
      setDisableSelect(false);
      setIsDisabled(true);
    }, [domain]);
  
    const getCategories = (id) => {
      let searchDomain = "";
  
      if (domain === "Book") {
        searchDomain = "Library";
        setDbDomain(searchDomain);
      } else if (domain === "Shopping Center") {
        searchDomain = "Shopping";
        setDbDomain(searchDomain);
      } else if (domain === "Fast Food") {
        searchDomain = "Food and Restaurant";
        setDbDomain(searchDomain);
      } else if (domain === "Cantinas") {
        searchDomain = "Supermarket and Cantinas";
        setDbDomain(searchDomain);
      } else if (domain === "Bar & Restaurant") {
        searchDomain = "Food and Restaurant";
        setDbDomain(searchDomain);
      } else if (domain === "Supermarket") {
        searchDomain = "Supermarket and Cantinas";
        setDbDomain(searchDomain);
      } else {
        searchDomain = domain;
        setDbDomain(searchDomain);
      }
  
      if (id === "") {
        id = "null";
      }
      // axios
      //   .get(`${baseUrl}/categories?parent=${id}&domain=${searchDomain}`)
      fetchCategories(id, searchDomain)
        .then((res) => {
          const { success, message, data } = res.data;
          if (success) {
            if (data.length === 0) {
              setDisableSelect(true);
              setIsDisabled(false);
            } else {
              data.map((item) => {
                o.push({ value: item._id, label: item.title });
              });
              setOptions(o);
              c++;
              setCount([...count, c]);
            }
          }
        })
        .catch((ex) => {
          // alert('error');
        });
    };
  
    const addCategory = () => {
      setApiError("");
      const body = {
        icon: imageUrl,
        title,
        businessDomain: dbDomain,
        parentId: currCatId,
        // childIds,  // this is not needed
        attributes: attrData,
        isApproved: "approved",
      };
      setCreatingCategory(true);
  
      if (imageUrl === "" || title === "" || dbDomain === "") {
        setApiError(t("(*) fields are mandatory."));
        setCreatingCategory(false);
      } else {
        // axios
        //   .post(`${baseUrl}/categories`, body)
        createCategories(body)
          .then((res) => {
            setCreatingCategory(false);
            getTableCategories();
            toggle();
          })
          .catch((err) => {
            setCreatingCategory(false);
            setApiError(err.message);
          })
          .finally(() => {
            setImageUrl("");
            setTitle("");
            setDomain("");
            setCurrCatId("");
            setAttrData([]);
          });
      }
    };
    return (
      <>
        <Row>
          <Col className="col-4">
            {!imageUrl ? (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  multiple
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Icon")}</p>
                  <p style={{ color: "red", display: "inline" }}> *</p>
                </label>
              </>
            ) : (
              <>
                <img
                  htmlFor="imgUpload"
                  src={imageUrl}
                  width={65}
                  height={65}
                  style={{ borderRadius: "8px" }}
                  alt={""}
                />
                {/* TODO: show an edit btn beside the img to edit the current uploaded image */}
              </>
            )}
          </Col>
          <Col>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <label
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "5px",
            }}
          >
            {t("Choose business domain")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <Select
            options={categories}
            onChange={({ value }) => {
              setDomain(value);
            }}
          />
        </Row>
        <Row>
          <label
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "5px",
            }}
          >
            {t("Choose parent category (if any)")}
          </label>
        </Row>
        <Row>
          <div>
            {catNames.length > 0 &&
              catNames.map((item, index) => (
                <p style={{ display: "inline", color: "gray", fontSize: "12px" }}>
                  {index !== 0 && "/ "}
                  {item}
                </p>
              ))}
          </div>
  
          {/* {count.map((i) => ( */}
          <div style={{ marginBottom: "1px" }}>
            <Select
              onChange={(e) => {
                // setProdCategories([...prodCategories, e.value]);
                getCategories(e.value);
                setCatNames((catNames) => [...catNames, e.label]);
                setCat({ label: "Select...", value: e.value });
                setCurrCatId(e.value);
              }}
              value={cat}
              isDisabled={disableSelect}
              options={options}
              isSearchable
              placeholder="Select Category..."
            />
          </div>
          {catNames.length > 0 && (
            <div style={{ width: "100%", textAlign: "end" }}>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={() => {
                  getCategories("");
                  setCat({ value: "", label: "Select..." });
                  setCatNames([]);
                  setCurrCatId("");
                  // setProdCategories([]);
                  setDisableSelect(false);
                  setIsDisabled(true);
                }}
              >
                <i style={{ fontSize: "13px", color: "gray" }}>{t("reset")}</i>
              </button>
            </div>
          )}
  
          {/* {count.map((i) => (
            <div style={{ marginBottom: "10px" }}>
              <Select
                onChange={(e) => {
                  setProdCategories([...prodCategories, e.value]);
                  getCategories(e.value);
                  setCurrCatId(e.value);
                }}
                options={options}
                // isClearable
                isSearchable
                placeholder="Select Category..."
              />
            </div>
          ))} */}
          {/* <button onClick={() => alert(currCatId)}>show cat id</button> */}
        </Row>
        {/* {attributeList} */}
  
        {attrData.length > 0 && (
          <label
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "5px",
            }}
          >
            {t("Attribute List")}
          </label>
        )}
  
        {attrData &&
          attrData.map((item) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "lightgray",
                margin: "0px",
                marginBottom: "4px",
                padding: "4px",
              }}
            >
              <p style={{ margin: "0px", backgroundColor: "lightgray" }}>
                {`${item.title} - (type: ${item.inputType})`}
              </p>
              <AiFillDelete
                onClick={() => {
                  let filteredTypes = attrData.filter((curr) => curr !== item);
                  setAttrData(filteredTypes);
                }}
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
                size={"1.2rem"}
              />
            </div>
          ))}
  
        <label
          style={{
            display: "block",
            width: "100%",
            fontSize: "12px",
            fontWeight: "bold",
            marginTop: "5px",
          }}
        >
          {t("Add attribute")}
        </label>
  
        <AddAttribute
          attrData={attrData}
          setAttrData={setAttrData}
          key={attributeList.length}
        />
        {apiError && <p style={{ color: "red", fontSize: "12px" }}>{apiError}</p>}
  
        {/* <Row className="addAtrTxt mt-5 mx-3 py-1">
          <Col>+ Add Attribute</Col>
        </Row> */}
  
        <ButtonToggle
          onClick={addCategory}
          className="tglBtn"
          disabled={creatingCategory}
        >
          <span style={{ color: "#fff" }}>{t("Save")}</span>
        </ButtonToggle>
      </>
    );
}

