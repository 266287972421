import React from "react";
import { Row, Col } from "react-bootstrap";

export default function ModalLayout() {
    <div>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          Seller Id
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>1130</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          Seller Name
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>Peter Griffin</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          Closing Date
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>06-July-2021</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          Amount
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>40,000AKZ</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          BayQi Commision
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>500AKZ</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          SMS Charges
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>120AKZ</span>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
          Net Amount
        </span>
      </Col>
      <Col md={6}>
        <span style={{ color: "#333", fontSize: "0.8rem" }}>190AKZ</span>
      </Col>
    </Row>
  </div>
}
