import React, { useState } from "react";
import axios from "axios";
import {Tabs, Tab } from "react-bootstrap";
import { baseUrl } from "../../../config";
import { useHistory } from "react-router";
import EcomPaymentTransactions from "./EcomPaymentTransactions";
import DirectPaymentTransactions from "./DirectPaymentTransactions";
import QrPaymentTransactions from "./QrPaymentTransactions";
import AllTransactions from "./AllTransactions";
import QrCatalogTransactions from "./QrCatalogTransactions";
import LinkTransactions from "./LinkTransactions";
import DeliveryTransactions from "./DeliveryTransactions";

import PickupDeliveryTransactions from "./PickupDeliveryTransactions";

import PostOfficeDeliveryTransactions from "./PostOfficeDeliveryTransactions";
import { useTranslation } from "react-i18next";

const Transactions = () => {
  const { t, i18n } = useTranslation();
  // store orders here

  // fetch all the orders
  // React.useEffect(() => {
  //   getOrders("pickup");
  //   getOrders("delivery");
  // }, []);

  const getOrders = async (
    delivery = "",
    startDate = "",
    endDate = "",
    page = 1,
    limit = 10
  ) => {
    return axios
      .get(
        `${baseUrl}/orders?delivery=${delivery}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`
      )
      .then((res) => res);
  };

  // const getOrders = (dataType) => {
  //   axios
  //     .get(`${baseUrl}/orders?delivery=${dataType}`)
  //     .then((res) => {
  //       if (dataType === "delivery") {
  //         setDeliveryOrders(res.data.data);
  //         
  //       } else if (dataType === "pickup") {
  //         
  //         setPickupOrders(res.data.data);
  //       }
  //     })
  //     .catch((error) =>
  //     );
  // };

  const [key, setKey] = useState("direct");
  const [modal, setModal] = useState(false);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleDeliveryModal = () => setDeliveryModal(!deliveryModal);
  const [loading, setLoading] = React.useState(true);
  const [currentOrder, setCurrentOrder] = React.useState();

  const [allOrders, setAllOrders] = useState([]);
  const [noOfPagesAll, setNoOfPagesAll] = useState([]);
  const [limitAll, setLimitAll] = useState(10);
  const [activePageAll, setActivePageAll] = useState(1);
  const [startDateAll, setStartDateAll] = useState("");
  const [endDateAll, setEndDateAll] = useState("");

  const history = useHistory();

  // React.useEffect(() => {
  //   setNoOfPagesAll([]);
  //   setLoading(true);
  //   getOrders("", startDateAll, endDateAll, activePageAll, limitAll)
  //     .then((res) => {
  //       if (res.data.success) setAllOrders(res.data.data);
  //       setLoading(false);
  //       let num = res.data.count / limitAll;
  //       if (num < 1) setNoOfPagesAll([1]);
  //       else {
  //         num = Math.ceil(num);
  //         for (let i = 0; i < num; i++) {
  //           setNoOfPagesAll((oldArray) => [...oldArray, i + 1]);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       
  //     });
  // }, [startDateAll, endDateAll, activePageAll, limitAll]);

  // if (loading) {
  //   return (
  //     <span className="spinnerContainer">
  //       <Spinner animation="border" variant="primary" />
  //     </span>
  //   );
  // }

  return (
    <Tabs
      defaultActiveKey={key}
      onSelect={(k) => setKey(k)}
      id="controlled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="qrPayment" title={t("QR Payment")}>
        <QrPaymentTransactions />
      </Tab>
      <Tab eventKey="qrCatalog" title={t("QR Catalog")}>
        <QrCatalogTransactions />
      </Tab>
      <Tab eventKey="direct" title={t("Direct Payment")}>
        <DirectPaymentTransactions />
      </Tab>
      <Tab eventKey="marketplace" title={t("Marketplace")}>
        <EcomPaymentTransactions />
      </Tab>
      <Tab eventKey="link" title={t("Link Payment")}>
        <LinkTransactions />
      </Tab>
      <Tab eventKey="all" title={t("All")}>
        <AllTransactions />
      </Tab>
      {/* <Tab eventKey="pickup" title={t("Pickup")}>
        <PickupDeliveryTransactions />
      </Tab> */}
      <Tab eventKey="PostOffice" title={t("Post Office")}>
        <PostOfficeDeliveryTransactions />
      </Tab>
      <Tab eventKey="delivery" title={t("Delivery")}>
        <DeliveryTransactions />
      </Tab>
    </Tabs>
  );
};

export default Transactions;
