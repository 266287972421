import React, { useState } from "react";
import { Row, Col, Table, Pagination, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import Pages from "../../Components/Pagination/Pagination";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { baseUrl } from "../../../config";
// import { getUploadWalletRequests } from "../../../common/api";
import noDataFound from "../../Assets/images/nodata.png";
import { useTranslation } from "react-i18next";
import {
  updateRequestStatus,
  getUploadWalletRequests,
} from "../../../api/UploadWallet/api";
import ModalLayout from "./ModalLayout";

const UploadWallet = () => {
  const { t, i18n } = useTranslation();
  // const today = `${
  //   new Date().getMonth() + 1
  // }/${new Date().getDate()}/${new Date().getFullYear()}`;

  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const toggle = (isRefresh=false) => {
  setModal(!modal);
  if (isRefresh === true) {
    loadData();
  }
  };
  const [loading, setLoading] = useState(false);
  const [currRecord, setCurrRecord] = useState(null);
  const [dropDownValue, setDropDownValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [noOfPages, setNoOfPages] = useState([]);

  React.useEffect(() => {
    loadData();
  }, [startDate, endDate, dropDownValue, limit, activePage]);

  const loadData = () => {
    setNoOfPages([]);
    setLoading(true);
    getUploadWalletRequests(
      startDate,
      endDate,
      dropDownValue,
      activePage,
      limit
    )
      .then((res) => {
        if (res.data.success) setTableData(res.data.data);
        setLoading(false);
        let num = res.data.count / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          for (let i = 0; i < num; i++) {
            setNoOfPages((oldArray) => [...oldArray, i + 1]);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const exportCsv = () => {
    saveAs(
      `${baseUrl}/wallet/1/customers/wallet/upload/exportCSV?startDate=${startDate}&endDate=${endDate}&status=${dropDownValue}`,
      ""
    );
  };

  if (loading) {
    return (
      <span className="spinnerContainer">
        <Spinner animation="border" variant="primary" />
      </span>
    );
  }

  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader">
        <Row style={{ width: "100%" }}>
          <Col
            xl={3}
            lg={3}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  visibility: "hidden",
                }}
              >
                show all
              </span>

              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: "1.5rem",
                }}
              >
                {t("Upload Wallet")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>

            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "30%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Status
            </span>

            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span className="showAllSpan">
                  {dropDownValue === "" 
                  ? "Show All" 
                  :dropDownValue === "APPROVED"
                  ? "Approved"
                  :dropDownValue === "PENDING"
                  ? "Pending"
                  :dropDownValue === "REJECTED"
                  ? "Rejected"
                  : dropDownValue}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem onClick={() => setDropDownValue("")}>
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem onClick={() => setDropDownValue("APPROVED")}>
                  {t("Approved")}
                </DropdownItem>
                <DropdownItem onClick={() => setDropDownValue("PENDING")}>
                  {t("Pending")}
                </DropdownItem>
                <DropdownItem onClick={() => setDropDownValue("REJECTED")}>
                  {t("Rejected")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => {
                  setActivePage(1);
                  // if (e.target.value === "") setStartDate(today);
                  // else
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </Col>

          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => {
                  setActivePage(1);
                  // if (e.target.value === "") setEndDate(today);
                  // else
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      {/* Table Content */}
      {tableData.length > 0 ? (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Date & Time")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Transaction ID")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Name")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Wallet Number")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Amount")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Operation Number")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Status")}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr
                onClick={() => {
                  setCurrRecord(item);
                  toggle();
                }}
                style={{
                  backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                  fontSize: "12px",
                  color: "#4f4f4f",
                  fontFamily: "sans-serif",
                }}
              >
                <td style={{ padding: "20px" }}>
                  {new Date(item.createdAt).toLocaleDateString()}
                  {" - "}
                  {new Date(item.createdAt).toLocaleTimeString()}
                </td>
                <td style={{ padding: "20px" }}>{item.transactionId}</td>
                <td style={{ padding: "20px" }}>
                  {`${item.customerDetails.firstName} ${item.customerDetails.surName}`}
                </td>

                <td style={{ padding: "20px" }}>{item.walletId}</td>
                <td style={{ padding: "20px" }}>
                  {new Intl.NumberFormat().format(item.amount)} AKZ
                </td>
                <td style={{ padding: "20px" }}>{item.operationNumber}</td>
                <td style={{ padding: "20px" }}>
                  {item.status === "APPROVED"
                    ? "Approved"
                    : item.status === "PENDING"
                    ? "Pending"
                    : item.status === "REJECTED"
                    ? "Rejected"
                    : null
                    }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="noDataContainer">
          <img src={noDataFound} width="200px" />
          <p style={{ textAlign: "center" }}>{t("No data available")}</p>
        </div>
      )}
       {tableData.length > 0 && (
            <div style={{ height: "100px" }}>
              <Row style={{ display: "block", paddingBottom: "0px" }}>
                <div>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Pages
                        noOfPages={noOfPages}
                        activePage={activePage}
                        setActivePage={setActivePage}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle
                          style={{
                            backgroundColor: "#fff",
                            color: "#333",
                            borderColor: "#CED4DA",
                            fontSize: "0.9rem",
                            width: "100%",
                            margin: "auto",
                          }}
                          caret
                        >
                          <span
                            style={{
                              color: "#333",
                              fontSize: "0.7rem",
                            }}
                          >
                            {t("Items per page")}
                          </span>
                          <span
                            style={{
                              marginLeft: "5px",
                              fontWeight: "bold",
                              marginRight: "5px",
                            }}
                          >
                            {limit}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu style={{ marginTop: "5px" }}>
                          <DropdownItem
                            onClick={() => {
                              setLimit(1);
                              setActivePage(1);
                            }}
                          >
                            1
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(5);
                              setActivePage(1);
                            }}
                          >
                            5
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(10);
                              setActivePage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <ModalLayout
            toggle={toggle}
            data={currRecord}
            getUploadWalletRequests={getUploadWalletRequests}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default UploadWallet;
