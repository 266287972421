import React, { useState } from "react";
import { Row, Col, Table, Pagination, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import noDataFound from "../../Assets/images/nodata.png";
import StatsCard from "./StatsCard";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import "./Finances.scss";
// import { getPaymentCycleData } from "../../../common/api";
import axios from "axios";
import { baseUrl } from "../../../config";
import Pages from "../../Components/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import { getStatCards, getPaymentCycleData } from "../../../api/Finances/api";
import {getSellerBalanace,getSellerCaptiveBalance} from "../../../api/Kpi/api"
import ModalLayout from "./ModalLayout";

const Finances = () => {
  const { t, i18n } = useTranslation();
  const [cards, setCards] = React.useState([]);
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = React.useState(true);
  const [limit, setLimit] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const toggle = () => setModal(!modal);
  const loadStatCard=async()=>{
    setLoading(true);
    try{
      const sellerBalanceResponse=await getSellerBalanace();
      const captivegBalanceResponse=await getSellerCaptiveBalance();
      setCards([
          {
            title: t("Captive Balance"),
            amount: captivegBalanceResponse.data.captivegBalance ? captivegBalanceResponse.data.captivegBalance : 0,
            currency: true,
          },
          {
            title: t("Accounting Balance"),
            amount: sellerBalanceResponse.data.accountingBalance ? sellerBalanceResponse.data.accountingBalance : 0,
            currency: true,
          },
          {
            title: t("Available Balance"),
            amount: sellerBalanceResponse.data.availabaleBalance ? sellerBalanceResponse.data.availabaleBalance : 0,
            currency: true,
          },
        ]);
      setLoading(false);
    }
    catch(err){
      setLoading(false);
    }
  }
  React.useEffect(() => {
    setLoading(true);
    loadStatCard()
  }, []);
  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/payment-cycle/1/sellers/payment-cycle/export?&startDate=${startDate}&endDate=${endDate}`,
        ""
      );
    } catch (e) {
    }
  };
  React.useEffect(() => {
    setNoOfPages([]);
    setLoading(true);
    getPaymentCycleData(activePage, limit, startDate, endDate)
      .then((res) => {
        setTableData(res.data.result);
        setLoading(false);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [activePage, limit, startDate, endDate]);

  return (
    <div>
      {/* Header */}
      <div className="financesHeader">
        <Row style={{ width: "100%" }}>
          <Col
            xl={3}
            lg={3}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  visibility: "hidden",
                }}
              >
                show all
              </span>

              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: "1.5rem",
                }}
              >
                {t("Finances")}
              </span>
            </div>
          </Col>
          {/* <Col xl={3} lg={3} style={{ visibility: "hidden" }}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              search
            </span>

            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col> */}
          <Col xl={2} lg={2} style={{ visibility: "hidden" }}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>

            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span className="showAllSpan">{t("Show All")}</span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>Another Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Another Action</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  marginTop: "0px",
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "25%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2} >
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col xl={2} lg={2} >
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
        </Row>
      </div>

      {/* Stats Cards */}
      <div className="mt-4">
        {cards.map((card) => (
          <StatsCard {...card} />
        ))}
      </div>
      <br />

      {/* Table Content */}
      {loading ? (
        <span
          className="spinnerContainer"
          style={{
            height: "50vh",
            width: "100%",
            // marginLeft: "50%",
            // marginTop: "50px",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Date & Time")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Phone Number")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Store Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("No of Transactions")}
                </th>
                {/* <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Period Id")}
              </th> */}
                {/* <th style={{ padding: "20px", fontSize: "14px" }}>No of Payments</th> */}
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Comissions")}
                </th>
                {/* <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("BayQi Charges")}
                </th> */}
                {/* <th style={{ padding: "20px", fontSize: "14px" }}>SMS Charges</th> */}
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Net Amount")}
                </th>
              </tr>
            </thead>
            {tableData.length > 0 ? (
              <tbody>
                {tableData.map((item, index) => (
                  <tr
                    // onClick={toggle}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>
                      {item.createdAt
                        ? `${new Date(item.createdAt).toLocaleDateString()} - ${new Date(item.createdAt).toLocaleTimeString()}`
                        : "..."}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item?.sellerDetail[0]?.businessInfo?.phoneNumber}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item?.sellerDetail[0]?.businessInfo?.storeName}
                    </td>
                    <td style={{ padding: "20px" }}>
                  {item.noOfTransactions ? item.noOfTransactions : "..."}
                </td>
                    
                    {/* <td style={{ padding: "20px" }}>
                  {item.numberOfCycles}
                </td> */}
                    <td style={{ padding: "20px" }}>
                      {/* {new Intl.NumberFormat().format(item.amount)} AKZ */}
                      {item.amount
                        ? `${new Intl.NumberFormat().format(item.amount)} AKZ`
                        : item.amountToTransfer
                        ? `${new Intl.NumberFormat().format(
                            item.amountToTransfer
                          )} AKZ`
                        : "..."}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.commission ? item.commission : "..."}
                    </td>
                    {/* <td style={{ padding: "20px" }}>
                      {item.commission
                        ? `${new Intl.NumberFormat().format(
                            item.commission
                          )} AKZ`
                        : "..."}
                    </td> */}
                    {/* <td style={{ padding: "20px" }}>
                  {new Intl.NumberFormat().format(item.sms)} AKZ
                </td> */}
                    <td style={{ padding: "20px" }}>
                      {item.netAmount
                        ? `${new Intl.NumberFormat().format(
                            item.netAmount
                          )} AKZ`
                        : item.netAmount
                        ? `${new Intl.NumberFormat().format(
                            item.netAmount
                          )} AKZ`
                        : "..."}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={1000}>
                    <div className="noDataContainer">
                      <img alt="no data" src={noDataFound} width="200px" />
                      <p>{t("No data available")}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>

          <div style={{ height: "100px" }}>
            <Row style={{ display: "block", paddingBottom: "0px" }}>
              <div>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  {/* <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination>
                  {[1, 2, 3, 4, 5].map((item, index) => (
                    <Pagination.Item
                      onClick={() => setActivePage(item)}
                      active={activePage === item}
                    >
                      {item}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </div> */}
                  <Pages
                    noOfPages={noOfPages}
                    activePage={activePage}
                    setActivePage={setActivePage}
                  />
                  {/* No of products on page toggler */}
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <UncontrolledDropdown style={{ width: "100%" }}>
                      <DropdownToggle
                        style={{
                          backgroundColor: "#fff",
                          color: "#333",
                          borderColor: "#CED4DA",
                          fontSize: "0.9rem",
                          width: "100%",
                        }}
                        caret
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSize: "0.7rem",
                          }}
                        >
                          {t("Items per page")}
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {limit}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu style={{ marginTop: "5px" }}>
                        <DropdownItem
                          onClick={() => {
                            setLimit(1);
                            setActivePage(1);
                          }}
                        >
                          1
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setLimit(5);
                            setActivePage(1);
                          }}
                        >
                          5
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setLimit(10);
                            setActivePage(1);
                          }}
                        >
                          10
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </>
      )}
      {/* Transactions Details Modal */}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <ModalLayout />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default Finances;
