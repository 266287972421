import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Modal,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import Pages from "../../Components/Pagination/Pagination";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import noDataFound from "../../Assets/images/nodata.png";
import {
  getApprovedCategories,
  getPendingCategoriesApi,
} from "../../../api/Categories/api";
import AddCategoryModal from "./AddCategoryModal";
import CategoryDetailModal from "./CategoryDetailModal";
import UpdateCategoryModal from "./UpdateCategoryModal";

const Categories = () => {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState("all");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(true);
  // items per page
  // cat update modal
  const [updateModal, setUpdateModal] = useState(false);
  const toggleUpdateModal = () => setUpdateModal(!updateModal);
  const [currItemToUpdate, setCurrItemToUpdate] = useState(null);
  // cat detail modal
  const [catDetailModal, setCatDetailModal] = useState(false);
  const [currCatData, setCurrCatData] = useState(null);
  const toggleCatModal = () => setCatDetailModal(!catDetailModal);
  // search field
  // search pending field
  const [pendingSearch, setPendingSearch] = useState("");
  // data found on search or not
  const [dataFound, setDataFound] = useState(true);
  // pending data found or not
  const [pendingDataFound, setPendingDataFound] = useState(true);
  // search button loader state
  const [searchLoading, setSearchLoading] = useState(false);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      alert(row.title);
    },
  };

  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [qty, setQty] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);

  React.useEffect(() => {
    setLoading(true);
    getCategories(activePage, qty, search);
  }, [activePage, qty]);

  const [pendingCategories, setPendingCategories] = useState([]);
  const [activePagePending, setActivePagePending] = useState(1);
  const [searchPending, setSearchPending] = useState("");
  const [qtyPending, setQtyPending] = useState(10);
  const [noOfPagesPending, setNoOfPagesPending] = useState([]);
  const [pendingSearchLoading, setPendingSearchLoading] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    getPendingCategories(activePagePending, qtyPending, searchPending);
  }, [activePagePending, qtyPending]);

  // run after activePage/qty changes
  // React.useEffect(() => {
  //   setDataFound(true);
  //   getCategories();
  // }, [activePage, qty]);

  // React.useEffect(() => {
  //   getPendingCategories();
  // }, []);

  // // get all approved categories
  const getCategories = () => {
    setLoading(true);
    // axios
    //   .get(
    //     `${baseUrl}/categories?approved=approved&page=${activePage}&limit=${qty}&name=${search}`
    //   )
    getApprovedCategories(activePage, qty, search)
      .then((res) => {
        setNoOfPages([]);
        setTableData(res.data.data);
        setSearchLoading(false);
        setLoading(false);
        let num = res.data.count / qty;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempNoOfPages = [];
          for (let i = 0; i < num; i++) {
            // setNoOfPages((oldArray) => [...oldArray, i + 1]);
            tempNoOfPages.push(i + 1);
          }
          setNoOfPages(tempNoOfPages);
        }
      })
      .catch((error) => {
        setSearchLoading(false);
        setLoading(false);
      });
  };

  const getPendingCategories = () => {
    setLoading(true);
    // axios
    //   .get(
    //     `${baseUrl}/categories?approved=pending&page=${activePagePending}&limit=${qtyPending}&name=${searchPending}`
    //   )
    getPendingCategoriesApi(activePagePending, qtyPending, searchPending)
      .then((res) => {
        setNoOfPagesPending([]);
        setPendingCategories(res.data.data);
        setPendingSearchLoading(false);
        setLoading(false);
        let num = res.data.count / qtyPending;
        if (num < 1) setNoOfPagesPending([1]);
        else {
          num = Math.ceil(num);
          for (let i = 0; i < num; i++) {
            setNoOfPagesPending((oldArray) => [...oldArray, i + 1]);
          }
        }
      })
      .catch((error) => {
        setPendingSearchLoading(false);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <span className="spinnerContainer">
        <Spinner animation="border" variant="primary" />
      </span>
    );
  }
  
  return (
    <Tabs
      defaultActiveKey={activeTab}
      onSelect={(k) => setActiveTab(k)}
      id="controlled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="all" title="Approved">
        <div className="">
          {/* Header */}
          <div className="financesHeader">
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "20px",
                paddingRight: "20px",
              }}
            >
              <Col
                xl={3}
                lg={3}
                style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
              >
                <div>
                  <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                    {t("Approved Categories")}
                  </span>
                </div>
              </Col>
              {/* col to fill space */}
              <Col xl={5} lg={5} style={{ visibility: "hidden" }}>
                <div class="input-group rounded">
                  <input
                    type="search"
                    class="form-control rounded"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-addon"
                  />
                </div>
              </Col>
              {/* search by name */}
              <Col xl={2} lg={2}>
                <div class="input-group rounded">
                  <input
                    type="search"
                    class="form-control rounded"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-addon"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </Col>
              <Col xl={1} lg={1}>
                <div className="d-grid">
                  <Button
                    onClick={() => {
                      setSearchLoading(true);
                      getCategories(activePage, qty, search);
                    }}
                    disabled={searchLoading}
                    type="button"
                    style={{
                      backgroundColor: `${blue}`,
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    {searchLoading ? t("Loading...") : t("Search")}
                  </Button>
                </div>
              </Col>
              {/* add cat button */}
              <Col xl={1} lg={1}>
                <div className="d-grid">
                  <Button
                    onClick={() => toggle()}
                    type="button"
                    style={{
                      backgroundColor: `${blue}`,
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    {t("Add")}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          {/* Table Content */}
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>{t("ID")}</th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Domain")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Category Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Status")}
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  // onClick={() => alert('helllll')}
                  style={{
                    backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                    fontSize: "12px",
                    color: "#4f4f4f",
                    fontFamily: "sans-serif",
                  }}
                >
                  <td style={{ padding: " 20px" }}>
                    {item.publicID && item.publicID}
                  </td>
                  <td style={{ padding: "20px" }}>
                    {item.businessDomain && item.businessDomain}
                  </td>
                  <td style={{ padding: "20px" }}>
                    {item.title && item.title}
                  </td>
                  <td style={{ padding: "20px" }}>
                    {item.isApproved && item.isApproved}
                  </td>
                  <td style={{ padding: "20px" }}>
                    <span
                      style={{ color: "#0269AA", textDecoration: "underline" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleUpdateModal();
                        setCurrItemToUpdate(item);
                      }}
                    >
                      {t("update")}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!dataFound && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <img alt="" src={noDataFound} width="200px" />
              <p>{t("No data available")}</p>
            </div>
          )}
          <div style={{ height: "100px" }}>
            <Row style={{ display: "block", paddingBottom: "0px" }}>
              <div>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* Pagination */}
                    <Pages
                      noOfPages={noOfPages}
                      activePage={activePage}
                      setActivePage={setActivePage}
                    />
                  </div>
                  {/* No of products on page toggler */}
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <UncontrolledDropdown style={{ width: "100%" }}>
                      <DropdownToggle
                        style={{
                          backgroundColor: "#fff",
                          color: "#333",
                          borderColor: "#CED4DA",
                          fontSize: "0.9rem",
                          width: "100%",
                        }}
                        caret
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSize: "0.7rem",
                          }}
                        >
                          {t("Items per page")}
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {qty}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu style={{ marginTop: "5px" }}>
                        <DropdownItem onClick={() => setQty(1)}>1</DropdownItem>
                        <DropdownItem onClick={() => setQty(5)}>5</DropdownItem>
                        <DropdownItem onClick={() => setQty(10)}>
                          10
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
          {/* add category modal */}
          <Modal show={modal} onHide={toggle}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                {t("Add Category")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <AddCategoryModal
                toggle={toggle}
                getTableCategories={getCategories}
              />
            </Modal.Body>
          </Modal>

          {/* update cat modal */}
          <Modal show={updateModal} onHide={toggleUpdateModal}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                {t("Update Category")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <UpdateCategoryModal
                data={currItemToUpdate}
                toggle={toggleUpdateModal}
                getCategories={getCategories}
              />
            </Modal.Body>
          </Modal>
        </div>
      </Tab>
      <Tab eventKey="pending" title="Pending">
        <div className="">
          {/* Header */}
          <div className="financesHeader">
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "20px",
                paddingRight: "20px",
              }}
            >
              <Col
                xl={3}
                lg={3}
                style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
              >
                <div>
                  <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                    {t("Pending Categories")}
                  </span>
                </div>
              </Col>
              {/* col to fill space */}
              <Col xl={6} lg={6} style={{ visibility: "hidden" }}>
                <div class="input-group rounded">
                  <input
                    type="search"
                    class="form-control rounded"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-addon"
                  />
                </div>
              </Col>
              {/* search by name */}
              <Col xl={2} lg={2}>
                <div class="input-group rounded">
                  <input
                    type="search"
                    class="form-control rounded"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-addon"
                    value={searchPending}
                    onChange={(e) => setSearchPending(e.target.value)}
                  />
                </div>
              </Col>
              <Col xl={1} lg={1}>
                <div className="d-grid">
                  <Button
                    onClick={() => {
                      setPendingSearchLoading(true);
                      getPendingCategories(
                        activePagePending,
                        qtyPending,
                        searchPending
                      );
                    }}
                    disabled={pendingSearchLoading}
                    type="button"
                    style={{
                      backgroundColor: `${blue}`,
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    {t("Search")}
                  </Button>
                </div>
              </Col>
              {/* add cat button */}
              {/* <Col xl={1} lg={1}>
                <div className="d-grid">
                  <Button
                    onClick={() => toggle()}
                    type="button"
                    style={{
                      backgroundColor: `${blue}`,
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    Add
                  </Button>
                </div>
              </Col> */}
            </Row>
          </div>

          {/* Table Content */}

          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>{t("ID")}</th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Domain")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Category Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Status")}
                </th>
              </tr>
            </thead>
            <tbody>
              {pendingCategories &&
                pendingCategories.map((item, index) => (
                  <tr
                    onClick={() => {
                      toggleCatModal();
                      setCurrCatData(item);
                    }}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>
                      {item.publicID && item.publicID}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.businessDomain && item.businessDomain}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.title && item.title}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.isApproved && item.isApproved}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {!pendingDataFound && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <img alt="" src={noDataFound} width="200px" />
              <p>{t("No data available")}</p>
            </div>
          )}
          <div style={{ height: "100px" }}>
            <Row style={{ display: "block", paddingBottom: "0px" }}>
              <div>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* Pagination */}
                    {/* <Pagination>
                      {noOfPagesPending.map((item, index) => (
                        <Pagination.Item
                          onClick={() => setActivePagePending(item)}
                          active={activePagePending === item}
                        >
                          {item}
                        </Pagination.Item>
                      ))}
                    </Pagination> */}
                    <Pages
                      noOfPages={noOfPagesPending}
                      activePage={activePagePending}
                      setActivePage={setActivePagePending}
                    />
                  </div>
                  {/* No of products on page toggler */}
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <UncontrolledDropdown style={{ width: "100%" }}>
                      <DropdownToggle
                        style={{
                          backgroundColor: "#fff",
                          color: "#333",
                          borderColor: "#CED4DA",
                          fontSize: "0.9rem",
                          width: "100%",
                        }}
                        caret
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSize: "0.7rem",
                          }}
                        >
                          {t("Items per page")}
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {qtyPending}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu style={{ marginTop: "5px" }}>
                        <DropdownItem onClick={() => setQtyPending(1)}>
                          1
                        </DropdownItem>
                        <DropdownItem onClick={() => setQtyPending(5)}>
                          5
                        </DropdownItem>
                        <DropdownItem onClick={() => setQtyPending(10)}>
                          10
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </div>
            </Row>
          </div>

          {/* <div>
        {tableData !== [] ? (
          <TableViewTemplate
            keyField={"_id"}
            data={tableData}
            columns={categoryColumns}
            event={true}
            rowEvents={rowEvents}
          />
        ) : null}
        </div> */}

          {/* <div className="noDataContainer">
        <img src={noDataFound} width="200px" />
        <p>No data available</p>
        </div> */}

          {/* <Modal show={modal} onHide={toggle}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                Add Category
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <AddCategoryModal toggle={toggle} />
            </Modal.Body>
          </Modal> */}

          <Modal show={catDetailModal} onHide={toggleCatModal}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                {t("Category Details")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <CategoryDetailModal
                toggle={toggleCatModal}
                data={currCatData}
                getCategories={getCategories}
                getPendingCategories={getPendingCategories}
              />
            </Modal.Body>
          </Modal>
        </div>
      </Tab>
    </Tabs>
  );
};

export default Categories;
