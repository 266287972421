import "../Components/SideDrawer/SideDrawer.css";
import Login from "../Pages/Login/Login";
import HeaderWithDrawer from "./HeaderWithDrawer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Stats from "../Pages/Stats/Stats";
import Kpi from "../Pages/Kpi/Kpi";
import Finances from "../Pages/Finances/Finances";
import TransferFriend from "../Pages/TransferFriend/TransferFriend";
import UploadWallet from "../Pages/UploadWallet/UploadWallet";
import BankTransferUser from "../Pages/BankTransferUser/BankTransferUser";
import BankTransferSeller from "../Pages/BankTransferSeller/BankTransferSeller";
import Users from "../Pages/Users/Users";
import Sellers from "../Pages/Sellers/Sellers";
import WalletBalance from "../Pages/WalletBalance/WalletBalance";
import Advertisements from "../Pages/Advertisements/Advertisements";
import PromosAndDiscounts from "../Pages/PromosAndDiscounts/PromosAndDiscounts";
import Blogs from "../Pages/Blogs/Blogs";
import Telopay from "../Pages/Telopay/Telopay";
import Companies from "../Pages/Companies/Companies";
import DeliveryZones from "../Pages/DeliveryZones/DeliveryZones";
import Services from "../Pages/Services/Services";
import Categories from "../Pages/Categories/Categories";
import AccountSettings from "../Pages/AccountSettings/AccountSettings";
import BayqiLogistics from "../Pages/BayqiLogistics/BayqiLogistics";
import Transactions from "../Pages/Transactions/Transactions";
import OrderDetails from "../Pages/OrderDetails/OrderDetails";
import ShopCenters from "../Pages/ShopCenters/ShopCenters";
import Refund from "../Pages/Refund/Refund"
import Commission from "../Pages/Commission/Commission"
import UserHistory from "../Pages/UserHistory/UserHistory";

const routes = [
  {
    path: "/",
    exact: true,
    name: "Stats",
    component: Stats,
  },
  {
    path: "/Kpi",
    exact: true,
    name: "Kpi",
    component: Kpi,
  },
  {
    path: "/Finances",
    exact: true,
    name: "Finances",
    component: Finances,
  },
  {
    path: "/Commission",
    exact: true,
    name: "Commission",
    component: Commission,
  },
  {
    path: "/TransferFriend",
    exact: true,
    name: "TransferFriend",
    component: TransferFriend,
  },
  {
    path: "/UploadWallet",
    exact: true,
    name: "UploadWallet",
    component: UploadWallet,
  },
  {
    path: "/BankTransferUser",
    exact: true,
    name: "BankTransferUser",
    component: BankTransferUser,
  },
  {
    path: "/BankTransferSeller",
    exact: true,
    name: "BankTransferSeller",
    component: BankTransferSeller,
  },
  {
    path: "/Users",
    exact: true,
    name: "Users",
    component: Users,
  },
  {
    path: "/Sellers",
    exact: true,
    name: "Sellers",
    component: Sellers,
  },
  {
    path: "/WalletBalance",
    exact: true,
    name: "WalletBalance",
    component: WalletBalance,
  },
  {
    path: "/Advertisements",
    exact: true,
    name: "Advertisements",
    component: Advertisements,
  },
  {
    path: "/PromosAndDiscounts",
    exact: true,
    name: "PromosAndDiscounts",
    component: PromosAndDiscounts,
  },
  {
    path: "/Blogs",
    exact: true,
    name: "Blogs",
    component: Blogs,
  },
  {
    path: "/Telopay",
    exact: true,
    name: "Telopay",
    component: Telopay,
  },
  // {
  //   path: "/Companies",
  //   exact: true,
  //   name: "Companies",
  //   component: Companies,
  // },
  {
    path: "/DeliveryZones",
    exact: true,
    name: "DeliveryZones",
    component: DeliveryZones,
  },
  {
    path: "/Services",
    exact: true,
    name: "Services",
    component: Services,
  },
  {
    path: "/Categories",
    exact: true,
    name: "Categories",
    component: Categories,
  },
  {
    path: "/AccountSettings",
    exact: true,
    name: "AccountSettings",
    component: AccountSettings,
  },
  {
    path: "/BayqiLogistics",
    exact: true,
    name: "BayqiLogistics",
    component: BayqiLogistics,
  },
  {
    path: "/Transactions",
    exact: true,
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/Refund",
    exact: true,
    name: "Refund",
    component: Refund,
  },
  {
    path: "/OrderDetails",
    exact: true,
    name: "OrderDetails",
    component: OrderDetails,
  },
  {
    path: "/ShopCenters",
    exact: true,
    name: "ShopCenters",
    component: ShopCenters,
  },
  {
    path: "/UserHistory/:id",
    exact: true,
    name: "UserHistory",
    component: UserHistory,
  }
];

function RouterApp() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <HeaderWithDrawer>
          {routes.map((route, idx) => {
            return route.component ? (
              <PrivateRoute
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                Component={route.component}
              />
            ) : null;
          })}
        </HeaderWithDrawer>
      </Switch>
    </BrowserRouter>
  );
}

export default RouterApp;
