import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ModalLayout({ item }) {
    const { t, i18n } = useTranslation();
    return (
    <div>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Transaction Id")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.transactionId}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Date")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {" "}
            {new Date(item.createdAt).toLocaleDateString()}
            {" - "}
            {new Date(item.createdAt).toLocaleTimeString()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Wallet ID")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.walletId}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Name")}
          </span>
        </Col>
        <Col md={6}>
          <span
            style={{ color: "#333", fontSize: "0.8rem" }}
          >{`${item.customerDetails.firstName} ${item.customerDetails.surName}`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Description")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {`${t("You have added")} ${item.amount} AKZ ${t("in the wallet")}`}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Amount")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {" "}
            {new Intl.NumberFormat().format(item.amount)} AKZ
          </span>
        </Col>
      </Row>
    </div>
    );
}

