import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { ButtonToggle } from "reactstrap";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import {
  deleteShopCenters
} from "../../../api/ShopCenters/api";

export default function DeleteModal({ toggle, currItemId, getShoppingCenters }) {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const deleteItem = () => {
      setLoading(true);
      // axios
      //   .delete(`${baseUrl}/shopping-centers?id=${currItemId}`)
      deleteShopCenters(currItemId)
        .then((res) => {
          setLoading(false);
          toggle();
          getShoppingCenters();
        })
        .catch((err) => {
          setLoading(false);
        });
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonToggle
          onClick={deleteItem}
          disabled={loading}
          style={{
            margin: "20px 0px",
            backgroundColor: "#E65B65",
            borderWidth: 0,
            marginRight: "10px",
          }}
        >
          {loading ? (
            <Spinner animation="border" style={{ color: "white" }} size="sm" />
          ) : (
            t("Yes")
          )}
        </ButtonToggle>
  
        <ButtonToggle
          onClick={toggle}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
          }}
        >
          {t("No")}
        </ButtonToggle>
      </div>
    );
}
