import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Modal,
  Spinner
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import noDataFound from "../../Assets/images/nodata.png";
import AddModal from "./AddModal";


const PromosAndDiscounts = () => {
  const [tableData, setTableData] = useState([0]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    let timer1 = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  if (loading) {
    return (
      <span className="spinnerContainer">
        <Spinner animation="border" variant="primary" />
      </span>
    );
  }

  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader">
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: "20px",
            paddingRight: "20px",
          }}
        >
          <Col
            xl={3}
            lg={3}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div>
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                Promos & Discounts
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <div className="d-grid">
              <Button
                onClick={() => toggle()}
                type="button"
                style={{
                  backgroundColor: `${blue}`,
                  color: "#fff",
                  border: "none",
                }}
              >
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {/* Table Content */}
      <Table
        style={{
          cursor: "pointer",
          marginTop: "25px",
          backgroundColor: "#f7f7f7",
          borderRadius: "25px",
        }}
        borderless={true}
        hover
      >
        <thead>
          <tr>
            <th style={{ padding: "20px", fontSize: "16px" }}>Image</th>
            <th style={{ padding: "20px", fontSize: "16px" }}>ID</th>
            <th style={{ padding: "20px", fontSize: "16px" }}>Index</th>
            <th style={{ padding: "20px", fontSize: "16px" }}>Type</th>
            <th style={{ padding: "20px", fontSize: "16px" }}>Platform</th>
            <th style={{ padding: "20px", fontSize: "16px" }}>Status</th>
          </tr>
        </thead>
      </Table>

      <div className="noDataContainer">
        <img src={noDataFound} width="200px" />
        <p>No data available</p>
      </div>

      <Modal show={modal} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            Promo & Discount Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <AddModal />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PromosAndDiscounts;
