import React from "react";

import { ButtonToggle } from "reactstrap";

import { deleteAdd } from "../../../api/Advertisements/api";
import { v4 as uuid } from "uuid";

import { useTranslation } from "react-i18next";

export default function DeleteAddModal({ id, toggle }) {
  const { t, i18n } = useTranslation();
  const removeAdd = () => {
    deleteAdd(id)
      .then((res) => {
        toggle(true);
      })
      .catch((err) => {
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonToggle
        onClick={removeAdd}
        style={{
          margin: "20px 0px",
          backgroundColor: "#E65B65",
          borderWidth: 0,
          marginRight: "10px",
        }}
      >
        {t("Yes")}
      </ButtonToggle>

      <ButtonToggle
        onClick={toggle}
        style={{
          margin: "20px 0px",
          backgroundColor: "#049EFF",
          borderWidth: 0,
        }}
      >
        {t("No")}
      </ButtonToggle>
    </div>
  );
}
