import React, { useState, useEffect } from "react";
import { Row, Col, Table, Modal, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import noDataFound from "../../Assets/images/nodata.png";
import Select from "react-select";
import { useDebounce } from "use-debounce";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { baseUrl } from "../../../config/index";

import { getPostOfficeData } from "../../../api/BayqiLogistics/api";

import UpdatePostOfficeModal from "./UpdatePostOfficeModal";
import AddPostOfficeModal from "./AddPostOfficeModal";
import DeletePostOfficeModal from "./DeletePostOfficeModal";
import { useTranslation } from "react-i18next";

const PostOfficeTab = ({}) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);
  const [title, setTitle] = useState("");
  const [titleDebounce] = useDebounce(title, 1000);

  const [status, setStatus] = useState("");

  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/delivery/1/admin/post-office/csv?limit=&page=&title=${title}&status=${status}`,
        ""
      );
    } catch (e) {
    }
  };

  const [addModal, setAddModal] = useState(false);
  const addModalToggle = (isRefresh = false) => {
    setAddModal(!addModal);
    if (isRefresh === true) {
      fetchPostOfficeData();
    }
  };

  const [updateItem, setUpdateItem] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const updateModalToggle = (isRefresh = false) => {
    setUpdateModal(!updateModal);
    if (isRefresh === true) {
      fetchPostOfficeData();
    }
  };

  const [deleteItemId, setDeleteItemId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteModalToggle = (isRefresh = false) => {
    setDeleteModal(!deleteModal);
    if (isRefresh === true) {
      fetchPostOfficeData();
    }
  };

  useEffect(() => {
    fetchPostOfficeData();
  }, [titleDebounce, status]);

  const fetchPostOfficeData = () => {
    setLoading(true);

    getPostOfficeData(titleDebounce, status)
      .then((res) => {
        setTableData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
      });
  };

  return (
    <div>
      <div className="financesHeader">
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: "20px",
            paddingRight: "20px",
          }}
        >
          <Col
            xl={3}
            lg={3}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div>
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Post Offices")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            {/* <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span> */}
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "30%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            {/* <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span> */}
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search Name"
                aria-label="Search"
                aria-describedby="search-addon"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            {/* <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span> */}
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span className="showAllSpan">
                  {status === ""
                    ? t("Show All")
                    : status.slice(0, 1).toUpperCase() + status.slice(1)}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem
                  onClick={() => {
                    setStatus("");
                  }}
                >
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    setStatus("Inactive");
                  }}
                >
                  {t("Inactive")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    setStatus("Active");
                  }}
                >
                  {t("Active")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <div className="d-grid">
              <Button
                onClick={() => addModalToggle()}
                type="button"
                style={{
                  backgroundColor: `${blue}`,
                  color: "#fff",
                  border: "none",
                }}
              >
                {t("Add")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Country")}
              </th>
              {/* <th style={{ padding: "20px", fontSize: "16px" }}>{t("Province")}</th>
              <th style={{ padding: "20px", fontSize: "16px" }}>{t("Address")}</th> */}
              <th style={{ padding: "20px", fontSize: "16px" }}>{t("Name")}</th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Delivery Time")}
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>{t("Cost")}</th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("State")}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr
                onClick={() => {
                  setUpdateItem(item);
                  updateModalToggle();
                }}
                style={{
                  backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                  fontSize: "12px",
                  color: "#4f4f4f",
                  fontFamily: "sans-serif",
                }}
              >
                <td style={{ padding: "20px" }}>{item.country}</td>
                {/* <td style={{ padding: "20px" }}>{item.province}</td>
                <td style={{ padding: "20px" }}>{item.address}</td> */}
                <td style={{ padding: "20px" }}>{item.title}</td>
                <td style={{ padding: "20px" }}>
                  {item.deliveryTime > 0
                    ? `${item.deliveryTime} ${
                        item.deliveryTime > 1 ? "days" : "day"
                      }`
                    : "Same day"}
                </td>
                <td style={{ padding: "20px" }}>
                  {new Intl.NumberFormat().format(item.cost)} AKZ
                </td>
                <td style={{ padding: "20px" }}>{item.status}</td>
                <td style={{ padding: "20px" }}>
                  <span
                    style={{ color: "red", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteItemId(item._id);
                      deleteModalToggle();
                    }}
                  >
                    {t("Delete")}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Modal show={addModal} onHide={addModalToggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Add Post Office")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <AddPostOfficeModal toggle={addModalToggle} />
        </Modal.Body>
      </Modal>
      <Modal show={updateModal} onHide={updateModalToggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Edit Post Office")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <UpdatePostOfficeModal toggle={updateModalToggle} item={updateItem} />
        </Modal.Body>
      </Modal>
      <Modal centered show={deleteModal} onHide={deleteModalToggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Confirm Delete Post Office")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <DeletePostOfficeModal toggle={deleteModalToggle} id={deleteItemId} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PostOfficeTab;
