import instance from "./axios";

export const getRequestHistory = (
  page = 1,
  limit = 10,
  status,
  type = "",
  startDate = "",
  endDate = ""
) => {
  return instance
    .get(
      `/friends/transfer/wallet/money?limit=${limit}&page=${page}&status=${status}&type=${type}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => res);
};
