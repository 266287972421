import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Storage } from "aws-amplify";
import { updateAdd } from "../../../api/Advertisements/api";
import { v4 as uuid } from "uuid";

import { useTranslation } from "react-i18next";

export default function UpdateAddModal({ item, toggle }) {
  const { t, i18n } = useTranslation();
  const [updatingDomain, setUpdatingDomain] = useState(false);
  const [apiError, setApiError] = useState("");
  const [image, setImage] = useState(item.image);
  const [type, setType] = useState(item.type);
  const [platform, setPlatform] = useState(item.platform);
  const [status, setStatus] = useState(item.status);
  const [index, setIndex] = useState(item.index);
  const [showDeleteText, setShowDeleteText] = useState(false);

  const changeAdd = () => {
    setApiError("");
    setUpdatingDomain(true);
    if (
      image !== item.image ||
      index !== item.index ||
      type !== item.type ||
      platform !== item.platform ||
      status !== item.status
    ) {
      if (type === "" || platform === "" || image === "" || index === "") {
        setApiError(`(*) ${t("fields are mandatory")}`);
        setUpdatingDomain(false);
      } else {
        updateAdd(item._id, {
          index: index,
          image: image,
          type: type,
          platform: platform,
          status: status,
        })
          .then((res) => {
            setUpdatingDomain(false);
            toggle(true);
          })
          .catch((err) => {
            setUpdatingDomain(false);
          });
      }
    } else {
      setUpdatingDomain(false);
      setApiError(`${t("Change Values to update")}`);
    }
  };

  const onFileChange = async (e) => {
    setUpdatingDomain(true);
    const files = e.target.files;

    try {
      const fileKey = uuid() + files[0].name.replace(/\s/g, "-").toLowerCase();
      await Storage.put(fileKey, files[0]).then((res) => {
        setUpdatingDomain(false);
        setImage(`${process.env.REACT_APP_S3_BUCKET}/public/${res.key}`);
      });
    } catch (error) {
      setUpdatingDomain(false);
    }
  };

  const onDeleteImage = async (file) => {
    file = file.split("public/")[1];
    setUpdatingDomain(true);

    try {
      await Storage.remove(file).then((res) => {
        setUpdatingDomain(false);
      });
    } catch (e) {
      setUpdatingDomain(false);
    }
  };

  return (
    <>
      <Row className="d-flex flex-column">
        <Col className="col-12 mb-2">
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
              marginBottom: "15px",
            }}
          >
            {t("Add Image")}
          </span>
        </Col>
      </Row>
      <Row className="align-items-start flex-row">
        <Col style={{ display: "flex", flexDirection: "row" }}>
          {image != "" ? (
            <img
              alt={""}
              src={image}
              width={"65px"}
              height={"65px"}
              style={{ marginRight: "5px", cursor: "pointer" }}
              onClick={() => {
                setImage("");
                onDeleteImage(image);
              }}
              onMouseEnter={() => setShowDeleteText(true)}
              onMouseLeave={() => setShowDeleteText(false)}
            />
          ) : (
            <>
              <input
                style={{ display: "none" }}
                id="imageUpload"
                type="file"
                onChange={(e) => onFileChange(e)}
              />
              <label htmlFor="imageUpload" className="imgUploadLbl">
                <span>+</span>
                <p>{t("Add Image")}</p>
                <p style={{ color: "red", display: "inline" }}> *</p>
              </label>
            </>
          )}
        </Col>
        {image !== "" && (
          <p
            style={{
              color: "red",
              fontSize: "12px",
              visibility: !showDeleteText ? "hidden" : "visible",
            }}
          >
            *{t("click to delete")}
          </p>
        )}
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Col md={12}>
          <label
            htmlFor="lastName"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Index")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <input
            value={index}
            onChange={(e) => setIndex(e.target.value)}
            maxLength={"256"}
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            id="title"
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Col md={12}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Type")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <UncontrolledDropdown style={{ width: "100%" }}>
            <DropdownToggle
              style={{
                backgroundColor: "#fff",
                borderColor: "#CED4DA",
                color: "#333",
                fontSize: "0.9rem",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              caret
            >
              <span
                style={{
                  color: "#333",
                  fontSize: "0.9rem",
                }}
              >
                {type ? type : t("Select")}
              </span>
            </DropdownToggle>
            <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
              <DropdownItem onClick={() => setType("Banner")}>
                {t("Banner")}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => setType("Side")}>
                {t("Side")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>

      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Col md={12}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Platform")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <UncontrolledDropdown style={{ width: "100%" }}>
            <DropdownToggle
              style={{
                backgroundColor: "#fff",
                borderColor: "#CED4DA",
                color: "#333",
                fontSize: "0.9rem",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              caret
            >
              <span
                style={{
                  color: "#333",
                  fontSize: "0.9rem",
                }}
              >
                {platform ? platform : "Select"}
              </span>
            </DropdownToggle>
            <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
              <DropdownItem onClick={() => setPlatform("Web")}>
                {t("Web")}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => setPlatform("Mobile")}>
                {t("Mobile")}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => setPlatform("Both")}>
                {t("Both")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>

      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Col md={12}>
          <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
            {t("Status")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <UncontrolledDropdown style={{ width: "100%" }}>
            <DropdownToggle
              style={{
                backgroundColor: "#fff",
                borderColor: "#CED4DA",
                color: "#333",
                fontSize: "0.9rem",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              caret
            >
              <span
                style={{
                  color: "#333",
                  fontSize: "0.9rem",
                }}
              >
                {status
                  ? status === "active"
                    ? t("Active")
                    : t("Inactive")
                  : t("Select")}
              </span>
            </DropdownToggle>
            <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
              <DropdownItem onClick={() => setStatus("Active")}>
                {t("Active")}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => setStatus("Inactive")}>
                {t("Inactive")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>

      {apiError && <p style={{ color: "red", fontSize: "12px" }}>{apiError}</p>}
      <ButtonToggle
        onClick={changeAdd}
        disabled={updatingDomain}
        style={{
          margin: "20px 0px",
          backgroundColor: "#049EFF",
          borderWidth: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {updatingDomain ? (
          <Spinner
            animation="border"
            style={{ color: "white", margin: "4px" }}
            size="sm"
          />
        ) : (
          <span style={{ color: "#fff" }}>{t("Update")} </span>
        )}
      </ButtonToggle>
    </>
  );
}
