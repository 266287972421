import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { saveAs } from "file-saver";
import Select from "react-select";
import { useTranslation } from "react-i18next";
const customStyles = {
  option: (defaultStyles) => ({
    ...defaultStyles,
    fontSize: "12px",
  }),
  singleValue: (defaultStyles) => ({
    ...defaultStyles,
    fontSize: "12px",
  }),

  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: "12px",
    };
  },
};
export default function ModalLayout({ data, setStatus, updateStatus, status, updateLoading }) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Registration Date")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Date(data.createdAt).toDateString()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Registration Number")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {data?.regNumber && `0${data?.regNumber}`}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Owner Name")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{data.businessInfo.ownerName}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Store Name")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{data.businessInfo.storeName}</span>
        </Col>
      </Row>
      {/* <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            Nationality
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>400 AKZ</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            Date of birth
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>235226</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            Gender
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>Activated</span>
        </Col>
      </Row>
  
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            ID type
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>Activated</span>
        </Col>
      </Row>
  
       */}

      {/* <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            ID number
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>Activated</span>
        </Col>
      </Row> */}

      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>{t("NIF")}</span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{data.businessInfo.nif}</span>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Billing Method")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{data.documents.bankName}</span>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>{t("IBAN")}</span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{data.bankDetails.iban}</span>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Address")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{data.businessAddress.address}</span>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Province")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{data.businessAddress.province}</span>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>{t("Status")}</span>
        </Col>
        <Col md={4}>
          {/* <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {data.isApproved}
          </span> */}
          <Select
            styles={customStyles}
            options={[
              { value: "ACTIVE", label: "Active" },
              { value: "CLOSED", label: "Closed" },
              { value: "SUSPENDED", label: "Suspended" },
            ]}
            onChange={({ value }) => setStatus(value)}
          />
        </Col>
        <Col md={2} className={{ padding: "0px" }}>
          {status !== "" && (
            <button
              type="button"
              style={{
                backgroundColor: "#049EFF",
                color: "#fff",
                border: "none",
                fontSize: "12px",
                padding: "8px",
                borderRadius: "4px",
                fontWeight: "bold",
              }}
              onClick={updateStatus}
            >
              {updateLoading ? (
                <Spinner animation="border" size="sm" style={{ color: "white", padding: "9px" }} />
              ) : (
                t("Update")
              )}
            </button>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Document")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {data.documents.documentImage?.split(".").pop() === "pdf" ? (
              <Button
                onClick={() => saveAs(data.documents.documentImage, "document.pdf")}
                style={{
                  width: "50%",
                  margin: "5px",
                }}
              >
                {t("Download")}
              </Button>
            ) : (
              <img
                src={data.documents.documentImage}
                style={{ borderRadius: "8px", width: "200px" }}
                alt=""
              />
            )}
          </span>
        </Col>
      </Row>
    </div>
  );
}
