import React, { useState } from "react";
import { baseUrl } from "../../../config";
import axios from "axios";
import { Row, Col, Table, Form, Spinner } from "react-bootstrap";
import noDataFound from "../../Assets/images/nodata.png";
import Pages from "../../Components/Pagination/Pagination";
import { useDebounce } from "use-debounce";
import { blue } from "../../Globals/colors";
import Button from "react-bootstrap/Button";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import { fetchCommission } from "../../../api/Commisson/api";
import CreateCommissionModal from "./CreateCommissionModal";
import DeleteCommissionModal from "./DeleteCommissionModal";
import UpdateCommissionModal from "./UpdateCommissionModal";

const Commission = () => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [noOfPages, setNoOfPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [createModal, setCreateModal] = useState(false);
  const [nameSearch, setNameSearch] = useState("");
  const [nameDebounce] = useDebounce(nameSearch, 1000);
  const [typeSearch, setTypeSearch] = useState("");
  const [typeDebounce] = useDebounce(typeSearch, 1000);

  const createModalToggle = (isRefresh = false) => {
    setCreateModal(!createModal);
    if (isRefresh) {
      getCommissionData();
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const deleteModalToggle = (isRefresh = false) => {
    setDeleteModal(!deleteModal);
    if (isRefresh) {
      getCommissionData();
    }
  };

  const [updateModal, setUpdateModal] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const updateModalToggle = (isRefresh = false) => {
    setUpdateModal(!updateModal);
    if (isRefresh) {
      getCommissionData();
    }
  };

  React.useEffect(() => {
    getCommissionData();
  }, [limit, activePage, nameDebounce, typeDebounce]);

  const getCommissionData = () => {
    setLoading(true);
    // axios
    //   .get(
    //     `${baseUrl}/admin/commission?limit=${limit}&page=${activePage}&type=${typeDebounce}&name=${nameDebounce}`
    //   )
    fetchCommission(limit, activePage, typeDebounce, nameDebounce)
      .then((res) => {
        setLoading(false);
        setTableData(res.data.data);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            xl={3}
            lg={3}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Commission")}
              </span>
            </div>
          </Col>

          <Col
            xl={3}
            lg={3}
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              visibility: "hidden",
            }}
          >
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Order Status")}
            </span>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  marginTop: "4px",
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {t("Show All")}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem>{t("Show All")}</DropdownItem>
                <DropdownItem>{t("Completed")}</DropdownItem>
                <DropdownItem>{t("Pending")}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>

          <Col xl={2} lg={2}>
            {/* <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              Type Search
            </span> */}
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search Type"
                aria-label="Search"
                aria-describedby="search-addon"
                onChange={(e) => setTypeSearch(e.target.value)}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            {/* <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              Name Search
            </span> */}
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search Name"
                aria-label="Search"
                aria-describedby="search-addon"
                onChange={(e) => setNameSearch(e.target.value)}
              />
            </div>
          </Col>
          {/* <Col xl={2} lg={2}>
            <div className="d-grid">
              <Button
                onClick={() => {
                  createModalToggle();
                }}
                type="button"
                style={{
                  backgroundColor: `${blue}`,
                  color: "#fff",
                  border: "none",
                }}
              >
                {t("Create")}
              </Button>
            </div>
          </Col> */}
        </Row>
      </div>

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        // tableData.length > 0 ?
        <div>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Date & Time")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>{t("ID")}</th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Service Type")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Service Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Commission")}
                </th>
              </tr>
            </thead>
            {tableData.length > 0 ? (
              <tbody>
                {tableData.map((item, index) => (
                  <tr
                    onClick={() => {
                      setUpdateItem(item);
                      updateModalToggle(false);
                    }}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>
                      {" "}
                      {new Date(item.createdAt).toLocaleDateString()}
                      {" - "}
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </td>
                    <td style={{ padding: "20px" }}>{item._id}</td>
                    <td
                      style={{ padding: "20px" }}
                    >{`${item.commission_type}`}</td>
                    <td style={{ padding: "20px" }}>
                      {item.commission_name ? item.commission_name : ""}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.commission_rate
                        ? item.type === "fixed"
                          ? `${new Intl.NumberFormat().format(
                              item.commission_rate
                            )} AKZ`
                          : `${item.commission_rate} %`
                        : ""}
                    </td>
                    <td style={{ padding: "20px" }}>
                      <span
                        style={{ color: "red", textDecoration: "underline" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteItemId(item._id);
                          deleteModalToggle();
                        }}
                      >
                        {t("Delete")}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <div className="noDataContainer">
                      <img src={noDataFound} width="200px" />
                      <p>{t("No data available")}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
          {tableData.length > 0 && (
            <div style={{ height: "100px" }}>
              <Row style={{ display: "block", paddingBottom: "0px" }}>
                <div>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Pages
                        noOfPages={noOfPages}
                        activePage={activePage}
                        setActivePage={setActivePage}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle
                          style={{
                            backgroundColor: "#fff",
                            color: "#333",
                            borderColor: "#CED4DA",
                            fontSize: "0.9rem",
                            width: "100%",
                            margin: "auto",
                          }}
                          caret
                        >
                          <span
                            style={{
                              color: "#333",
                              fontSize: "0.7rem",
                            }}
                          >
                            {t("Items per page")}
                          </span>
                          <span
                            style={{
                              marginLeft: "5px",
                              fontWeight: "bold",
                              marginRight: "5px",
                            }}
                          >
                            {limit}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu style={{ marginTop: "5px" }}>
                          <DropdownItem
                            onClick={() => {
                              setLimit(1);
                              setActivePage(1);
                            }}
                          >
                            1
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(5);
                              setActivePage(1);
                            }}
                          >
                            5
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(10);
                              setActivePage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}
        </div>
      )}
      <Modal centered size="md" isOpen={createModal} toggle={createModalToggle}>
        <ModalHeader toggle={createModal}>{t("Add Commission")}</ModalHeader>
        <ModalBody>
          <CreateCommissionModal toggle={createModalToggle} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
      <Modal centered size="sm" isOpen={deleteModal} toggle={deleteModalToggle}>
        <ModalHeader toggle={deleteModal}>{t("Confirm Delete")}</ModalHeader>
        <ModalBody>
          <DeleteCommissionModal id={deleteItemId} toggle={deleteModalToggle} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
      <Modal centered size="sm" isOpen={updateModal} toggle={updateModalToggle}>
        <ModalHeader toggle={updateModal}>
          {t("Commission Details")}
        </ModalHeader>
        <ModalBody>
          <UpdateCommissionModal item={updateItem} toggle={updateModalToggle} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default Commission;
