import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ModalLayout({ data, tab }) {
    const { t, i18n } = useTranslation();
    return (
      <div>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Date & Time")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {new Date(data.createAt).toLocaleDateString()}
              {" - "}
              {new Date(data.createAt).toLocaleTimeString()}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Transaction Id")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.transactionId}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {tab === "send" ? t("Sender") : t("Requester")} {t("Name")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.senderData.firstName} {data.senderData.surName}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {tab === "send" ? t("Sender") : t("Requester")} {t("Wallet Number")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.senderData.phoneNumber}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Receiver Name")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.receiverData.firstName} {data.receiverData.surName}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Receiver Wallet Number")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.receiverData.phoneNumber}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Amount")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.amount.toLocaleString()} AKZ
            </span>
          </Col>
        </Row>
  
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Status")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.status?.charAt(0).toUpperCase() +
                data.status?.slice(1).toLowerCase()}
            </span>
          </Col>
        </Row>
      </div>
    );
}

