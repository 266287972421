import React from "react";
import { Pagination } from "react-bootstrap";

const Pages = ({ noOfPages, activePage, setActivePage }) => {
  let paginationFlag = false;

  return (
    <Pagination>
      {noOfPages.map((item, index) => {
        if (noOfPages.length <= 5) {
          return (
            <Pagination.Item
              onClick={() => setActivePage(item)}
              active={activePage === item}
              activeLabel={""}
            >
              {item}
            </Pagination.Item>
          );
        }

        if (activePage !== 1 && index === 0) {
          return (
            <Pagination.Item
              onClick={() => setActivePage(item)}
              active={activePage === item}
              activeLabel={""}
            >
              {item}
            </Pagination.Item>
          );
        }

        if (
          activePage - 1 === index + 1 ||
          activePage === index + 1 ||
          activePage + 1 === index + 1
        ) {
          paginationFlag = false;
          return (
            <Pagination.Item
              onClick={() => setActivePage(item)}
              active={activePage === item}
              activeLabel={""}
            >
              {item}
            </Pagination.Item>
          );
        }

        if (
          activePage !== noOfPages.slice(-1)[0] &&
          index + 1 === noOfPages.slice(-1)[0]
        ) {
          return (
            <Pagination.Item
              onClick={() => setActivePage(item)}
              active={activePage === item}
              activeLabel={""}
            >
              {noOfPages.slice(-1)[0]}
            </Pagination.Item>
          );
        }

        if (!paginationFlag) {
          paginationFlag = true;
          return <Pagination.Item>...</Pagination.Item>;
        }
      })}
    </Pagination>
  );
};

export default Pages;
