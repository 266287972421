import React, { useState, useEffect } from "react";
import { Row, Col, Table, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import noDataFound from "../../Assets/images/nodata.png";
import { baseUrl } from "../../../config";
import Pages from "../../Components/Pagination/Pagination";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import {
  getSellerTransferHistory,
} from "../../../api/BankTransferSeller/api";
import ModalLayout from "./ModalLayout";


const BankTransferSeller = () => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(false);
  const [currItem, setCurrItem] = useState(null);
  const today = `${
    new Date().getMonth() + 1
  }-${new Date().getDate()}-${new Date().getFullYear()}`;

  // show all/pending/approved
  const [userType, setUserType] = useState("");

  // api filters
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(today);
  const [status, setStatus] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [searchDebounce] = useDebounce(nameSearch, 1000);
  // no. of pages for Pagination (no of items in array = no of pages)
  const [noOfPages, setNoOfPages] = useState([]);

  // this effect only geting/updating total no of pages to show for pagination
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${baseUrl}/sellers/withdraw/payment?limit=&page=&startDate=${startDate}&endDate=${endDate}&name=${searchDebounce}&status=${status}`
  //     )
  //     .then((res) => {
  //       let num = res.data.data.length / 10;
  //       if (num < 1) setNoOfPages([1]);
  //       else {
  //         num = Math.ceil(num);
  //         for (let i = 0; i < num; i++) {
  //           setNoOfPages((oldArray) => [...oldArray, i + 1]);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       
  //     });
  // }, [activePage, startDate, endDate, searchDebounce, status]);

  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/withdrawals/1/sellers/withdraw/payment/exportCSV?startDate=${startDate}&endDate=${endDate}&name=${searchDebounce}&status=${status}`,
        ""
      );
    } catch (e) {
    }
  };

  // this effect is fetching data
  useEffect(() => {
    getUpdatedData();
  }, [limit, activePage, startDate, endDate, searchDebounce, status]);

  // it will be called after adding an entry successfully to get updated data
  const getUpdatedData = () => {
    setLoading(true);
    setNoOfPages([]);
    // axios
    //   .get(
    //     `${baseUrl}/sellers/withdraw/payment?limit=10&page=1&startDate=${today}&endDate=${today}`
    //   )
    getSellerTransferHistory(limit,
      activePage,
      startDate,
      endDate,
      searchDebounce,
      status)
      .then((res) => {
        setTableData(res.data.data);
        setLoading(false);
        let num = res.data.data.length / 10;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          for (let i = 0; i < num; i++) {
            setNoOfPages((oldArray) => [...oldArray, i + 1]);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader">
        <Row style={{ width: "100%" }}>
          <Col
            xl={2}
            lg={2}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: "1.5rem",
                  marginLeft: "1rem",
                }}
              >
                {t("Bank Transfer Seller")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              {t("show all")}
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "30%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              {t("show all")}
            </span>
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={nameSearch}
                onChange={(e) => setNameSearch(e.target.value)}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Status
            </span>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span className="showAllSpan">
                  {status === ""
                    ? t("Show All")
                    : status?.charAt(0).toUpperCase() +
                    status?.slice(1).toLowerCase()}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem
                  onClick={() => {
                    setActivePage(1);
                    setStatus("");
                  }}
                >
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    setActivePage(1);
                    setStatus("PENDING");
                  }}
                >
                  {t("Pending")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    setActivePage(1);
                    setStatus("APPROVED");
                  }}
                >
                  {t("Approved")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setStatus("REJECTED")}>
                  {t("Rejected")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>
            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => {
                  setActivePage(1);
                  setLimit(10);
                  if (e.target.value === "") setStartDate("");
                  else setStartDate(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>
            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => {
                  setActivePage(1);
                  setLimit(10);
                  if (e.target.value === "") setEndDate(today);
                  else setEndDate(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>

      {/* if no data found */}
      {/* {tableData.length <= 0 && (
        <div style={{ textAlign: "center" }}>
          <img src={noDataFound} width="200px" />
          <p>No data available</p>
        </div>
      )} */}

      {/* Table Content */}
      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : tableData.length > 0 ? (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Date")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Transaction Id")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Seller/Company Name")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Vendor Number")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Bank Name")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("IBAN")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Amount")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Transfer Type")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Despesas STC")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("TAX")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Status")}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.slice(0, limit).map((item, index) => (
              <tr
                onClick={() => {
                  setCurrItem(item);
                  toggle();
                }}
                style={{
                  backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                  fontSize: "12px",
                  color: "#4f4f4f",
                  fontFamily: "sans-serif",
                }}
              >
                <td style={{ padding: "20px" }}>
                  {new Date(item.createdAt).toDateString()}
                </td>
                <td style={{ padding: "20px" }}>{item.transactionId}</td>
                <td style={{ padding: "20px" }}>
                  {item.withdrawDetail?.businessInfo.storeName
                    ? item.withdrawDetail?.businessInfo.storeName
                    : "NaN"}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.withdrawDetail?.businessInfo.phoneNumber
                    ? item.withdrawDetail?.businessInfo.phoneNumber
                    : "NaN"}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.bankName ? item.bankName : "NaN"}
                </td>
                <td style={{ padding: "20px" }}>{item.ibanNumber}</td>
                <td style={{ padding: "20px" }}>
                  {new Intl.NumberFormat().format(item.amountToTransfer)} AKZ
                </td>
                <td style={{ padding: "20px" }}>
                  {item.bankName === "Banco BNI" ? "Intrabank" : "Interbank"}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.commission
                    ? `${new Intl.NumberFormat().format(item.commission)} AKZ`
                    : "..."}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.iva
                    ? `${new Intl.NumberFormat().format(item.iva)} AKZ`
                    : "NaN"}
                </td>
                <td style={{ padding: "20px" }}>
                  {/* {item.paymentStatus.toLowerCase() === "pending"
                    ? "Pending"
                    : item.paymentStatus.toLowerCase() === "approved"
                    ? "Approved"
                    : item.paymentStatus} */}
                    {item?.paymentStatus === "APPROVED"
                    ? "Approved"
                    : item?.paymentStatus === "PENDING"
                    ? "Pending"
                    : item?.paymentStatus === "REJECTED"
                    ? "Rejected"
                    : null
                    }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div style={{ textAlign: "center" }}>
          <img src={noDataFound} width="200px" />
          <p>{t("No data available")}</p>
        </div>
      )}

      {tableData.length > 0 && (
        <div style={{ height: "100px" }}>
          <Row style={{ display: "block", paddingBottom: "0px" }}>
            <div>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* Pagination */}
                  <Pages
                    noOfPages={noOfPages}
                    activePage={activePage}
                    setActivePage={setActivePage}
                  />
                </div>
                {/* No of products on page toggler */}
                <div
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <UncontrolledDropdown style={{ width: "100%" }}>
                    <DropdownToggle
                      style={{
                        backgroundColor: "#fff",
                        color: "#333",
                        borderColor: "#CED4DA",
                        fontSize: "0.9rem",
                        width: "100%",
                      }}
                      caret
                    >
                      <span
                        style={{
                          color: "#333",
                          fontSize: "0.7rem",
                        }}
                      >
                        {t("Items per page")}
                      </span>
                      <span
                        style={{
                          marginLeft: "5px",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {limit}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu style={{ marginTop: "5px" }}>
                      <DropdownItem onClick={() => setLimit(1)}>1</DropdownItem>
                      <DropdownItem onClick={() => setLimit(5)}>5</DropdownItem>
                      <DropdownItem onClick={() => setLimit(10)}>
                        10
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Col>
            </div>
          </Row>
        </div>
      )}
      {/* Transactions Details Modal */}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Transaction Details</ModalHeader>
        <ModalBody>
          <ModalLayout
            toggle={toggle}
            data={currItem}
            getUpdatedData={getUpdatedData}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default BankTransferSeller;
