import instance from "./axios";

export const fetchCategories = async (id, searchDomain) => {
  try {
    return instance.get(`/categories?parent=${id}&domain=${searchDomain}`);
  } catch (e) {
    return e;
  }
};

export const createCategories = async (body) => {
  try {
    return instance.post(`/categories`, body);
  } catch (e) {
    return e;
  }
};

export const changeCategoryStatus = async (id, status) => {
  try {
    return instance.post(`/categories/${id}`, {
      isApproved: status,
    });
  } catch (e) {
    return e;
  }
};

export const updateCategories = async (id, body) => {
  try {
    return instance.put(`/categories/${id}`, body);
  } catch (e) {
    return e;
  }
};

export const getApprovedCategories = async (activePage, qty, search) => {
  try {
    return instance.get(
      `/categories?approved=approved&page=${activePage}&limit=${qty}&name=${search}`
    );
  } catch (e) {
    return e;
  }
};

export const getPendingCategoriesApi = async (activePage, qty, search) => {
  try {
    return instance.get(
      `/categories?approved=pending&page=${activePage}&limit=${qty}&name=${search}`
    );
  } catch (e) {
    return e;
  }
};
