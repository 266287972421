import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import { MdDelete, MdEdit } from "react-icons/md";
import "./AddAttribute.scss";

const AddAttribute = ({ setAttrData, attrData }) => {
  const [check, setCheck] = useState(false);
  const [inputType, setInputType] = useState("");
  const [inputOption, setInputOption] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [inpVal, setInpVal] = useState("");

  const categoriesOptions = [
    { value: "dropdown", label: "Dropdown" },
    { value: "input", label: "Input" },
  ];
  const inputOptions = [
    { value: "string", label: "String" },
    { value: "number", label: "Number" },
  ];
  const [name, setName] = useState("");
  const [fieldsEditable, setFieldsEditable] = useState(false);

  const customSelect = {
    control: (base) => ({
      ...base,
    }),
  };

  const addOption = (e) => {
    e.preventDefault();
    if (inpVal.length > 0) {
      setInpVal("");
      setDropdownOptions((prevOptions) => [...prevOptions, inpVal]);
    }
  };
  const removeOption = (item) => {
    const result = dropdownOptions.filter((val) => val !== item);
    setDropdownOptions(result);
  };

  const addAttr = () => {
    setAttrData([...attrData, { title: name, inputType: inputType.value }]);

    // reset all the fields
    setName("");
    setCheck(false);
    setInputType("");
  };
  return (
    <div className="p-3 addAttrContainer">
      <label
        style={{
          display: "block",
          width: "100%",
          fontSize: "12px",
          fontWeight: "bold",
          marginTop: "5px",
        }}
      >
        Name
      </label>
      <Row>
        <Col>
          <input
            disabled={fieldsEditable}
            className="my-1 w-100 nameInp"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Col>
        <Col>
          <Select
            isSearchable={false}
            value={inputType}
            onChange={(item) => {
              // setInputOption(null);
              setInputType(item);
            }}
            options={categoriesOptions}
            placeholder="Type"
            styles={customSelect}
          />
        </Col>
      </Row>
      {/* <Col>
        <input
          disabled={fieldsEditable}
          type="radio"
          checked={check}
          onClick={() => setCheck(!check)}
        />
        <label className="noSelect ms-1">Optional</label>
      </Col> */}
      {/* input type */}
      {/* <Row className="d-flex align-items-start">
         <Col className="">
          {!!inputType ? (
            inputType === "input" ? (
              <Select
                isSearchable={false}
                onChange={(item) => setInputOption(item.value)}
                options={inputOptions}
                placeholder="Type"
                styles={customSelect}
              />
            ) : (
              <div>
                <form
                  style={{ marginTop: "4px" }}
                  onSubmit={addOption}
                  className="d-flex flex-row bg-white flex-nowrap"
                >
                  <input
                    value={inpVal}
                    onChange={(e) => setInpVal(e.target.value)}
                    placeholder="Add option..."
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      fontSize: "14px",
                      padding: "5px",
                    }}
                  />
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "#049EFF",
                      color: "#fff",
                      fontSize: "14px",
                      borderRadius: "6px",
                    }}
                    onClick={addOption}
                  >
                    Add
                  </button>
                </form>
                <div
                  style={{
                    maxHeight: "100px",
                    overflowY: "scroll",
                  }}
                >
                  {dropdownOptions
                    .slice(0)
                    .reverse()
                    .map((item) => (
                      <div className="d-flex justify-content-between border mt-1 bg-white p-1">
                        <span
                          style={{
                            color: "#000",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        >
                          {item}
                        </span>
                        <div>
                          <MdDelete
                            className="me-2"
                            onClick={() => removeOption(item)}
                            color={"red"}
                            style={{ cursor: "pointer" }}
                            size={20}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )
          ) : (
            <input
              style={{ marginTop: "2px" }}
              disabled
              className="disabledInp"
            />
          )}
        </Col> 
      </Row>
       {inputType === "input" && (
        <Row className="mt-1">
          {inputOption === "number" ? (
            <>
              <Col className="text-center">
                <input
                  placeholder="Max"
                  type="number"
                  className="numberInp"
                  min={"1"}
                />
              </Col>
              <Col className="text-center">
                <input
                  placeholder="Min"
                  type="number"
                  className="numberInp"
                  min={"1"}
                />
              </Col>
            </>
          ) : null}
        </Row>
      )} */}
      <div className="d-flex justify-content-end mt-2 w-100">
        {/* <button disabled={name === "" ? true : false} onClick={addAttr}> */}
        <button
          disabled={name === "" || inputType === "" ? true : false}
          onClick={addAttr}
        >
          create
        </button>
      </div>
    </div>
  );
};

export default AddAttribute;
