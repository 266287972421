import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import { AiFillDelete } from "react-icons/ai";
import Select from "react-select";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

// import { createPostOffice } from "../../../common/api";
import { useTranslation } from "react-i18next";
import { createPostOffice } from "../../../api/BayqiLogistics/api";

const AddPostOfficeModal = ({ toggle }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [country, setCountry] = useState("");
  // const [province, setProvince] = useState("");
  const [cost, setCost] = useState("");
  const [est, setEst] = useState("");
  // const [address, setAddress] = useState("");
  const [extras, setExtras] = useState([]);
  const [extraItems, setExtraItems] = useState();
  const [extraCost, setExtraCost] = useState();

  const addPostOffice = () => {
    setError("");
    setLoading(true);

    if (
      title === "" ||
      cost === "" ||
      est === "" ||
      country === ""
      // address === "" ||
      // province === ""
    ) {
      setError("(*) fields are mandatory.");
      setLoading(false);
    } else {
      createPostOffice({
        title,
        // address,
        country,
        // province,
        cost,
        extras,
        deliveryTime: est,
        status: "Active",
      })
        .then((res) => {
          setLoading(false);
          toggle(true);
        })
        .catch((err) => {
          setLoading(false);
          setError("*Something went wrong.");
        });
    }
  };
  return (
    <div style={{ padding: "0px 30px" }}>
      <Row className="d-flex justify-content-between">
        <Col className="col-12">
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Title")}
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
            className="inputField"
            id="Title"
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col className="col-12">
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Country")}
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <CountryDropdown
            defaultOptionLabel="Select a country"
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            value={country}
            onChange={(val) => {
              setCountry(val);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="sm-col-12 md-col-6">
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Cost")}
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
              marginBottom: "8px",
            }}
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            placeholder="0.00 AKZ"
            className="inputField"
            id="Cost"
            type="number"
          />
        </Col>
      </Row>
      {extras.length > 0 &&
        extras.map((item) => (
          <div
            style={{
              backgroundColor: "lightgray",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <p
              style={{
                display: "inline",
                marginBottom: "0",
                marginLeft: "10px",
              }}
            >
              {t("Items")}: {item.extraItems}, {t("Cost")}: {item.extraCost}
            </p>
            <AiFillDelete
              onClick={() => {
                let filteredExtras = extras.filter((curr) => curr !== item);
                setExtras(filteredExtras);
              }}
              style={{
                color: "red",
                cursor: "pointer",
                marginRight: "6px",
              }}
              size={"1.2rem"}
            />
          </div>
        ))}
      <Row
        className="d-flex justify-content-between pb-3"
        style={{ backgroundColor: "#f0f0f0" }}
      >
        <Col>
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Items")}
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            value={1}
            onChange={(e) => setExtraItems(e.target.value)}
            placeholder="Enter Items"
            className="inputField"
            type="number"
            readOnly
          />
        </Col>
        <Col>
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Cost")}
          </label>
          <input
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            value={extraCost}
            onChange={(e) => setExtraCost(e.target.value)}
            placeholder="0.00"
            className="inputField"
            type="number"
          />
        </Col>
        <Col className="col-2 d-flex justify-content-end mt-4">
          <button
            disabled={!extraCost || extras.length > 0}
            onClick={() => {
              setExtras((prevData) => [...prevData, { extraItems: 1, extraCost }]);
              setExtraCost("");
            }}
          >
            {t("Add")}
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            className="inpLblTxt"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Delivery time")} (EST)
            <p style={{ color: "red", display: "inline" }}>*</p>
          </label>
          <Select
            options={[
              { value: "0", label: "Same day" },
              { value: "1", label: "1 day" },
              { value: "2", label: "2 days" },
              { value: "3", label: "3 days" },
              { value: "4", label: "4 days" },
              { value: "5", label: "5 days" },
              { value: "6", label: "6 days" },
              { value: "7", label: "7 days" },
              { value: "8", label: "8 days" },
              { value: "9", label: "9 days" },
              { value: "10", label: "10 days" },
              { value: "11", label: "11 days" },
              { value: "12", label: "12 days" },
              { value: "13", label: "13 days" },
              { value: "14", label: "14 days" },
              { value: "15", label: "15 days" },
            ]}
            onChange={({ value }) => setEst(value)}
          />
        </Col>
      </Row>

      {error && (
        <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
          {error}
        </p>
      )}

      <Button
        onClick={addPostOffice}
        disabled={loading}
        style={{
          margin: "20px 0px",
          backgroundColor: "#049EFF",
          borderWidth: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <Spinner
            animation="border"
            style={{ color: "white", padding: "9px" }}
            size="sm"
          />
        ) : (
          t("Save")
        )}
      </Button>
    </div>
  );
};

export default AddPostOfficeModal;
