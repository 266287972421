import React, { useState } from "react";
import "./AccountSettings.css";
import { Row, Col } from "react-bootstrap";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

import {
  Button,
} from "reactstrap";
import { useTranslation } from 'react-i18next';

export default function AccountSettings() {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "0px 25px",
        backgroundColor: "#f7f7f7",
        marginTop: "10px",
      }}
    >
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          marginTop: "20px",
          padding: "12px 5px",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
            xl={12}
            lg={12}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("My Account")}
              </span>
            </div>
          </Col>
        </Row>
      </div>

      <div
        style={{
          marginTop: "2%",
          alignSelf: "center",
        }}
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
              style={{ cursor: 'pointer' }}
            >
              {t("Personal Data")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
              style={{ cursor: 'pointer' }}
            >
              {t("Update Password")}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane style={{}} tabId="1">
            <div
              style={{
                marginTop: "2%",
                background: "#fff",
                width: "100%",
                padding: "2%",
                boxShadow: "2px 4px 50px 0px rgba(0,0,0,0.1)",
                borderRadius: "5px",
              }}
            >
              <Row style={{ padding: "0.3% 0px" }}>
                <Col xl={3} lg={6} md={6} sm={6} xs={6}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      fontSize: "1rem",
                    }}
                  >
                    {t("Mobile phone")}
                  </span>
                </Col>
                <Col xl={9} lg={6} md={6} sm={6} xs={6}>
                  <span style={{ color: "#333", fontSize: "0.8rem", width: '150px', display: 'inline-block' }}>939725027</span><button className="editBtn">edit</button>

                </Col>
              </Row>
              <Row style={{ padding: "0.3% 0px" }}>
                <Col xl={3} lg={6} md={6} sm={6} xs={6}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      fontSize: "1rem",
                    }}
                  >
                    Name
                  </span>
                </Col>
                <Col xl={9} lg={6} md={6} sm={6} xs={6}>
                  <span style={{ color: "#333", fontSize: "0.8rem", width: '150px', display: 'inline-block' }}>BayQi</span><button className="editBtn">edit</button>
                </Col>
              </Row>
              <Row style={{ padding: "0.3% 0px" }}>
                <Col xl={3} lg={6} md={6} sm={6} xs={6}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      fontSize: "1rem",
                    }}
                  >
                    Email
                  </span>
                </Col>
                <Col xl={9} lg={6} md={6} sm={6} xs={6}>
                  <span style={{ color: "#333", fontSize: "0.8rem", width: '150px', display: 'inline-block' }}>apoiocliente@bayqi.com</span><button className="editBtn">edit</button>

                </Col>
              </Row>

              <Row style={{ padding: "0.3% 0px" }}>
                <Col xl={3} lg={6} md={6} sm={6} xs={6}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      fontSize: "1rem",
                    }}
                  >
                    Zip Code
                  </span>
                </Col>
                <Col xl={9} lg={6} md={6} sm={6} xs={6}>
                  <span style={{ color: "#333", fontSize: "0.8rem", width: '150px', display: 'inline-block' }}>99399</span><button className="editBtn">edit</button>

                </Col>
              </Row>


              <Row style={{ padding: "0.3% 0px" }}>
                <Col xl={3} lg={6} md={6} sm={6} xs={6}>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      fontSize: "1rem",
                    }}
                  >
                    Address
                  </span>
                </Col>
                <Col xl={9} lg={6} md={6} sm={6} xs={6}>
                  <span style={{ color: "#333", fontSize: "0.8rem", width: '150px', display: 'inline-block' }}>88467 Bechtelar Cres</span><button className="editBtn">edit</button>
                </Col>
              </Row>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div
              style={{
                marginTop: "2%",
                background: "#fff",
                width: "100%",
                padding: "2%",
                boxShadow: "2px 4px 50px 0px rgba(0,0,0,0.1)",
                borderRadius: "5px",
              }}
            >
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Col md={12}>
                  <label
                    htmlFor="Amount"
                    style={{
                      display: "block",
                      width: "100%",
                      fontWeight: "600",
                    }}
                  >
                    Current password
                  </label>
                  <input
                    style={{
                      marginTop: "1%",
                      width: "25%",
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                      height: "33px",
                    }}
                    id="Amount"
                  />
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "1%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Col md={12}>
                  <label
                    htmlFor="Amount"
                    style={{
                      display: "block",
                      width: "100%",
                      fontWeight: "600",
                    }}
                  >
                    New password
                  </label>
                  <input
                    style={{
                      marginTop: "1%",
                      width: "25%",
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                      height: "33px",
                    }}
                    id="NewPass"
                  />
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "1%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Col md={12}>
                  <label
                    htmlFor="Amount"
                    style={{
                      display: "block",
                      width: "100%",
                      fontWeight: "600",
                    }}
                  >
                    Confirm password
                  </label>
                  <input
                    style={{
                      marginTop: "1%",
                      width: "25%",
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                      height: "33px",
                    }}
                    id="ConfirmPass"
                  />
                </Col>
              </Row>
              <Button style={{ marginTop: '1rem', backgroundColor: '#049eff', border: 'none' }}>Update</Button>
            </div>
          </TabPane>
        </TabContent>
      </div>

      {/* <p className="h6 mt-4">Suggestions</p>
      <div style={{
        display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', paddingBottom: "150px", marginTop: '15px', marginTop: '0px', gap: '15px'
      }}>
        {[0, 1, 2, 3, 4, 5].map(() => <Product image={logo2} />)}
      </div> */}


    </div>
  );
}
