import React from "react";
import Button from "react-bootstrap/Button";
import { Row, Col, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

export default function ModalLayout({
    data,
    updateStatus,
    setStatus,
    status,
    updateLoading,
  }) {
    const { t, i18n } = useTranslation();
    const history = useHistory();
  
    const image_url=`${process.env.REACT_APP_CUSTOMER_S3_BUCKET}/${data.identificationCardImg}`;
    const image_url2=`${process.env.REACT_APP_CUSTOMER_S3_BUCKET}/${data.identificationCardImg1}`;
    return (
      <div>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Registration Date")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {new Date(data.createdAt).toDateString()}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Registration Number")}:
            </span>
          </Col>
          <Col md={6}>
            <span
              style={{ color: "#333", fontSize: "0.8rem" }}
            >{`0${data?.regNumber.toString()}`}</span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Name")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.firstName + " " + data.surName}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Email Address")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.email}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Nationality")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.nationality}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Date of birth")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.dob && new Date(data.dob).toDateString()}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Gender")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.gender}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Identification Type")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.identificationType}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Identification Number")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.identificationNumber}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Wallet Number")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.phoneNumber}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Wallet Balance")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.walletBalance} AKZ
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Bank Name")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.bankName}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              IBAN:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>{data.iban}</span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Address")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.shippingAddress}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Province")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.province}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Status")}
            </span>
          </Col>
          <Col md={4}>
            <Select
              // styles={customStyles}
              options={[
                { value: "true", label: "Activate" },
                { value: "false", label: "Disable" },
              ]}
              onChange={({ value }) => setStatus(value === "true" ? true : false)}
            />
          </Col>
          <Col md={2} className={{ padding: "0px" }}>
            {data.isActive !== status && status !== "" && (
              <button
                type="button"
                style={{
                  backgroundColor: "#049EFF",
                  color: "#fff",
                  border: "none",
                  fontSize: "12px",
                  padding: "8px",
                  borderRadius: "4px",
                  fontWeight: "bold",
                }}
                onClick={updateStatus}
              >
                {updateLoading ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ color: "white", padding: "9px" }}
                  />
                ) : (
                  t("Update")
                )}
              </button>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Document")}:
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              <img
                src={`${image_url}`}
                style={{ borderRadius: "8px", width: "200px" }}
                alt=""
              />
              <img
                src={`${image_url2}`}
                style={{ borderRadius: "8px", width: "200px" }}
                alt=""
              />
            </span>
          </Col>
        </Row>
  
        <Row>
          <Col md={4}>
            <Button
              style={{
                width: "100%",
              }}
              onClick={() => history.push(`/UserHistory/${data._id}`)}
            >
              {t("History")}
            </Button>
          </Col>
        </Row>
      </div>
    );
}
