import React, { useState } from "react";
import { baseUrl } from "../../../config";
import axios from "axios";
import { Row, Col, Table, Form, Spinner } from "react-bootstrap";
import noDataFound from "../../Assets/images/nodata.png";
import Pages from "../../Components/Pagination/Pagination";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserHistory } from "../../../api/UserHistory/api";

const UserHistory = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState("");
  // const [value, setValue] = useState(null);
  // const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { t, i18n } = useTranslation();

  let { id } = useParams();

  const [detailsModalItem, setDetailsModalItem] = useState({});

  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/orders/1/order/export?limit=1000000&page=1&transactionType=QR_CATALOGE_SCAN&paymentMethod=${selectedMethod}&startDate=${startDate}&endDate=${endDate}`,
        ""
      );
    } catch (e) {
    }
  };

  const toggle = (isRefresh = false) => {
    setModal(!modal);
    if (isRefresh === true) {
      LoadOrders();
    }
  };

  React.useEffect(() => {
    LoadOrders();
  }, [limit, activePage, startDate, endDate, selectedMethod]);

  const LoadOrders = async () => {
    setLoading(true);
    // axios
    //   .get(
    //     `${baseUrl}/transactions/history/mbl?limit=${limit}&page=${activePage}&startDate=${startDate}&endDate=${endDate}&customerId=${id}`
    //   )
    getUserHistory(limit, activePage, startDate, endDate, id)
      .then((res) => {
        const { success, message, data, count } = res.data;
        
        // if (success) {
        //   
        //   setTableData(data);
        // }
        setTableData(res.data.result);
        setLoading(false);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((ex) => {
      });
  };

  return (
    <div
    // style={{
    //   width: "100%",
    //   height: "100%",
    //   padding: "0px 25px",
    //   backgroundColor: "#f7f7f7",
    // }}
    >
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            xl={4}
            lg={4}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Transactions")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2} style={{ visibility: "hidden" }}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                  marginTop: "0px",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "25%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col style={{ visibility: "hidden" }} xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Payment Method")}
            </span>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {selectedMethod
                  ? selectedMethod?.charAt(0).toUpperCase() +
                    selectedMethod?.slice(1).toLowerCase()
                  : "Show All"}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setSelectedMethod("")}>
                  Show All
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedMethod("BAYQI")}>
                  Bayqi
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedMethod("EXPRESS")}>
                  Express
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedMethod("PAGAQI")}>
                  Pagaqi
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>

          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </div>

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : tableData.length > 0 ? (
        <div>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Date & Time")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Transaction ID")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Client Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Client Province")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Store Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Transaction Type")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Amount")}
                </th>
                {/* <th style={{ padding: "20px", fontSize: "14px" }}>Payment Method</th> */}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  onClick={() => {
                    setDetailsModalItem(item);
                    toggle();
                  }}
                  style={{
                    backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                    fontSize: "12px",
                    color: "#4f4f4f",
                    fontFamily: "sans-serif",
                  }}
                >
                  <td style={{ padding: "20px" }}>
                    {" "}
                    {new Date(
                      item.createdAt || item.orderStatusDetail.createdAt
                    ).toLocaleDateString()}
                    {" - "}
                    {new Date(
                      item.createdAt || item.orderStatusDetail.createdAt
                    ).toLocaleTimeString()}
                  </td>
                  <td style={{ padding: "20px" }}>
                    {item.transactionId || item._id}
                  </td>
                  <td
                    style={{ padding: "20px" }}
                  >
                    {
                      item.transactionType === "FRIEND_WALLET"
                        ? `${item.senderDetail.firstName} ${item.senderDetail.surName}`
                        : item.transactionType === "PAY" || item.transactionType === "TRANSFER_WALLET" || item.transactionType === "UPLOAD_WALLET"
                        ? `${item.customerDetail.firstName} ${item.customerDetail.surName}`
                        : `${item.customerName}`
                    }
                                        
                    </td>
                  <td
                    style={{ padding: "20px" }}
                  >
                    {
                      item.transactionType === "FRIEND_WALLET"
                        ? `${item.senderDetail.province}`
                        : item.transactionType === "PAY" || item.transactionType === "TRANSFER_WALLET" || item.transactionType === "UPLOAD_WALLET"
                        ? `${item.customerDetail.province}`
                        : `${item.customerProvince}`
                    }

                    </td>
                  <td
                    style={{ padding: "20px" }}
                  >{
                    item?.sellerDetail?.businessInfo?.storeName
                      ? `${item.sellerDetail.businessInfo.storeName}`
                      : '...'
                  }</td>
                  <td
                    style={{ padding: "20px" }}
                  >{`${item?.transactionType?.replace(/_/g, " ")}`}</td>
                  <td style={{ padding: "20px" }}>
                    {new Intl.NumberFormat().format(item.amount)} AKZ
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div style={{ height: "100px" }}>
            <Row style={{ display: "block", paddingBottom: "0px" }}>
              <div>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Pages
                      noOfPages={noOfPages}
                      activePage={activePage}
                      setActivePage={setActivePage}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <UncontrolledDropdown style={{ width: "100%" }}>
                      <DropdownToggle
                        style={{
                          backgroundColor: "#fff",
                          color: "#333",
                          borderColor: "#CED4DA",
                          fontSize: "0.9rem",
                          width: "100%",
                          margin: "auto",
                        }}
                        caret
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSize: "0.7rem",
                          }}
                        >
                          {t("Items per page")}
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {limit}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu style={{ marginTop: "5px" }}>
                        <DropdownItem onClick={() => setLimit(1)}>
                          1
                        </DropdownItem>
                        <DropdownItem onClick={() => setLimit(5)}>
                          5
                        </DropdownItem>
                        <DropdownItem onClick={() => setLimit(10)}>
                          10
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </div>
      ) : (
        <div className="noDataContainer">
          <img src={noDataFound} width="200px" />
          <p>{t("No data available")}</p>
        </div>
      )}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          {/* <QrCatalogDetails item={detailsModalItem} toggle={toggle} /> */}
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default UserHistory;
