import React, { useState } from "react";
import {
  ButtonToggle,
} from "reactstrap";
import {
  updateAdminDelivery,
} from "../../../api/BayqiLogistics/api";
import { useTranslation } from "react-i18next";

export default function DeleteDeliveryModal({ id, toggle }) {
    const { t, i18n } = useTranslation();
    const removeDelivery = () => {
      updateAdminDelivery(id, {
        isDelete: true,
      })
        .then((res) => {
          toggle(true);
        })
        .catch((err) => {
        });
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonToggle
          onClick={removeDelivery}
          style={{
            margin: "20px 0px",
            backgroundColor: "#E65B65",
            borderWidth: 0,
            marginRight: "10px",
          }}
        >
          {t("Yes")}
        </ButtonToggle>
  
        <ButtonToggle
          onClick={() => toggle()}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
          }}
        >
          {t("No")}
        </ButtonToggle>
      </div>
    );
}

