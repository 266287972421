import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";
import React, { useState } from "react";
import Logo from "../../Assets/images/bayqi_logo.png";
import "./NavBar.css";
import { ImSwitch } from "react-icons/im";
import { IoMdPerson } from "react-icons/io";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAppContext } from "../../lib/contextLib";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NavBar({ toggleDrawer, ...props }) {
  const { t, i18n } = useTranslation();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  let history = useHistory();
  // context
  const { isAuthenticated, userHasAuthenticated } = useAppContext();

  const signOut = async () => {
    try {
      await Auth.signOut();
      userHasAuthenticated(false);
      history.push("/login");
    } catch (error) {
    }
  };

  return (
    <>
      <Navbar className="sticky-top shadow navbar navbar-expand-lg navbar-light bg-light  pb-0 pt-0">
        <div className="container-fluid">
          {/* navbar brand */}
          {/* this icon will be shown only on mobile screens to toggle the sidebar */}
          {/* <ImSwitch
            className="d-inline d-sm-none"
            size={"1.1em"}
          /> */}
          <a className="navbar-brand ps-4" href="#">
            <img src={Logo} alt="" width="80" height="60" />
            {/* <a
              href="https://bayqi-customer-app.web.app/"
              style={{ marginRight: '0.6rem', marginLeft: '0.6rem' }}
            >
            </a> */}
          </a>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto"></ul>
            <Form className="d-none d-sm-flex">
              <FormControl
                type="search"
                placeholder="Search"
                className="mr-2"
                aria-label="Search"
              />
            </Form>

            <Nav.Item>
              <Nav.Link className="navLink">
                <IoMdPerson
                  onClick={() => history.push("/AccountSettings")}
                  color={"grey"}
                  size={"1.2rem"}
                />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link className="navLink" eventKey={2} href="#memes">
                <ImSwitch
                  onClick={toggle}
                  className={"switchClass"}
                  size={"1.1rem"}
                />
              </Nav.Link>
            </Nav.Item>
          </div>
        </div>
      </Navbar>

      <Modal centered isOpen={modal} toggle={toggle} className="modal-open">
        <ModalHeader toggle={toggle}>{t("Logout")}</ModalHeader>
        <ModalBody>
          <span style={{ color: "#333" }}>
            {t("Are you sure you want to log out of the site?")}
          </span>
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
        <ModalFooter>
          <Button variant="danger" onClick={signOut}>
            {t("Yes")}
          </Button>
          <Button color="secondary" onClick={toggle}>
            {t("No")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default withRouter(NavBar);
