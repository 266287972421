import React from "react";
import { Row, Col, Container, Table, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

import { baseUrl } from "../../../config";
import axios from "axios";
import noDataFound from "../../Assets/images/nodata.png";
import { getLatestTransactions } from "../../../api/Stats/api";

const LatestTransactions = () => {
  const { t, i18n } = useTranslation();

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [tableData, setTableData] = React.useState(null);

  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/orders/1/transactions/history/export?limit=25&startDate=${startDate}&endDate=${endDate}`,
        ""
      );
    } catch (e) {
      
    }
    
  };

  React.useEffect(() => {
    loadTransactions();
  }, [startDate, endDate]);
  const loadTransactions = () => {
    setLoading(true);
    // axios
    //   .get(
    //     `${baseUrl}/transactions/history?limit=25&startDate=${startDate}&endDate=${endDate}`
    //   )
    getLatestTransactions(startDate, endDate)
      .then((response) => {
        setTableData(response.data.result);
        setLoading(false);
      })
      .catch((error) => {
      });
  };

  return (
    <Container fluid>
      <Row
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 7px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col
          xl={6}
          lg={6}
          style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
        >
          <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            {t("Transactions")}
          </span>
        </Col>
        <Col xl={2} lg={2} style={{ padding: "0px 8px" }}>
          <Row>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
          </Row>
          <Row style={{ width: "100%" }}>
            <Button
              onClick={exportCsv}
              type="button"
              style={{
                marginTop: "0px",
                backgroundColor: "#f4f4f4",
                color: "black",
                border: "none",
              }}
            >
              {t("Export")}{" "}
              <FiDownload
                style={{ color: "black", position: "relative", left: "25%" }}
                size={"1.2rem"}
              />
            </Button>
          </Row>
        </Col>
        <Col xl={2} lg={2} style={{ padding: "0px 8px" }}>
          <Row>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Start Date")}
            </span>
          </Row>
          <Row style={{ width: "100%" }}>
            <input
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ fontSize: "0.9rem" }}
              type="date"
              class="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </Row>
        </Col>
        <Col xl={2} lg={2} style={{ padding: "0px 8px" }}>
          <Row>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("End Date")}
            </span>
          </Row>
          <Row style={{ width: "100%" }}>
            <input
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ fontSize: "0.9rem" }}
              type="date"
              class="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </Row>
        </Col>
      </Row>

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        // tableData.length > 0 ?
        <div style={{ paddingBottom: "50px" }}>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Date & Time")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Transaction ID")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Client Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Client Province")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Store Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Payment Method")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Commission")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Status")}
                </th>
              </tr>
            </thead>
            {tableData.length > 0 ? (
              <tbody>
                {tableData.map((item, index) => (
                  <tr
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>
                      {" "}
                      {new Date(item.createdAt).toLocaleDateString()}
                      {" - "}
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </td>
                    <td style={{ padding: "20px" }}>{item.transactionId}</td>
                    <td
                      style={{ padding: "20px" }}
                    >
                      {
                      item.transactionType === "FRIEND_WALLET"
                        ? `${item.senderDetail.firstName} ${item.senderDetail.surName}`
                        : item.transactionType === "PAY" || item.transactionType === "TRANSFER_WALLET" || item.transactionType === "UPLOAD_WALLET"
                        ? `${item.customerDetail.firstName} ${item.customerDetail.surName}`
                        : `${item.customerName}`
                    }
                    </td>
                    <td style={{ padding: "20px" }}>
                    {
                      item.transactionType === "FRIEND_WALLET"
                        ? `${item.senderDetail.province}`
                        : item.transactionType === "PAY" || item.transactionType === "TRANSFER_WALLET" || item.transactionType === "UPLOAD_WALLET"
                        ? `${item.customerDetail.province}`
                        : `${item.customerProvince}`
                    }
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item?.sellerDetail?.businessInfo?.storeName
                        ? item?.sellerDetail?.businessInfo?.storeName
                        : ""}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item?.paymentMethod ||
                        item?.paymentMethodDetail?.paymentMethod ||
                        ""}

                      {/* {item?.paymentMethod === 'BAYQI'
                            ? 'BayQi'
                            : item.paymentMethod?.charAt(0)
                              .toUpperCase() +
                              item.paymentMethod
                                ?.slice(1)
                                .toLowerCase()
                       }   */}
                    </td>
                    <td style={{ padding: "20px" }}>
                    {item.commission
                      ? `${new Intl.NumberFormat().format(item.commission)} AKZ`
                      : "0"}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.amount)} AKZ
                    </td>
                    <td style={{ padding: "20px" }}>{item.status}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={1000}>
                    <div className="noDataContainer">
                      <img alt="no data" src={noDataFound} width="200px" />
                      <p>{t("No data available")}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      )}
    </Container>
  );
};

export default LatestTransactions;
