import React, { useState } from "react";
import "./Transactions.scss";
import { baseUrl } from "../../../config";
import axios from "axios";
import { Row, Col, Table, Spinner } from "react-bootstrap";
import noDataFound from "../../Assets/images/nodata.png";
import Pages from "../../Components/Pagination/Pagination";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import PayModuleModal from "./PayModuleModal";
import { useTranslation } from "react-i18next";
import { getQrPaymentTransactions } from "../../../api/Pay/api";

const QrPaymentTransactions = () => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  // const [value, setValue] = useState(null);
  // const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [detailsModalItem, setDetailsModalItem] = useState({});

  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/direct-payments/1/pay/purchase/product/export?paymentType=QR Pay&paymentMethod=${selectedMethod}&paymentStatus=${selectedStatus}&startDate=${startDate}&endDate=${endDate}`,
        ""
      );
    } catch (e) {
    }
  };

  const toggle = (isRefresh = false) => {
    setModal(!modal);
    if (isRefresh === true) {
      LoadOrders();
    }
  };

  React.useEffect(() => {
    LoadOrders();
  }, [limit, activePage, selectedMethod, selectedStatus, startDate, endDate]);

  const LoadOrders = async () => {
    setLoading(true);
    // axios
    //   .get(
    //     `${baseUrl}/pay/purchase/product?limit=${limit}&page=${activePage}&paymentType=QR Pay&paymentMethod=${selectedMethod}&paymentStatus=${selectedStatus}&startDate=${startDate}&endDate=${endDate}`
    //   )
    getQrPaymentTransactions(
      limit,
      activePage,
      selectedMethod,
      selectedStatus,
      startDate,
      endDate
    )
      .then((res) => {
        setTableData(res.data.data);
        setLoading(false);
        let num = res.data.total.noOfRecord / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((ex) => {
      });
  };

  return (
    <div
    // style={{
    //   width: "100%",
    //   height: "100%",
    //   padding: "0px 25px",
    //   backgroundColor: "#f7f7f7",
    // }}
    >
      <div
        className="MainContainer"
        style={{
          backgroundColor: "#fff",
          padding: "12px 5px",
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            xl={2}
            lg={2}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Transactions")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  marginTop: "0px",
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "25%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Start Date")}
            </span>
            <div class="input-group rounded">
              <input
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Status")}
            </span>

            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {selectedStatus
                  ? selectedStatus?.charAt(0).toUpperCase() +
                    selectedStatus?.slice(1).toLowerCase()
                  : "Show All"}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setSelectedStatus("")}>
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedStatus("COMPLETED")}>
                  {t("Completed")}
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedStatus("PENDING")}>
                  {t("Pending")}
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedStatus("REJECTED")}>
                  {t("Rejected")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Payment Method")}
            </span>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {selectedMethod
                  ? selectedMethod?.charAt(0).toUpperCase() +
                    selectedMethod?.slice(1).toLowerCase()
                  : t("Show All")}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setSelectedMethod("")}>
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedMethod("BAYQI")}>
                  Bayqi
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedMethod("EXPRESS")}>
                  Express
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedMethod("PAGAQI")}>
                  Pagaqi
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </div>

      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        // tableData.length > 0 ?
        <div>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Date & Time")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Transaction ID")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Client Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Client Province")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Store Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Payment Method")}
                </th>
                {/* <th style={{ padding: "20px", fontSize: "14px" }}>Client Address</th> */}
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Net Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("BayQi Commission")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Status")}
                </th>
              </tr>
            </thead>

            {tableData.length > 0 ? (
              <tbody>
                {tableData.map((item, index) => (
                  <tr
                    onClick={() => {
                      setDetailsModalItem(item);
                      toggle();
                    }}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>
                      {" "}
                      {new Date(item.createdAt).toLocaleDateString()}
                      {" - "}
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </td>
                    <td style={{ padding: "20px" }}>{item.transactionId}</td>
                    <td
                      style={{ padding: "20px" }}
                    >{`${item.customer.firstName} ${item.customer.surName}`}</td>
                    <td style={{ padding: "20px" }}>
                      {item.customer.province ? item.customer.province : "..."}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.sellerDetail.businessInfo.storeName
                        ? item.sellerDetail.businessInfo.storeName
                        : "..."}
                    </td>
                    <td style={{ padding: "20px" }}>
                    {item.paymentMethod === 'BAYQI'
                            ? 'BayQi'
                            : item.paymentMethod?.charAt(0)
                              .toUpperCase() +
                              item.paymentMethod
                                ?.slice(1)
                                .toLowerCase()
                       }
                    </td>
                    {/* <td style={{ padding: "20px" }}>
                    {item.shippingAddress ? item.shippingAddress : "NaN"}
                  </td> */}
                    <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.amount)} AKZ
                    </td>
                    <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.netAmount)} AKZ
                    </td>
                    <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.commission)} AKZ
                    </td>
                    <td style={{ padding: "20px" }}>
                      
                        {item.paymentStatus === 'BAYQI'
                            ? 'BayQi'
                            : item.paymentStatus?.charAt(0)
                              .toUpperCase() +
                              item.paymentStatus
                                ?.slice(1)
                                .toLowerCase()
                       }
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={20}>
                    <div className="noDataContainer">
                      <img alt="No Data" src={noDataFound} width="200px" />
                      <p>{t("No data available")}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
          {tableData.length > 0 && (
            <div style={{ height: "100px" }}>
              <Row style={{ display: "block", paddingBottom: "0px" }}>
                <div>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Pages
                        noOfPages={noOfPages}
                        activePage={activePage}
                        setActivePage={setActivePage}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle
                          style={{
                            backgroundColor: "#fff",
                            color: "#333",
                            borderColor: "#CED4DA",
                            fontSize: "0.9rem",
                            width: "100%",
                            margin: "auto",
                          }}
                          caret
                        >
                          <span
                            style={{
                              color: "#333",
                              fontSize: "0.7rem",
                            }}
                          >
                            {t("Items per page")}
                          </span>
                          <span
                            style={{
                              marginLeft: "5px",
                              fontWeight: "bold",
                              marginRight: "5px",
                            }}
                          >
                            {limit}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu style={{ marginTop: "5px" }}>
                          <DropdownItem
                            onClick={() => {
                              setLimit(1);
                              setActivePage(1);
                            }}
                          >
                            1
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(5);
                              setActivePage(1);
                            }}
                          >
                            5
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(10);
                              setActivePage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}
        </div>
      )}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <PayModuleModal item={detailsModalItem} toggle={toggle} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default QrPaymentTransactions;
