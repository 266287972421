import React, { useState } from "react";
import {
  Row,
  Col
} from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import AddAttribute from "./AddAttribute";
import "./Categories.scss";
import {
  changeCategoryStatus,
} from "../../../api/Categories/api";

export default function CategoryDetailModal({
    toggleCatModal,
    data,
    getCategories,
    getPendingCategories,
  }) {
    const { t, i18n } = useTranslation();
    const customStyles = {
      option: (defaultStyles) => ({
        ...defaultStyles,
        fontSize: "12px",
      }),
      singleValue: (defaultStyles) => ({
        ...defaultStyles,
        fontSize: "12px",
      }),
  
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          fontSize: "12px",
        };
      },
    };
    const [status, setStatus] = useState("");
    const updateStatus = () => {
      // axios
      //   .put(`${baseUrl}/categories/${data._id}`, {
      //     isApproved: status,
      //   })
      changeCategoryStatus(data._id, status)
        .then((res) => {
        })
        .catch((err) => {
        });
    };
    return (
      <div>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("ID")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.publicID}
            </span>
          </Col>
        </Row>
  
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Domain")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.businessDomain}
            </span>
          </Col>
        </Row>
  
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Name")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.title}
            </span>
          </Col>
        </Row>
  
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Status")}
            </span>
          </Col>
          <Col md={4}>
            <Select
              styles={customStyles}
              options={[
                { value: "approved", label: "Approve" },
                { value: "false", label: "Reject" },
              ]}
              onChange={({ value }) => setStatus(value)}
            />
          </Col>
          <Col md={2} className={{ padding: "0px" }}>
            {status !== "" && (
              <button
                type="button"
                style={{
                  backgroundColor: "#049EFF",
                  color: "#fff",
                  border: "none",
                  fontSize: "12px",
                  padding: "8px",
                  borderRadius: "4px",
                  fontWeight: "bold",
                }}
                onClick={updateStatus}
              >
                {t("update")}
              </button>
            )}
          </Col>
        </Row>
      </div>
    );
}


