import React, { useState} from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import { ButtonToggle } from "reactstrap";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { v4 as uuid } from "uuid";
import { Storage } from "aws-amplify";
import { useTranslation } from "react-i18next";

import {
  updateShopCenters,
} from "../../../api/ShopCenters/api";

export default function DetailModal({ toggle, getShoppingCenters, data }) {
    const { t, i18n } = useTranslation();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
  
    const [image, setImage] = useState(data.image);
    const [name, setName] = useState(data.name);
    const [regNo, setRegNo] = useState(data.regNumber);
    const [description, setDescription] = useState(data.description);
    const [address, setAddress] = useState(data.address);
  
    const onFileChange = async (e) => {
      setLoading(true);
      const files = e.target.files;
  
      try {
        const fileKey = uuid() + files[0].name.replace(/\s/g, "-").toLowerCase();
        await Storage.put(fileKey, files[0]).then((res) => {
          setLoading(false);
          setImage(
            `${process.env.REACT_APP_S3_BUCKET}/public/${res.key}`
          );
        });
      } catch (error) {
        setLoading(false);
      }
    };
  
    const onDeleteImage = async (file) => {
      file = file.split("public/")[1];
      setLoading(true);
  
      try {
        await Storage.remove(file).then((res) => {
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);
      }
    };
  
    const update = () => {
      let dataChanged = false;
      setLoading(true);
      setError("");
      if (
        name == data.name &&
        regNo == data.regNumber &&
        description == data.description &&
        address == data.address &&
        image == data.image
      ) {
        setError(t("*Change any field to update record."));
        setLoading(false);
      } else {
        const body = {};
        if (name !== data.name) {
          dataChanged = true;
          body.name = name;
        }
        if (address !== data.address) {
          dataChanged = true;
          body.address = address;
        }
        if (regNo !== data.regNumber) {
          dataChanged = true;
          body.regNumber = regNo;
        }
        if (description !== data.description) {
          dataChanged = true;
          body.description = description;
        }
        if (image !== data.image) {
          dataChanged = true;
          body.image = image;
        }
        if (dataChanged) {
          // axios
          //   .put(`${baseUrl}/shopping-centers?id=${data._id}`, body)
          updateShopCenters(data._id, body)
            .then((res) => {
              getShoppingCenters();
              setLoading(false);
              toggle();
            })
  
            .catch((err) => {
              setLoading(false);
              setError(t("*Something went wrong."));
            });
        }
      }
    };
  
    return (
      <>
        <Row>
          <Col>
            <label
              style={{ display: "block", fontSize: "12px", fontWeight: "bold" }}
            >
              {t("Shop Center Logo")}
            </label>
          </Col>
        </Row>
        <Row className="align-items-start">
          <Col>
            {!image ? (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  multiple
                  onClick={() => setShowInfo(false)}
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Image")}</p>
                  <p style={{ color: "red", display: "inline" }}> *</p>
                </label>
              </>
            ) : (
              <>
                <img
                  alt=""
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}
                  htmlFor="imgUpload"
                  src={image}
                  width={65}
                  height={65}
                  style={{ borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => {
                    onDeleteImage(image);
                    setImage("");
                  }}
                />
                <p
                  style={{
                    visibility: showInfo ? "visible" : "hidden",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  *{t("click to delete image")}*
                </p>
              </>
            )}
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Name")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={"256"}
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              id="title"
            />
          </Col>
        </Row>
  
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Registration number")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              value={regNo}
              onChange={(e) => setRegNo(e.target.value)}
              maxLength={"256"}
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              id="title"
            />
          </Col>
        </Row>
  
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Description")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <TextareaAutosize
              minRows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              maxLength={"256"}
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              id="title"
            />
          </Col>
        </Row>
  
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Address")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <TextareaAutosize
              minRows={3}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              maxLength={"256"}
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              id="title"
            />
          </Col>
        </Row>
  
        {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
        <Row>
          <Col>
            <ButtonToggle
              disabled={loading}
              onClick={update}
              style={{
                margin: "20px 0px",
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "#fff" }}>
                {loading ? <Spinner size="sm" animation="border" /> : t("Update")}{" "}
              </span>
            </ButtonToggle>
          </Col>
        </Row>
      </>
    );
}