import React, { useState } from "react";
import {
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { v4 as uuid } from "uuid";

import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  updateBlogCategory,
} from "../../../api/Blogs/api";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

export default function UpdateCategoryModal({ toggle, item }) {
    const { t, i18n } = useTranslation();
  
    const [title, setTitle] = useState(item.title);
    const [catError, setCatError] = useState("");
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(item.status);
  
    const editCategory = () => {
      setLoading(true);
      if (title !== item.title || status !== item.status) {
        if (title !== "") {
          updateBlogCategory(item._id, {
            title: title,
            status: status,
          })
            .then((res) => {
              toggle(true);
              setLoading(false);
            })
            .catch((res) => {
              setLoading(false);
            });
        } else {
          setCatError(t("(*) fields are mandatory"));
          setLoading(false);
        }
      } else {
        setCatError(t("Change Values to Update"));
        setLoading(false);
      }
    };
  
    return (
      <div>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              maxLength={"256"}
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              id="title"
            />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              {t("Status")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {status
                    ? status === "enable" ||
                      status === "Enable" ||
                      status === "Active"
                      ? t("Active")
                      : status === "disable" ||
                        status === "Disable" ||
                        status === "Inactive"
                      ? t("Inactive")
                      : status
                    : t("Select")}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setStatus("Active")}>
                  {t("Active")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setStatus("Inactive")}>
                  {t("Inactive")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
  
        {catError && (
          <span style={{ color: "red", fontSize: "12px" }}>{catError}</span>
        )}
        <ButtonToggle
          onClick={editCategory}
          disabled={loading ? true : false}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "#fff" }}>
            {loading ? <Spinner size="sm" animation="border" /> : t("Update")}{" "}
          </span>
        </ButtonToggle>
      </div>
    );
}


