import instance from "./axios";

export const totalSales = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/sales?sellerId=&startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};

export const avgTransactions = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/avgTransaction?sellerId=&startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};

export const retrunRate = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/return-rate?startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};

export const totalProducts = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/products?startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};

export const deliveryProducts = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/delivery-products?startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};

export const totalOrders = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/orders?startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};

export const totalPayTransactions = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/pay?sellerId=&startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};

export const totalBayqiCommission = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/bayqi-commission?startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};

export const totalSellers = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/sellers?startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};

export const totalCustomers = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/customers?startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};
export const getTotalCustomerBalance = async () => {
  try {
    return instance.get(`/kpis/customers`);
  } catch (err) {
    return err;
  }
};

export const getSellerBalanace = async () => {
  try {
    return instance.get(`/kpis/sellers-balance?sellerId=`);
  } catch (err) {
    return err;
  }
};


export const totalTransactions = (startDate,
  endDate) => {
  try {
    return instance.get(`/kpis/transactions?sellerId=&startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};
export const getSellerCaptiveBalance = (startDate,
  endDate) =>{
  try {
    return instance.get(`/kpis/seller-captive-balance?startDate=${startDate}&endDate=${endDate}`);
  } catch (e) {
  }
};
