import React from "react";
import {
  Row,
  Col
} from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default function AddModal() {
  return (
    <>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
            //   alignSelf: "center",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              Payment
            </label>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                // color={'light'}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  Select
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>Unitel</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Telo</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
            //   alignSelf: "center",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              Provinces
            </label>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                // color={'light'}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  Select
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>Bengo</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Luanda</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
            //   alignSelf: "center",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              Type
            </label>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                // color={'light'}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  Select
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>Fixed</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Percentage</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
  
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              Amount
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                height: "33px",
              }}
            />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              Min Cart Amount
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                height: "33px",
              }}
            />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              Max Cashback Discount
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                height: "33px",
              }}
            />
          </Col>
        </Row>
  
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
            //   alignSelf: "center",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              State
            </label>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                // color={'light'}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  Select
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>Active</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Inactive</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <ButtonToggle
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "#fff" }}>Add </span>
        </ButtonToggle>
      </>
  )
}

