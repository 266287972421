import instance from "./axios";

export const getLatestTransactions = async (startDate, endDate) => {
  try {
    return instance.get(
      `/transactions/history?limit=25&startDate=${startDate}&endDate=${endDate}`
    );
  } catch (err) {
    return err;
  }
};

// export const totalOrders = () => {
//   try {
//     return instance.get(`/kpis/orders`);
//   } catch (e) {
//     
//   }
// };

// export const totalPayTransactions = () => {
//   try {
//     return instance.get(`/kpis/pay`);
//   } catch (e) {
//     
//   }
// };

// export const totalTransactions = () => {
//   try {
//     return instance.get(`/kpis/transactions?sellerId=`);
//   } catch (e) {
//     
//   }
// };

// export const totalSales = () => {
//   try {
//     return instance.get(`/kpis/sales?sellerId=`);
//   } catch (e) {
//     
//   }
// };

// export const totalBayqiCommission = () => {
//   try {
//     return instance.get(`/kpis/bayqi-commission`);
//   } catch (e) {
//     
//   }
// };

// export const totalSellers = () => {
//   try {
//     return instance.get(`/kpis/sellers`);
//   } catch (e) {
//     
//   }
// };

// export const totalCustomers = () => {
//   try {
//     return instance.get(`/kpis/customers`);
//   } catch (e) {
//     
//   }
// };
