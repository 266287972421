import instance from "./axios";

export const fetchCommission = async (
  limit,
  activePage,
  typeDebounce,
  nameDebounce
) => {
  try {
    return instance.get(
      `/admin/commission?limit=${limit}&page=${activePage}&type=${typeDebounce}&name=${nameDebounce}`
    );
  } catch (e) {
    return e;
  }
};

export const createCommission = async (body) => {
  try {
    return instance.post(`/admin/commission`, body);
  } catch (e) {
    return e;
  }
};

export const deleteCommission = async (id) => {
  try {
    return instance.delete(`/admin/commission?id=${id}`);
  } catch (e) {
    return e;
  }
};

export const updateCommission = async (id, body) => {
  try {
    return instance.put(`/admin/commission?id=${id}`, body);
  } catch (e) {
    return e;
  }
};
