import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  createAdminDelivery,
} from "../../../api/BayqiLogistics/api";
import { useTranslation } from "react-i18next";

export default function CreateDeliveryModal({ toggle }) {
    const { t, i18n } = useTranslation();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [cost, setCost] = useState("");
    const [est, setEst] = useState("");
  
    const addDelivery = () => {
      setError("");
      setLoading(true);
  
      if (
        title === "" ||
        cost === "" ||
        est === "" ||
        country === "" ||
        province === ""
      ) {
        setError(t("(*) fields are mandatory."));
        setLoading(false);
      } else {
        createAdminDelivery({
          title,
          cost,
          country,
          province,
          estimateTime: est,
          status: "Active",
        })
          .then((res) => {
            setLoading(false);
            toggle(true);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setError(t("*Something went wrong."));
          });
      }
    };
    return (
      <div style={{ padding: "0px 30px" }}>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
              className="inputField"
              id="Title"
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Country")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <CountryDropdown
              defaultOptionLabel="Select a country"
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={country}
              onChange={(val) => {
                setCountry(val);
              }}
            />
            {/* <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Country"
              className="inputField"
              id="Title"
            /> */}
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Province")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <RegionDropdown
              blankOptionLabel="No country selected"
              defaultOptionLabel="Now select a region"
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              country={country}
              value={province}
              onChange={(val) => {
                setProvince(val);
              }}
            />
            {/* <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              placeholder="Province"
              className="inputField"
              id="Title"
            /> */}
          </Col>
        </Row>
        <Row>
          <Col className="sm-col-12 md-col-6">
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Cost")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              placeholder="0.00 AKZ"
              className="inputField"
              id="Cost"
              type="number"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Delivery time")} (EST)
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <Select
              options={[
                { value: "0", label: t("Same day") },
                { value: "1", label: t("1 day") },
                { value: "2", label: t("2 days") },
                { value: "3", label: t("3 days") },
                { value: "4", label: t("4 days") },
                { value: "5", label: t("5 days") },
              ]}
              onChange={({ value }) => setEst(value)}
            />
          </Col>
        </Row>
  
        {error && (
          <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
            {error}
          </p>
        )}
  
        <Button
          onClick={addDelivery}
          disabled={loading}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Spinner
              animation="border"
              style={{ color: "white", padding: "9px" }}
              size="sm"
            />
          ) : (
            t("Save")
          )}
        </Button>
      </div>
    );
}


