import instance from "./axios";

export const updateSellerStatus = async (id, status) => {
  try {
    return await instance.put(`/sellers?sellerId=${id}`, {
      status: status,
    });
  } catch (error) {
    return error;
  }
};

// fetch all sellers (both individual and company accounts)
export const fetchAllSellers = async (
  type = "individual",
  startDate = "",
  endDate = "",
  page = 1,
  limit = 10,
  name = "",
  storeName = ""
) => {
  return instance
    .get(
      `/sellers?accountType=${type}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}&name=${name}&storeName=${storeName}`
    )
    .then((res) => res);
};
