import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  Spinner,
  InputGroup,
  Toast,
  Tabs,
  Tab,
} from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { v4 as uuid } from "uuid";

import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import uploadImg from "../../Assets/images/uploadfile.png";
import noDataFound from "../../Assets/images/nodata.png";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "react-select";
import { Storage } from "aws-amplify";
import {
  addBlog,
  getBlogs,
  updateBlog,
  deleteBlog,
  getBlogCategories,
  createBlogCategory,
  deleteBlogCategory,
  updateBlogCategory,
} from "../../../api/Blogs/api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CreateCategory from "./CreateCategory";
import CreateAdModal from "./CreateAdModal";
import UpdateCategoryModal from "./UpdateCategoryModal";
import UpdateBlogModal from "./UpdateBlogModal";
import DeleteBlogModal from "./DeleteBlogModal";
import CategoryDetails from "./CategoryDetails";
import { useTranslation } from "react-i18next";



const Blogs = () => {
  const { t, i18n } = useTranslation();
  const [deleteBlogId, setDeleteBlogId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshCat, setIsRefreshCat] = useState(false);
  const [catModal, setCatModal] = useState(false);
  const [deleteBlogModal, setDeleteBlogModal] = useState(false);
  const [blogLoading, setBlogLoading] = useState(false);
  const [deleteBlogsId, setDeleteBlogsId] = useState("");
  const [updateBlogModal, setUpdateBlogModal] = useState(false);
  const [updateBlogItem, setUpdateBlogItem] = useState({});
  const [catUpdateModal, setCatUpdateModal] = useState(false);
  const [updateCatItem, setUpdateCatItem] = useState({});

  const toggleCatUpdateModal = (refreshFlag = false) => {
    setCatUpdateModal(!catUpdateModal);
    if (refreshFlag) {
      setLoading(true);
      getBlogCategories()
        .then((res) => {
          setBlogCategories(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const updateBlogModalToggle = (refreshFlag = false) => {
    setUpdateBlogModal(!updateBlogModal);
    if (refreshFlag) {
      setBlogLoading(true);
      getBlogs()
        .then((res) => {
          setTableData(res.data.data);
          setBlogLoading(false);
        })
        .catch((error) => {
          setBlogLoading(false);
        });
    }
  };

  const deleteBlogModalToggle = (refreshFlag = false) => {
    setDeleteBlogModal(!deleteBlogModal);
    if (refreshFlag) {
      setBlogLoading(true);
      getBlogs()
        .then((res) => {
          setTableData(res.data.data);
          setBlogLoading(false);
        })
        .catch((error) => {
          setBlogLoading(false);
        });
    }
  };

  const toggleCatModal = () => {
    setCatModal(!catModal);
    if (isRefreshCat) {
      setLoading(true);
      setIsRefreshCat(false);
      getBlogCategories()
        .then((res) => {
          setBlogCategories(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const toggle = () => {
    setModal(!modal);
    if (isRefresh) {
      setBlogLoading(true);
      setIsRefresh(false);
      getBlogs()
        .then((res) => {
          setTableData(res.data.data);
          setBlogLoading(false);
        })
        .catch((error) => {
          setBlogLoading(false);
        });
    }
  };

  const [loading, setLoading] = React.useState(false);
  const [blogCatOptions, setBlogCatOptions] = React.useState([]);
  const [catDetailModal, setCatDetailModal] = React.useState(false);

  const toggleCatDetailModal = (refreshFlag = false) => {
    setCatDetailModal(!catDetailModal);
    if (refreshFlag) {
      setLoading(true);
      setIsRefreshCat(false);
      getBlogCategories()
        .then((res) => {
          setBlogCategories(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setBlogLoading(true);
    getBlogs()
      .then((res) => {
        setTableData(res.data.data);
        setBlogLoading(false);
      })
      .catch((error) => {
        setBlogLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);

    getBlogCategories()
      .then((res) => {
        let options = [];
        res.data.data.map((item) => {
          options.push({ label: item.title, value: item._id });
        });
        setBlogCatOptions(options);
        setBlogCategories(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <Tabs
      defaultActiveKey="blogs"
      id="uncontrolled-tab-example"
      className="mb-3"
      // onSelect={(value) => alert(value)}
    >
      <Tab eventKey="blogs" title="Blogs">
        <div className="">
          {/* Header */}

          <div className="financesHeader">
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: "20px",
                paddingRight: "20px",
              }}
            >
              <Col
                xl={3}
                lg={3}
                style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
              >
                <div>
                  <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                    {t("Blogs")}
                  </span>
                </div>
              </Col>
              <Col xl={2} lg={2}>
                <div className="d-grid">
                  <Button
                    onClick={() => {
                      toggle();
                      setIsRefresh(true);
                    }}
                    type="button"
                    style={{
                      backgroundColor: `${blue}`,
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    {t("Create Post")}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>

          {/* Table Content */}
          {blogLoading ? (
            <span className="spinnerContainer">
              <Spinner animation="border" variant="primary" />
            </span>
          ) : (
            <Table
              style={{
                cursor: "pointer",
                marginTop: "25px",
                backgroundColor: "#f7f7f7",
                borderRadius: "25px",
              }}
              borderless={true}
              hover
            >
              <thead>
                <tr>
                  <th style={{ padding: "20px", fontSize: "16px" }}>
                    {t("ID")}
                  </th>
                  <th style={{ padding: "20px", fontSize: "16px" }}>
                    {t("Image")}
                  </th>
                  <th style={{ padding: "20px", fontSize: "16px" }}>
                    {t("Category Name")}
                  </th>
                  <th style={{ padding: "20px", fontSize: "16px" }}>
                    {t("Title")}
                  </th>
                  <th style={{ padding: "20px", fontSize: "16px" }}>
                    {t("Status")}
                  </th>
                </tr>
              </thead>
              <tbody style={{ marginBottom: "25px" }}>
                {tableData.map((item, index) => (
                  <tr
                    onClick={() => {
                      setUpdateBlogItem(item);
                      updateBlogModalToggle();
                    }}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>{item._id}</td>
                    <td style={{ padding: "20px" }}>
                      <img
                        src={item.image}
                        width={65}
                        height={65}
                        alt="blog-img"
                        style={{ borderRadius: "8px", cursor: "pointer" }}
                      />
                    </td>

                    <td style={{ padding: "20px" }}>{item.category}</td>
                    <td style={{ padding: "20px" }}>{item.title}</td>
                    <td style={{ padding: "20px" }}>
                      {item.status === "active" || item.status === "Active"
                        ? t("Active")
                        : item.status === "Inactive" ||
                          item.status === "inactive"
                        ? t("Inactive")
                        : item.status}
                    </td>
                    <td style={{ padding: "20px" }}>
                      <span
                        style={{ color: "red", textDecoration: "underline" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteBlogsId(item._id);
                          deleteBlogModalToggle();
                        }}
                      >
                        {t("Delete")}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          <span style={{ visibility: "hidden", marginTop: "20px" }}>Hello</span>

          {tableData.length <= 0 && (
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img alt="" src={noDataFound} width="200px" />
              <p>{t("No data available")}</p>
            </div>
          )}

          <Modal show={modal} onHide={toggle} className="">
            <AiFillCloseCircle
              onClick={toggle}
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                color: "red",
                cursor: "pointer",
              }}
              size={"1.5rem"}
            />
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                {t("New Blog")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <CreateAdModal toggle={toggle} options={blogCatOptions} />
            </Modal.Body>
          </Modal>

          <Modal
            show={updateBlogModal}
            onHide={updateBlogModalToggle}
            className=""
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                {t("Update Blog Details")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <UpdateBlogModal
                toggle={updateBlogModalToggle}
                options={blogCatOptions}
                item={updateBlogItem}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={deleteBlogModal}
            onHide={deleteBlogModalToggle}
            className=""
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                {t("Delete Blog Confirm")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <DeleteBlogModal
                toggle={deleteBlogModalToggle}
                id={deleteBlogsId}
              />
            </Modal.Body>
          </Modal>
        </div>
      </Tab>
      <Tab eventKey="blogCategories" title="Categories">
        <div className="">
          {/* Header */}

          <div className="financesHeader">
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: "20px",
                paddingRight: "20px",
              }}
            >
              <Col
                xl={3}
                lg={3}
                style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
              >
                <div>
                  <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                    {t("Blog Categories")}
                  </span>
                </div>
              </Col>
              <Col xl={3} lg={3}>
                <div className="d-grid">
                  <Button
                    onClick={() => {
                      toggleCatModal();
                      setIsRefreshCat(true);
                    }}
                    type="button"
                    style={{
                      backgroundColor: `${blue}`,
                      color: "#fff",
                      border: "none",
                    }}
                  >
                    {t("Add category")}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>

          {/* Table Content */}
          {loading ? (
            <span className="spinnerContainer">
              <Spinner animation="border" variant="primary" />
            </span>
          ) : (
            <Table
              style={{
                cursor: "pointer",
                marginTop: "25px",
                backgroundColor: "#f7f7f7",
                borderRadius: "25px",
              }}
              borderless={true}
              hover
            >
              <thead>
                <tr>
                  <th style={{ padding: "20px", fontSize: "16px" }}>
                    {t("ID")}
                  </th>
                  <th style={{ padding: "20px", fontSize: "16px" }}>
                    {t("Title")}
                  </th>
                  <th style={{ padding: "20px", fontSize: "16px" }}>
                    {t("Status")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {blogCategories.map((item, index) => (
                  <tr
                    onClick={() => {
                      setUpdateCatItem(item);
                      toggleCatUpdateModal();
                    }}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>{item._id}</td>
                    <td style={{ padding: "20px" }}>{item.title}</td>
                    <td style={{ padding: "20px" }}>
                      {item.status === "Enable"
                        ? t("Active")
                        : item.status === "Disable"
                        ? t("Inactive")
                        : item.status}
                    </td>
                    <td
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteBlogId(item._id);
                        toggleCatDetailModal();
                      }}
                      style={{
                        padding: "20px",
                        textDecoration: "underline",
                        color: "red",
                      }}
                    >
                      {t("Delete")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {/* if no data available */}
          {blogCategories.length <= 0 && (
            <div className="noDataContainer">
              <img alt="" src={noDataFound} width="200px" />
              <p>{t("No data available")}</p>
            </div>
          )}

          {/* cat details modal */}
          <Modal
            size="sm"
            centered
            show={catDetailModal}
            onHide={toggleCatDetailModal}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                {t("Confirm Delete Category")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <CategoryDetails
                toggleCatDetailModal={toggleCatDetailModal}
                id={deleteBlogId}
              />
            </Modal.Body>
          </Modal>

          {/* add cat modal */}
          <Modal show={catModal} onHide={toggleCatModal}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                {t("New Category")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <CreateCategory toggleCatModal={toggleCatModal} />
            </Modal.Body>
          </Modal>

          <Modal show={catUpdateModal} onHide={toggleCatUpdateModal}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "20px" }}>
                {t("Update Category")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <UpdateCategoryModal
                item={updateCatItem}
                toggle={toggleCatUpdateModal}
              />
            </Modal.Body>
          </Modal>
        </div>
      </Tab>
    </Tabs>
  );
};

export default Blogs;
