import React, { useState } from "react";
import axios from "axios";
import { Row, Col, Table, Modal, Button, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { baseUrl } from "../../../config";
import Pages from "../../Components/Pagination/Pagination";
import noDataFound from "../../Assets/images/nodata.png";
import TransactionDetailsModal from "./TransactionDetailsModal";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { getDeliveryTransactions } from "../../../api/Transactions/api";

const DeliveryTransactions = () => {
  const { t, i18n } = useTranslation();
  const [noOfPages, setNoOfPages] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [modal, setModal] = useState(false);
  const [detailItem, setDetailItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const toggle = (isRefresh = false) => {
    setModal(!modal);
    if (isRefresh === true) {
      loadOrders();
    }
  };

  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/orders/1/transactions/history/export?orderStatus=${selectedStatus}&startDate=${startDate}&endDate=${endDate}&deliveryMethod=Admin Delivery&orderStatus=${selectedStatus}`,
        ""
      );
    } catch (e) {
    }
  };

  const loadOrders = () => {
    setNoOfPages([]);
    setLoading(true);
    // axios
    //   .get(
    //     `${baseUrl}/orders/history?deliveryMethod=Admin Delivery&startDate=${startDate}&endDate=${endDate}&page=${activePage}&limit=${limit}&orderStatus=${selectedStatus}`
    //   )
    getDeliveryTransactions(
      startDate,
      endDate,
      activePage,
      limit,
      selectedStatus
    )
      .then((res) => {
        setTableData(res.data.result);
        setLoading(false);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    loadOrders();
  }, [selectedStatus, limit, activePage, startDate, endDate]);

  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader pt-3 ps-2">
        <Row style={{ width: "100%" }}>
          <Col
            xl={4}
            lg={4}
            style={{ padding: "0px", paddingLeft: "34px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Transactions")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  marginTop: "0px",
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "25%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Start Date")}
            </span>
            <div class="input-group rounded">
              <input
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Status")}
            </span>

            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {selectedStatus
                  ? selectedStatus?.charAt(0).toUpperCase() +
                    selectedStatus?.slice(1).toLowerCase()
                  : t("Show All")}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setSelectedStatus("")}>
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedStatus("COMPLETED")}>
                  {t("Completed")}
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedStatus("PENDING")}>
                  {t("Pending")}
                </DropdownItem>
                <DropdownItem onClick={() => setSelectedStatus("REJECTED")}>
                  {t("Rejected")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </div>
      {/* Table Content */}
      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "16px" }}>
                  {t("Date & Time")}
                </th>
                <th style={{ padding: "20px", fontSize: "16px" }}>
                  {t("Transaction Number")}
                </th>
                <th style={{ padding: "20px", fontSize: "16px" }}>
                  {t("Client Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "16px" }}>
                  {t("Store Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "16px" }}>
                  {t("Address")}
                </th>
                <th style={{ padding: "20px", fontSize: "16px" }}>
                  {t("Province")}
                </th>
                <th style={{ padding: "20px", fontSize: "16px" }}>
                  {t("Commission")}
                </th>
                <th style={{ padding: "20px", fontSize: "16px" }}>
                  {t("Net Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "16px" }}>
                  {t("Total")}
                </th>
                <th style={{ padding: "20px", fontSize: "16px" }}>
                  {t("Status")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Delivery Code")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Delivery Cost")}
                </th>
              </tr>
            </thead>
            {tableData.length > 0 ? (
              <tbody>
                {tableData.map((item, index) => (
                  <tr
                    onClick={() => {
                      setDetailItem(item);
                      toggle(false);
                    }}
                    key={index}
                    // onClick={() =>
                    //    history.push({
                    //     pathname: "/OrderDetails",
                    //     state: { details: item },
                    //   })
                    // }
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>
                      {new Date(
                        item.createdAt
                      ).toLocaleDateString()}
                      {" - "}
                      {new Date(
                        item.createdAt
                      ).toLocaleTimeString()}
                    </td>
                    <td style={{ padding: "20px" }}>{item.transactionId}</td>
                    <td
                      style={{ padding: "20px" }}
                    >{`${item.customerName}`}</td>
                    {/* <td style={{ padding: "20px" }}>{item.deliveryMethod}</td> */}
                    {/* <td style={{ padding: "20px" }}>{item.paymentMethod}</td> */}
                    <td style={{ padding: "20px" }}>
                      {item.sellerDetail?.businessInfo?.storeName}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.shippingAddress ? item.shippingAddress : "NaN"}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {item.customerProvince ? item.customerProvince : "NaN"}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.commission)} AKZ
                    </td>
                    <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.netAmount)} AKZ
                      </td>
                      <td style={{ padding: "20px" }}>
                      {new Intl.NumberFormat().format(item.totalPrice)} AKZ
                    </td>
                    <td style={{ padding: "20px" }}>
                        {
                        item.orderStatusDetail === "COMPLETED"
                          ? t("Completed")
                          : item.orderStatusDetail === "PENDING"
                          ? t("Pending")
                          : item.orderStatusDetail
                        }      
                    </td>
                    <td style={{ padding: "20px" }}>
                        {item?.deliveryDetail[0]?.deliveryCode}
                      </td>
                    <td style={{ padding: "20px" }}>
                        {new Intl.NumberFormat().format(item.totalDeliveryCost)} AKZ
                      </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={100}>
                    <div className="noDataContainer">
                      <img alt="" src={noDataFound} width="200px" />
                      <p>{t("No data available")}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>

          {tableData.length > 0 && (
            <div style={{ height: "100px" }}>
              <Row style={{ display: "block", paddingBottom: "0px" }}>
                <div>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {/* Pagination   */}
                      {/* <Pagination>
                  {noOfPages.map((item, index) => (
                    <Pagination.Item
                      onClick={() => {
                        // setLimit(10);
                        setActivePage(item);
                      }}
                      active={activePage === item}
                      key={index}
                    >
                      {item}
                    </Pagination.Item>
                  ))}
                </Pagination> */}
                      <Pages
                        noOfPages={noOfPages}
                        activePage={activePage}
                        setActivePage={setActivePage}
                      />
                    </div>
                    {/* No of products on page toggler  */}
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle
                          style={{
                            backgroundColor: "#fff",
                            color: "#333",
                            borderColor: "#CED4DA",
                            fontSize: "0.9rem",
                            width: "100%",
                            margin: "auto",
                          }}
                          caret
                        >
                          <span
                            style={{
                              color: "#333",
                              fontSize: "0.7rem",
                            }}
                          >
                            {t("Items per page")}
                          </span>
                          <span
                            style={{
                              marginLeft: "5px",
                              fontWeight: "bold",
                              marginRight: "5px",
                            }}
                          >
                            {limit}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu style={{ marginTop: "5px" }}>
                          <DropdownItem
                            onClick={() => {
                              setLimit(1);
                              setActivePage(1);
                            }}
                          >
                            1
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(5);
                              setActivePage(1);
                            }}
                          >
                            5
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(10);
                              setActivePage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}
        </>
      )}
      <Modal centered show={modal} onHide={toggle}>
        <ModalHeader toggle={toggle}>{t("Order Details")}</ModalHeader>
        <ModalBody>
          <TransactionDetailsModal item={detailItem} toggle={toggle} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default DeliveryTransactions;
