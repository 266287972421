import React, { useState } from "react";
import {
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { v4 as uuid } from "uuid";

import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";
import { Storage } from "aws-amplify";
import {
  updateBlog,
} from "../../../api/Blogs/api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

export default function UpdateBlogModal({ item, toggle, options }) {
    const { t, i18n } = useTranslation();
    const [showInfo, setShowInfo] = useState(false);
    const [error, setError] = useState("");
    const [image, setImage] = useState(item.image);
    const [title, setTitle] = useState(item.title);
    const [category, setCategory] = useState(item.category);
    const [description, setDescription] = useState(item.description);
    const [status, setStatus] = useState(item.status);
    const [updatingBlog, setUpdatingBlog] = useState(false);
  
    const editBlog = () => {
      setUpdatingBlog(true);
      if (
        image !== item.image ||
        title !== item.title ||
        category !== item.category ||
        description !== item.description ||
        status !== item.status
      ) {
        if (
          image === "" ||
          title === "" ||
          category === "" ||
          description === ""
        ) {
          setUpdatingBlog(false);
          setError(t("(*) Fields are required"));
        } else {
          updateBlog(item._id, {
            image: image,
            title: title,
            description: description,
            status: status,
            categoryId: category,
          })
            .then((res) => {
              setUpdatingBlog(false);
              toggle(true);
            })
            .error((err) => {
              setUpdatingBlog(false);
            });
        }
      } else {
        setUpdatingBlog(false);
        setError(t("Change Values to update."));
      }
    };
  
    const onFileChange = async (e) => {
      setUpdatingBlog(true);
      const files = e.target.files;
  
      try {
        const fileKey = uuid() + files[0].name.replace(/\s/g, "-").toLowerCase();
        await Storage.put(fileKey, files[0]).then((res) => {
          setUpdatingBlog(false);
          setImage(`${process.env.REACT_APP_S3_BUCKET}/public/${res.key}`);
        });
      } catch (error) {
        setUpdatingBlog(false);
      }
    };
  
    const onDeleteImage = async (file) => {
      file = file.split("public/")[1];
      setUpdatingBlog(true);
  
      try {
        await Storage.remove(file).then((res) => {
          setUpdatingBlog(false);
        });
      } catch (e) {
        setUpdatingBlog(false);
      }
    };
  
    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
    };
  
    return (
      <>
        <Row>
          <Col>
            <label
              style={{ display: "block", fontSize: "12px", fontWeight: "bold" }}
            >
              {t("Select Post Image")}
            </label>
          </Col>
          <Col>
            <label
              style={{ display: "block", fontSize: "12px", fontWeight: "bold" }}
            >
              {t("Category")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
          </Col>
        </Row>
        <Row className="align-items-start">
          <Col>
            {!image ? (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  multiple
                  onClick={() => setShowInfo(false)}
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Image")}</p>
                  <p style={{ color: "red", display: "inline" }}> *</p>
                </label>
              </>
            ) : (
              <>
                <img
                  alt=""
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}
                  htmlFor="imgUpload"
                  src={image}
                  width={65}
                  height={65}
                  style={{ borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => {
                    onDeleteImage(image);
                    setImage("");
                  }}
                />
                <p
                  style={{
                    visibility: showInfo ? "visible" : "hidden",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  *{t("click to delete image")}*
                </p>
              </>
            )}
          </Col>
          <Col className="mt-1">
            <Select
              value={{ label: item.category, value: item.categoryId }}
              options={options}
              onChange={({ value }) => setCategory(value)}
            />
          </Col>
        </Row>
  
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              maxLength={"256"}
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              id="title"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{ display: "block", fontSize: "12px", fontWeight: "bold" }}
            >
              {t("Description")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <ReactQuill
              modules={modules}
              value={description}
              onChange={(value) => setDescription(value)}
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                overflow: "hidden",
                height: "300px",
              }}
            />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              {t("Status")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {status
                    ? status === "active" || status === "Active"
                      ? t("Active")
                      : status === "Inactive" || status === "inactive"
                      ? t("Inactive")
                      : status
                    : t("Select")}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setStatus("Active")}>
                  {t("Active")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setStatus("Inactive")}>
                  {t("Inactive")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
  
        {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
        <Row>
          {/* <Col>
            <ButtonToggle
              onClick={draftBlog}
              disabled={draftLoading ? true : false}
              style={{
                margin: "20px 0px",
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "#fff" }}>
                {draftLoading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  "Draft"
                )}{" "}
              </span>
            </ButtonToggle>
          </Col> */}
          <Col>
            <ButtonToggle
              disabled={updatingBlog ? true : false}
              onClick={editBlog}
              style={{
                margin: "20px 0px",
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "#fff" }}>
                {updatingBlog ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  t("Update")
                )}{" "}
              </span>
            </ButtonToggle>
          </Col>
        </Row>
      </>
    );
}


