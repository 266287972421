import React from "react";
import { v4 as uuid } from "uuid";
import {
  ButtonToggle
} from "reactstrap";
import {
  deleteBlog,
} from "../../../api/Blogs/api";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

export default function DeleteBlogModal({ id, toggle }) {
    const { t, i18n } = useTranslation();
  
    const removeBlog = () => {
      deleteBlog(id)
        .then((res) => {
          toggle(true);
        })
        .catch((err) => {
        });
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonToggle
          onClick={removeBlog}
          style={{
            margin: "20px 0px",
            backgroundColor: "#E65B65",
            borderWidth: 0,
            marginRight: "10px",
          }}
        >
          {t("Yes")}
        </ButtonToggle>
  
        <ButtonToggle
          onClick={toggle}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
          }}
        >
          {t("No")}
        </ButtonToggle>
      </div>
    );
}


