import instance from "./axios";

export const fetchDomains = async (page = 1, limit = 10, name = "", status = "") => {
  return instance
    .get(`/services?limit=${limit}&page=${page}&status=${status}&name=${name}`)
    .then((res) => res);
};

export const addDomain = async (data) => {
  return instance.post(`/services`, data).then((res) => res);
};

export const deleteDomain = async (id) => {
  return instance.delete(`/services?id=${id}`).then((res) => res);
};

export const updateDomain = async (id, data) => {
  return instance.put(`/services?id=${id}`, data).then((res) => res);
};
