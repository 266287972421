import React, { useState, useEffect } from "react";
import { Row, Col, Table, Modal, Spinner } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import noDataFound from "../../Assets/images/nodata.png";

import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import {
  getShopCenters,
} from "../../../api/ShopCenters/api";
import DetailModal from "./DetailModal";
import DeleteModal from "./DeleteModal";
import CreateAdModal from "./CreateAdModal";


const ShopCenters = () => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [isRefresh, setIsRefresh] = useState(false);

  // detail modal
  const [detailModal, setDetailModal] = useState(false);
  const toggleDetailModal = () => {
    setDetailModal(!detailModal);
  };
  const [currItem, setCurrItem] = useState(null);
  // del modal
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const [currItemId, setCurrItemId] = useState("");
  const toggle = () => {
    setModal(!modal);
  };
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    getShoppingCenters();
  }, []);

  const getShoppingCenters = () => {
    setLoading(true);
    // axios
    //   .get(`${baseUrl}/shopping-centers?name=&limit=&page=`)
    getShopCenters()
      .then((res) => {
        setTableData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <span className="spinnerContainer">
        <Spinner animation="border" variant="primary" />
      </span>
    );
  }

  return (
    <div className="">
      <div className="financesHeader">
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: "20px",
            paddingRight: "20px",
          }}
        >
          <Col
            xl={3}
            lg={3}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div>
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Shop Center")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <div className="d-grid">
              <Button
                onClick={() => {
                  toggle();
                }}
                type="button"
                style={{
                  backgroundColor: `${blue}`,
                  color: "#fff",
                  border: "none",
                }}
              >
                {t("Create")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {/* Table Content */}
      <Table
        style={{
          cursor: "pointer",
          marginTop: "25px",
          backgroundColor: "#f7f7f7",
          borderRadius: "25px",
        }}
        borderless={true}
        hover
      >
        <thead>
          <tr>
            <th style={{ padding: "20px", fontSize: "16px" }}>{t("Name")}</th>
            <th style={{ padding: "20px", fontSize: "16px" }}>{t("Image")}</th>
            <th style={{ padding: "20px", fontSize: "16px" }}>
              {t("Registration")}
            </th>
            <th style={{ padding: "20px", fontSize: "16px" }}>
              {t("Description")}
            </th>
            <th style={{ padding: "20px", fontSize: "16px" }}>
              {t("Address")}
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr
              onClick={() => {
                setCurrItem(item);
                toggleDetailModal();
              }}
              style={{
                backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                fontSize: "12px",
                color: "#4f4f4f",
                fontFamily: "sans-serif",
              }}
            >
              <td style={{ padding: "20px" }}>{item.name}</td>
              <td style={{ padding: "20px" }}>
                <img
                  src={item.image}
                  width={65}
                  height={65}
                  alt="logo"
                  style={{ borderRadius: "8px", cursor: "pointer" }}
                />
              </td>{" "}
              <td style={{ padding: "20px" }}>{item.regNumber}</td>
              <td style={{ padding: "20px" }}>{item.description}</td>
              <td style={{ padding: "20px" }}>{item.address}</td>
              <td
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrItemId(item._id);
                  toggleDeleteModal();
                }}
                style={{
                  padding: "20px",
                  color: "red",
                  textDecoration: "underline",
                }}
              >
                {t("Delete")}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {tableData.length === 0 && (
        <div style={{ textAlign: "center" }}>
          <img alt="" src={noDataFound} width="200px" />
          <p>{t("No data available")}</p>
        </div>
      )}

      {/* add shop-center modal */}
      <Modal show={modal} onHide={toggle}>
        <AiFillCloseCircle
          onClick={toggle}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "red",
            cursor: "pointer",
          }}
          size={"1.5rem"}
        />
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("New Shop Center")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <CreateAdModal
            toggle={toggle}
            getShoppingCenters={getShoppingCenters}
          />
        </Modal.Body>
      </Modal>

      {/* detail modal */}
      <Modal show={detailModal} onHide={toggleDetailModal}>
        <AiFillCloseCircle
          onClick={toggleDetailModal}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "red",
            cursor: "pointer",
          }}
          size={"1.5rem"}
        />
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Shop Center Details")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <DetailModal
            toggle={toggleDetailModal}
            getShoppingCenters={getShoppingCenters}
            data={currItem}
          />
        </Modal.Body>
      </Modal>

      {/* delete modal */}
      <Modal size="sm" centered show={deleteModal} onHide={toggleDeleteModal}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Are you sure to delete?")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <DeleteModal
            toggle={toggleDeleteModal}
            currItemId={currItemId}
            getShoppingCenters={getShoppingCenters}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShopCenters;
