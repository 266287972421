import React, { useState } from "react";
import "./Transactions.scss";
import { Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { updateTransaction } from "../../../api/orders/orders";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TransactionInvoice from "./TransactionInvoice";
import { useTranslation } from "react-i18next";

const TransactionDetailsModal = ({ item, toggle }) => {
  const { t, i18n } = useTranslation();
  const [deliveryCode, setDeliveryCode] = useState("");
  const [status, setStatus] = useState(item?.orderStatusDetail);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const updateOrder = () => {
    
    const body = {};

    if (deliveryCode !== "") {
      body.deliveryCode = deliveryCode;
    }

    if (status !== item.orderStatusDetail) {
      body.status = status;
    }

    setLoading(true);
    updateTransaction(item, body)
      .then((res) => {
      
        setLoading(false);
        if (res.data.success === false) {
          setError(res.data.message);
        } else {
          toggle(true);
          setError("");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // const updateOrder = () => {
  //   const body = {};

  //   if (deliveryCode !== "") {
  //     body.deliveryCode = deliveryCode;
  //   }

  //   if (status !== item.orderStatusDetail.orderStatus) {
  //     body.status = status;
  //   }

  //   setLoading(true);
  //   axios
  //     .put(`${baseUrl}/orders/history?orderId=${item._id}`, body)
  //     .then((res) => {
  //       setLoading(false);
  //       
  //       if (res.data.success === false) {
  //         setError(res.data.message);
  //       } else {
  //         toggle(true);
  //         setError("");
  //       }
  //     })
  //     .catch((err) => {
  //       
  //       setLoading(false);
  //     });
  // };

  return (
    <div>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Date & Time")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Date(item?.createdAt).toLocaleDateString()}
            {" - "}
            {new Date(item?.createdAt).toLocaleTimeString()}

          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Transaction ID")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{item?.transactionId}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Name")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{`${
            item?.customerName && item?.customerName
          }`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Phone Number")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>{`${
            item?.customerPhone && item?.customerPhone
          }`}</span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Address")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item?.shippingAddress ? item?.shippingAddress : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Client Province")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0 .8rem" }}>
            {item?.customerProvince ? item?.customerProvince : ""}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Amount")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item?.totalPrice)} AKZ
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Delivery Code")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
          {
              item.deliveryMethod === "Seller Delivery"
                ? item?.deliveryDetails?.deliveryCode
                : item?.deliveryDetail[0]?.deliveryCode
            }
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Status")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {/* <UncontrolledDropdown style={{ width: "50%" }}>
              <DropdownToggle
                style={{
                  marginTop: "4px",
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {status?.charAt(0).toUpperCase() + status?.slice(1).toLowerCase()}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setStatus("DELIVERED")}>Delivered</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            {item.status?.charAt(0).toUpperCase() + item.status?.slice(1).toLowerCase()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: "flex", justifyContent: "left" }}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Ordered Products")}
          </span>
        </Col>
        {item?.products &&
          item?.products.map((prod, index) => {
            return (
              <Row key={index} style={{ justifyContent: "left" }}>
                <Col md={2}>
                  <img
                    alt={""}
                    style={{ marginRight: "10px" }}
                    src={prod.image}
                    width={67}
                    height={67}
                  />
                </Col>
                <Col md={6}>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#333",
                          fontSize: "0.75rem",
                        }}
                      >
                        {t("Name")}
                      </span>
                    </Col>
                    <Col>
                      <span>{prod.name}</span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#333",
                          fontSize: "0.75rem",
                        }}
                      >
                        {t("Delivery Name")}
                      </span>
                    </Col>
                    <Col>
                      <span>{prod?.deliveryZone?.title}</span>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#333",
                          fontSize: "0.75rem",
                        }}
                      >
                        Pickup Point
                      </span>
                    </Col>
                    <Col>
                      <span>{prod.deliveryMethod}</span>
                    </Col>
                  </Row>
                  
                </Col>
                
              </Row>
            );
          })}
      </Row>
      {item?.orderStatusDetail === "REFUND" || item?.orderStatusDetail === "COMPLETED"
        ? ""      
        : <Row style={{ marginTop: "1%", justifyContent: "left" }}>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            {t("Delivery Code")}
          </span>
        </Col>
        <Col md={4}>
          <input
            className="inputField"
            style={{ width: "100%" }}
            value={deliveryCode}
            onChange={(e) => setDeliveryCode(e.target.value)}
            type="number"
            id="Code"
            maxLength="8"
          />
          {error !== "" && (
            <p style={{ color: "red", fontSize: "14px", textAlign: "left" }}>
              *{t(`${error}`)}
            </p>
          )}
        </Col>
        </Row>
      }
      { item?.orderStatusDetail=== "REFUND" || item?.orderStatusDetail === "COMPLETED"
        ? ""
        : <Row>
        <Col md={6}>
          <Button
            onClick={(e) => updateOrder(e)}
            disabled={loading}
            style={{
              marginTop:"10px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner
                animation="border"
                size="sm"
                style={{ color: "white", padding: "9px" }}
              />
            ) : (
              t("Update")
            )}
          </Button>
        </Col>
          </Row>
      }
    <Row >
      <Col md={6}>
        <TransactionInvoice item={item} />
      </Col>
    </Row>
   
      {/* <Row style={{ marginTop: "1%", justifyContent: "left" }}>
        <Col md={6}>
          <span
            style={{
              fontWeight: "bold",
              color: "#333",
              fontSize: "1rem",
            }}
          >
            Delivery Code
          </span>
        </Col>
        <Col md={4}>
          <input
            className="inputField"
            style={{ width: "100%" }}
            value={deliveryCode}
            onChange={(e) => setDeliveryCode(e.target.value)}
            type="number"
            id="Code"
            maxLength="8"
          />
          {error !== "" && (
            <p style={{ color: "red", fontSize: "14px", textAlign: "left" }}>*{error}</p>
          )}
        </Col>
      </Row> */}

      {/* <Row>
        <Col md={6}>
          <Button
            onClick={(e) => updateOrder(e)}
            disabled={loading}
            style={{
              // margin: "20px 0px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <Spinner animation="border" size="sm" style={{ color: "white", padding: "9px" }} />
            ) : (
              "Update"
            )}
          </Button>
        </Col>
      </Row> */}
      <Row>
        <Col md={6}>{/* <TransactionInvoice item={item} /> */}</Col>
      </Row>
    </div>
  );
};

export default TransactionDetailsModal;
