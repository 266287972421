import instance from "./axios";

// export const getStatCards = async () => {
//   try {
//     return instance.get(`/kpis/sellers-balance?sellerId=`);
//   } catch (err) {
//     return err;
//   }
// };

export const getPaymentCycleData = (page = 1, limit = 100,startDate, endDate) => {
  try {
    return instance.get(`/admin/payment-cycle?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`);
  } catch (err) {
    return err;
  }
};
