import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import noDataFound from "../../Assets/images/nodata.png";
import {
  fetchDomains,
} from "../../../api/Services/api";
import Pages from "../../Components/Pagination/Pagination";
import { useDebounce } from "use-debounce";
import UpdateModal from "./UpdateModal";
import AddModal from "./AddModal";
import DeleteModal from "./DeleteModal";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [debounceName, setDebounceName] = useState("");
  const [name] = useDebounce(debounceName, 1000);
  const [noOfPages, setNoOfPages] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [status, setStatus] = useState("");
  const toggle = () => setModal(!modal);
  const deleteToggle = () => setDeleteModal(!deleteModal);
  const updateToggle = () => setUpdateModal(!updateModal);
  const [loading, setLoading] = React.useState(true);
  const [deleteId, setDeleteId] = useState("");
  const [updateItem, setUpdateItem] = useState({});

  React.useEffect(() => {
    getDomains();
  }, [activePage, limit, name, status]);

  const getDomains = () => {
    setLoading(true);

    fetchDomains(activePage, limit, name, status)
      .then((res) => {
        setTableData(res.data.data);
        setLoading(false);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          const tempPages = [];
          num = Math.ceil(num);
          for (let i = 0; i < num; i++) {
            tempPages.push(i + 1);
          }
          setNoOfPages(tempPages);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // if (loading) {
  //   return (
  //     <span className="spinnerContainer">
  //       <Spinner animation="border" variant="primary" />
  //     </span>
  //   );
  // }

  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader">
        <Row
          style={{
            // display: "flex",
            // flexDirection: "row",
            // justifyContent: "space-between",
            width: "100%",
            paddingTop: "20px",
            paddingRight: "20px",
          }}
        >
          <Col
            xl={5}
            lg={5}
            style={{
              justifyContent: "left",
              padding: "0px",
              paddingLeft: "24px",
              margin: "0px",
            }}
          >
            <div>
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Domains")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <div class="input-group rounded">
              <UncontrolledDropdown style={{ width: "100%" }}>
                <DropdownToggle
                  style={{
                    backgroundColor: "#fff",
                    borderColor: "#CED4DA",
                    color: "#333",
                    fontSize: "0.9rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  caret
                >
                  <span className="showAllSpan">
                    {status === "" ? t("Show All") : status}
                  </span>
                </DropdownToggle>
                <DropdownMenu style={{ marginTop: "5px" }}>
                  <DropdownItem onClick={() => setStatus("")}>
                    {t("Show All")}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => setStatus("Active")}>
                    {t("Active")}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => setStatus("Inactive")}>
                    {t("Inactive")}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={debounceName}
                onChange={(e) => setDebounceName(e.target.value)}
              />
            </div>
          </Col>
          {/* <Col xl={2} lg={2}>
            <div className="d-grid">
              <Button
                onClick={() => toggle()}
                type="button"
                style={{
                  backgroundColor: `${blue}`,
                  color: "#fff",
                  border: "none",
                }}
              >
                {t("Add")}
              </Button>
            </div>
          </Col> */}
        </Row>
      </div>

      {/* Table Content */}
      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "16px" }}>{t("Name")}</th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Description")}
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("State")}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr
                onClick={() => {
                  setUpdateItem(item);
                  updateToggle();
                }}
                style={{
                  backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                  fontSize: "12px",
                  color: "#4f4f4f",
                  fontFamily: "sans-serif",
                }}
              >
                <td style={{ padding: "20px" }}>{item.name && item.name}</td>
                <td style={{ padding: "20px" }}>
                  {item.description && item.description}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.status && item.status}
                </td>
                <td style={{ padding: "20px" }}>
                  <span
                    style={{ color: "red", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteId(item._id);
                      deleteToggle();
                    }}
                  >
                    {t("Delete")}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {tableData.length <= 0 && (
        <div className="noDataContainer" style={{ textAlign: "center" }}>
          <img
            alt=""
            src={noDataFound}
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "15%",
            }}
          />
          <p>{t("No data available")}</p>
        </div>
      )}

      <div style={{ height: "100px" }}>
        <Row style={{ display: "block", paddingBottom: "0px" }}>
          <div>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* Pagination */}
                <Pages
                  noOfPages={noOfPages}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              </div>
              {/* No of products on page toggler */}
              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <UncontrolledDropdown style={{ width: "100%" }}>
                  <DropdownToggle
                    style={{
                      backgroundColor: "#fff",
                      color: "#333",
                      borderColor: "#CED4DA",
                      fontSize: "0.9rem",
                      width: "100%",
                    }}
                    caret
                  >
                    <span
                      style={{
                        color: "#333",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("Items per page")}
                    </span>
                    <span
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      {limit}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu style={{ marginTop: "5px" }}>
                    <DropdownItem onClick={() => setLimit(1)}>1</DropdownItem>
                    <DropdownItem onClick={() => setLimit(5)}>5</DropdownItem>
                    <DropdownItem onClick={() => setLimit(10)}>10</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </div>
        </Row>
      </div>

      <Modal show={modal} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Add Domain")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <AddModal toggle={toggle} getDomains={getDomains} />
        </Modal.Body>
      </Modal>

      <Modal show={updateModal} onHide={updateToggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Update Domain")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <UpdateModal
            item={updateItem}
            toggle={updateToggle}
            getDomains={getDomains}
          />
        </Modal.Body>
      </Modal>

      <Modal size="sm" centered show={deleteModal} onHide={deleteToggle}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px" }}>
            {t("Confirm Delete")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <DeleteModal
            id={deleteId}
            toggle={deleteToggle}
            getDomains={getDomains}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Services;
