import instance from "./axios";

export const getUserHistory = async (
  limit,
  activePage,
  startDate,
  endDate,
  id
) => {
  try {
    return instance.get(
      `/transactions/history/mbl?limit=${limit}&page=${activePage}&startDate=${startDate}&endDate=${endDate}&customerId=${id}`
    );
  } catch (err) {
    return err;
  }
};
