import instance from "./axios";


export const getDirectPayTransactions = async (
  limit,
  activePage,
  selectedMethod,
  selectedStatus,
  startDate,
  endDate
) => {
  try {
    return instance.get(
      `/pay/purchase/product?limit=${limit}&page=${activePage}&paymentType=Direct Pay&paymentMethod=${selectedMethod}&paymentStatus=${selectedStatus}&startDate=${startDate}&endDate=${endDate}`
    );
  } catch (err) {
    return err;
  }
};
export const getQrPaymentTransactions = async (
  limit,
  activePage,
  selectedMethod,
  selectedStatus,
  startDate,
  endDate
) => {
  try {
    return instance.get(
      `/pay/purchase/product?limit=${limit}&page=${activePage}&paymentType=QR Pay&paymentMethod=${selectedMethod}&paymentStatus=${selectedStatus}&startDate=${startDate}&endDate=${endDate}`
    );
  } catch (err) {
    return err;
  }
};
