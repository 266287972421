import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";

import "./Kpi.scss";
import StatsCard from "../Finances/StatsCard";
import { baseUrl } from "../../../config";
import { useTranslation } from "react-i18next";
import {
  totalSales,
  avgTransactions,
  retrunRate,
  totalProducts,
  deliveryProducts,
  totalOrders,
  totalPayTransactions,
  totalSellers,
  totalCustomers,
  totalBayqiCommission,
} from "../../../api/Kpi/api";

const Kpi = () => {
  const { t, i18n } = useTranslation();
  const [timePeriod, setTimePeriod] = React.useState("Today");
  const [loading, setLoading] = React.useState(true);
  const [cardData, setCardData] = React.useState([]);
  const [chart, setChart] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  // const [cards, setCards] = React.useState([
  //   {
  //     title: "TRAFFIC",
  //     amount: "550,897",
  //     increase: true,
  //     value: 15.45,
  //     when: "last month",
  //   },
  //   {
  //     title: "NEW USERS",
  //     amount: "5,356",
  //     increase: true,
  //     value: 12.48,
  //     when: "last week",
  //   },
  //   {
  //     title: "SALES",
  //     amount: "1,234",
  //     increase: true,
  //     value: 20,
  //     when: "yesterday",
  //   },
  //   {
  //     title: "PERFORMANCE",
  //     amount: "14,965",
  //     increase: true,
  //     value: 44,
  //     when: "yesterday",
  //   },
  // ]);
  const dynamicColors = () => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  };

  const chartData = React.useMemo(() => {
    return {
      labels: chart.map((item) => {
        return item._id;
      }),
      datasets: [
        {
          label: "",
          data: chart.map((item) => {
            return item.count;
          }),
          hoverOffset: 4,
          backgroundColor: chart.map((item) => {
            return dynamicColors();
          }),
        },
      ],
    };
  }, [chart]);

  const cards = React.useMemo(() => {
    const cards = cardData.map((card) => {
      return {
        title: card.title,
        amount: card.amount,
        chartData: card.chartData,
        setChart: setChart,
        currency: card.currency,
      };
    });

    return cards;
  }, [cardData]);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      totalSales(startDate, endDate),
      avgTransactions(startDate, endDate),
      retrunRate(startDate, endDate),
      totalProducts(startDate, endDate),
      deliveryProducts(startDate, endDate),
      totalOrders(startDate, endDate),
      totalPayTransactions(startDate, endDate),
      totalSellers(startDate, endDate),
      totalCustomers(startDate, endDate),
      totalBayqiCommission(startDate, endDate),
    ]).then((results) => {

      const temp = results.map((item) => {
        return {
          title: item.data.message,
          amount: item.data.totalRecord || item.data.amount,
          chartData: item.data.result,
          currency: item.data.currency,
        };
      });

      setCardData(temp);
      setLoading(false);
    });
  }, [startDate, endDate]);
  if (loading) {
    return (
      <span className="spinnerContainer">
        <Spinner animation="border" variant="primary" />
      </span>
    );
  }

  return (
    <div>
      <div className="financesHeader pt-2">
        <Row style={{ width: "100%", justifyContent: "end" }}>
        <Col xl={2} lg={2} style={{ padding: "0px 8px" }}>
          <Row>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Start Date")}
            </span>
          </Row>
          <Row style={{ width: "100%" }}>
            <input
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ fontSize: "0.9rem" }}
              type="date"
              class="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </Row>
        </Col>
        <Col xl={2} lg={2} style={{ padding: "0px 8px" }}>
          <Row>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {"End Date"}
            </span>
          </Row>
          <Row style={{ width: "100%" }}>
            <input
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              style={{ fontSize: "0.9rem" }}
              type="date"
              class="form-control rounded"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
            />
          </Row>
        </Col>  
          <Col
            className="col-12 col-sm-6 col-md-3"
            style={{ visibility: "hidden" }}
          >
            <p className="rangeTitle">{t("Range")}</p>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span className="showAllSpan">{timePeriod}</span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem onClick={() => setTimePeriod("Today")}>
                  {t("Today")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setTimePeriod("Last Week")}>
                  {t("Last Week")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setTimePeriod("Last Month")}>
                  {t("Last Month")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setTimePeriod("Last Year")}>
                  {t("Last Year")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </div>

      <Col>
        <Row>
          <div className="mt-4">
            {cards.map((card) => (
              <StatsCard {...card} />
            ))}
          </div>
        </Row>
          
        <Row>
          {chart.length > 0 && (
            <div style={{ marginTop: "30px" }}>
              <div className="statsCard">
                <Doughnut data={chartData} />
              </div>
            </div>
          )}
        </Row>
      </Col>
      {/* Stats Cards */}
    </div>
  );
};

export default Kpi;
