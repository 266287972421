import NavBar from "../Components/NavBar/NavBar";
import React, { useState } from "react";
import SideDrawer from "../Components/SideDrawer/SideDrawer";
import "../Components/SideDrawer/SideDrawer.css";
import { useAppContext } from "../lib/contextLib";
import { Redirect } from "react-router-dom";

function HeaderWithDrawer({ children }) {
  const { isAuthenticated } = useAppContext();

  const [isOpen, setIsOpen] = useState(true);
  const toggleDrawer = () => setIsOpen(!isOpen);

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: "/login" }} />;
  }
  return (
    <div>
      <NavBar toggleDrawer={toggleDrawer} />
      <SideDrawer isOpen={isOpen}>{children}</SideDrawer>
    </div>
  );
}

export default HeaderWithDrawer;
