import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from "react-select";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  updateAdminDelivery,
} from "../../../api/BayqiLogistics/api";
import { useTranslation } from "react-i18next";

export default function UpdateDeliveryModal({ toggle, item }) {
    const { t, i18n } = useTranslation();
    const [title, setTitle] = useState(item.title);
    const [country, setCountry] = useState(item.country);
    const [province, setProvince] = useState(item.province);
    const [status, setStatus] = useState(item.status);
    const [cost, setCost] = useState(item.cost);
    const [est, setEst] = useState(item.estimateTime);
  
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
  
    const getEst = (val) => {
      const retValues = [
        { value: "0", label: t("Same day") },
        { value: "1", label: t("1 day") },
        { value: "2", label: t("2 days") },
        { value: "3", label: t("3 days") },
        { value: "4", label: t("4 days") },
        { value: "5", label: t("5 days") },
      ];
  
      return retValues[val];
    };
  
    const editDelivery = () => {
      setLoading(true);
      if (
        title !== item.title ||
        status !== item.status ||
        cost !== item.cost ||
        est !== item.estimateTime ||
        country !== item.country ||
        province !== item.province
      ) {
        if (title !== "") {
          updateAdminDelivery(item._id, {
            title: title,
            country,
            cost,
            estimateTime: est,
            province,
            status: status,
          })
            .then((res) => {
              toggle(true);
              setLoading(false);
            })
            .catch((res) => {
              setLoading(false);
            });
        } else {
          setError(t("(*) fields are mandatory"));
          setLoading(false);
        }
      } else {
        setError(t("Change Values to Update"));
        setLoading(false);
      }
    };
  
    return (
      <div>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Title")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
              className="inputField"
              id="Title"
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Country")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <CountryDropdown
              defaultOptionLabel="Select a country"
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={country}
              onChange={(val) => {
                setCountry(val);
              }}
            />
            {/* <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Country"
              className="inputField"
              id="Title"
            /> */}
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col className="col-12">
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Province")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <RegionDropdown
              blankOptionLabel="No country selected"
              defaultOptionLabel="Now select a region"
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              country={country}
              value={province}
              onChange={(val) => {
                setProvince(val);
              }}
            />
            {/* <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              placeholder="Province"
              className="inputField"
              id="Title"
            /> */}
          </Col>
        </Row>
        <Row>
          <Col className="sm-col-12 md-col-6">
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Cost")}
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              placeholder="0.00 AKZ"
              className="inputField"
              id="Cost"
              type="number"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label
              className="inpLblTxt"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Delivery time")} (EST)
              <p style={{ color: "red", display: "inline" }}>*</p>
            </label>
            <Select
              value={getEst(est)}
              options={[
                { value: "0", label: "Same day" },
                { value: "1", label: "1 day" },
                { value: "2", label: "2 days" },
                { value: "3", label: "3 days" },
                { value: "4", label: "4 days" },
                { value: "5", label: "5 days" },
              ]}
              onChange={({ value }) => setEst(value)}
            />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              {t("Status")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {status
                    ? status === "enable" ||
                      status === "Enable" ||
                      status === "Active"
                      ? t("Active")
                      : status === "disable" ||
                        status === "Disable" ||
                        status === "Inactive"
                      ? t("Inactive")
                      : status
                    : t("Select")}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setStatus("Active")}>
                  {t("Active")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setStatus("Inactive")}>
                  {t("Inactive")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
  
        {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
        <ButtonToggle
          onClick={editDelivery}
          disabled={loading ? true : false}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "#fff" }}>
            {loading ? <Spinner size="sm" animation="border" /> : t("Update")}{" "}
          </span>
        </ButtonToggle>
      </div>
    );
}

