import React, { useState, useMemo } from "react";
import { Row, Col, Table, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import noDataFound from "../../Assets/images/nodata.png";
import StatsCard from "../Finances/StatsCard";
import { baseUrl } from "../../../config";
// import StatsCard from "./StatsCard";
// import { getTelopayCardData } from "../../../common/api";
import Pages from "../../Components/Pagination/Pagination";
import { useDebouncedCallback } from "use-debounce";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { getTelopayHistory, getTelopayCardData } from "../../../api/Telopay/api";
import ModalLayout from "./ModalLayout";


const Telopay = () => {
  const { t, i18n } = useTranslation();
  // const [cards, setCards] = React.useState([
  //   {
  //     title: "RECHARGE",
  //     amount: "  350,897 AKZ",
  //     increase: true,
  //     value: 3.45,
  //     when: "last month",
  //   },
  //   {
  //     title: "TV INTERNET",
  //     amount: "2,356 AKZ",
  //     increase: false,
  //     value: 3.45,
  //     when: "last week",
  //   },
  //   {
  //     title: "INSURANCE",
  //     amount: "924 AKZ",
  //     increase: false,
  //     value: 1.1,
  //     when: "yesterday",
  //   },
  // ]);
  const [cardData, setCardData] = React.useState([]);
  const [salesData, setSalesData] = React.useState([]);
  const [comissionData, setcomissionData] = React.useState([]);
  const cards = useMemo(() => {
    const cards = [
      cardData.some((element) => element._id === "RECHARGE")
        ? {
            title: cardData.find((element) => element._id === "RECHARGE")._id,
            amount: cardData.find((element) => element._id === "RECHARGE")
              .TotalSum,
          }
        : {
            title: "RECHARGE",
            amount: 0,
          },

      cardData.some((element) => element._id === "TV INTERNET")
        ? {
            title: cardData.find((element) => element._id === "TV INTERNET")._id,
            amount: cardData.find((element) => element._id === "TV INTERNET")
              .TotalSum,
          }
        : {
            title: "TV INTERNET",
            amount: 0,
          },

      // cardData.some((element) => element._id == "BAYQI TELOPAY COMMISSION")
      //   ? {
      //       title: cardData.find(
      //         (element) => element._id == "BAYQI TELOPAY COMMISSION"
      //       )._id,
      //       amount: cardData.find(
      //         (element) => element._id == "BAYQI TELOPAY COMMISSION"
      //       ).TotalSum,
      //     }
      //     : {
      //       title: "BAYQI TELOPAY COMMISSION",
      //       amount: 0,
      //     },
      

      {
        title: "BAYQI TELOPAY COMMISSION",amount: comissionData
      },
        
      // cardData.some((element) => element._id == null)
      //   ? {
      //       title: cardData.find((element) => element._id == null)._id,
      //       amount: cardData.find((element) => element._id == null)
      //         .TotalSum,
      //     }
      //   : {
      //       title: "TOTAL SALES",
      //       amount: 0,
      //     },
       
       
        {
          title: "TOTAL SALES",amount: salesData
        }
        

        
    ];

    // cardData.map((card) => {
    //   return {
    //     title: card._id,
    //     amount: card.TotalSum,
    //   };
    // });

    return cards;
  }, [cardData,salesData,comissionData]);

  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [noOfPages, setNoOfPages] = useState([]);
  const [debouncedName, setDebounceName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("BAYQI");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [name, setName] = useState("");
  const [service, setService] = useState("");
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    fetchCardData();
    loadHistory();
  }, [limit, activePage, paymentMethod, service, startDate, endDate]);

  const fetchCardData = () => {
    getTelopayCardData()
      .then((res) => {
        setCardData(res.data.result);
        setSalesData(res.data.totalSales[0].totalSales || '0');
        setcomissionData(res.data.bayQiCommission[0].TotalCommission || '0')
      })
      .catch((err) => {
      });
  };

  const debounced = useDebouncedCallback((value) => {
    setDebounceName(value);
    setActivePage(1);
  }, 1000);

  const loadHistory = () => {
    // const url = `${baseUrl}/telopay/purchaseItem?limit=${limit}&page=${activePage}&paymentMethod=${paymentMethod}&service=${service}&startDate=${startDate}&endDate=${endDate}`;
    setLoading(true);
    
    // axios
    //   .get(url)
    getTelopayHistory(limit, activePage, paymentMethod, service, startDate, endDate)
      .then((res) => {
        setTableData(res.data.data);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/telopay/1/telopay/purchaseItem/export?paymentMethod=${paymentMethod}&type=${debouncedName}&startDate=${startDate}&endDate=${endDate}`,
        ""
      );
    } catch (e) {
    }
  };

  // React.useEffect(() => {
  //   loadHistory();
  // }, [limit, activePage, paymentMethod, debouncedName, startDate, endDate]);

  // if (loading) {
  //   return (
  //     <span className="spinnerContainer">
  //       <Spinner animation="border" variant="primary" />
  //     </span>
  //   );
  // }
  return (
    <div>
      {/* Header */}
      <div className="financesHeader">
        <Row style={{ width: "100%" }}>
          <Col
            xl={2}
            lg={2}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: "1.5rem",
                  marginLeft: "1rem",
                }}
              >
                {t("Telopay")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  marginTop: "0px",
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "25%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Start Date")}
            </span>
            <div class="input-group rounded">
              <input
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>

            <div class="input-group rounded">
              <input
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Service")}
            </span>
            {/* // TODO: change this debounce text to dropdown */}
            {/* <div class="input-group rounded">
              <input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  debounced(e.target.value);
                }}
                type="search"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            </div> */}
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {service
                  ? service?.charAt(0).toUpperCase() +
                    service?.slice(1).toLowerCase()
                  : "Show All"}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setService("")}>
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem onClick={() => setService("Unitel")}>
                  Unitel
                </DropdownItem>
                <DropdownItem onClick={() => setService("Movicel")}>
                  Movicel
                </DropdownItem>
                <DropdownItem onClick={() => setService("Telo")}>
                  Telo
                </DropdownItem>
                <DropdownItem onClick={() => setService("ZAP")}>
                  ZAP
                </DropdownItem>
                <DropdownItem onClick={() => setService("DSTV")}>
                  DSTV
                </DropdownItem>
                <DropdownItem onClick={() => setService("Net One")}>
                  Net One
                </DropdownItem>
                <DropdownItem onClick={() => setService("Fidelidade")}>
                  Fidelidade
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                {paymentMethod
                  ? paymentMethod?.charAt(0).toUpperCase() +
                    paymentMethod?.slice(1).toLowerCase()
                  : t("Show All")}
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setPaymentMethod("BAYQI")}>
                  Bayqi
                </DropdownItem>
                <DropdownItem onClick={() => setPaymentMethod("EXPRESS")}>
                  Express
                </DropdownItem>
                <DropdownItem onClick={() => setPaymentMethod("PAGAQI")}>
                  Pagaqi
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </div>

      {/* Stats Cards */}
      <div className="mt-4">
        {cards.map((card) => (
          <StatsCard {...card} />
        ))}
      </div>

      {/* Table Content */}
      {loading ? (
        <span
          className="spinnerContainer"
          style={{ height: "50vh", width: "100%" }}
        >
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <>
          <Table
            style={{
              cursor: "pointer",
              marginTop: "25px",
              backgroundColor: "#f7f7f7",
              borderRadius: "25px",
            }}
            borderless={true}
            hover
          >
            <thead>
              <tr>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Transaction Id")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Date")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Name")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Service")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Payment Method")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Amount")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("Commission")}
                </th>
                <th style={{ padding: "20px", fontSize: "14px" }}>
                  {t("State")}
                </th>
              </tr>
            </thead>
            {tableData.length > 0 ? (
              <tbody>
                {tableData.map((item, index) => (
                  <tr
                    // onClick={toggle}
                    key={index}
                    style={{
                      backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                      fontSize: "12px",
                      color: "#4f4f4f",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <td style={{ padding: "20px" }}>{item.transactionId}</td>
                    <td style={{ padding: "20px" }}>
                      {new Date(item.createdAt).toLocaleDateString()}
                      {" - "}
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </td>
                    <td
                      style={{ padding: "20px" }}
                    >{`${item.customerDetail.firstName} ${item.customerDetail.surName}`}</td>
                    <td style={{ padding: "20px" }}>{item.type}</td>
                    <td style={{ padding: "20px" }}>
                      {item.paymentMethod?.charAt(0).toUpperCase() +
                        item.paymentMethod?.slice(1).toLowerCase()}
                    </td>
                    <td
                      style={{ padding: "20px" }}
                    >{`${item.amount.toLocaleString()} AKZ`}</td>
                    <td style={{ padding: "20px" }}>
                      {item?.bayqiCommission
                        ? `${item?.bayqiCommission} AKZ`
                        : "..."}
                    </td>
                    <td style={{ padding: "20px" }}>{t("Activated")}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={1000}>
                    <div className="noDataContainer">
                      <img
                        alt="no data found"
                        src={noDataFound}
                        width="200px"
                      />
                      <p>{t("No data available")}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>

          {tableData.length > 0 && (
            <div style={{ height: "100px" }}>
              <Row style={{ display: "block", paddingBottom: "0px" }}>
                <div>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {/* Pagination */}
                      <Pages
                        noOfPages={noOfPages}
                        activePage={activePage}
                        setActivePage={setActivePage}
                      />
                    </div>
                    {/* No of products on page toggler */}
                    <div
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle
                          style={{
                            backgroundColor: "#fff",
                            color: "#333",
                            borderColor: "#CED4DA",
                            fontSize: "0.9rem",
                            width: "100%",
                            margin: "auto",
                          }}
                          caret
                        >
                          <span
                            style={{
                              color: "#333",
                              fontSize: "0.7rem",
                            }}
                          >
                            {t("Items per page")}
                          </span>
                          <span
                            style={{
                              marginLeft: "5px",
                              fontWeight: "bold",
                              marginRight: "5px",
                            }}
                          >
                            {limit}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ marginTop: "5px", width: "100%" }}
                        >
                          <DropdownItem
                            onClick={() => {
                              setLimit(1);
                              setActivePage(1);
                            }}
                          >
                            1
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(5);
                              setActivePage(1);
                            }}
                          >
                            5
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setLimit(10);
                              setActivePage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}
        </>
      )}
      {/* Transactions Details Modal */}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <ModalLayout />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default Telopay;
