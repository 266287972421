import React, { useState} from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import Select from "react-select";
import { useTranslation } from "react-i18next";
import {
  updateTransferStatus
} from "../../../api/BankTransferSeller/api";

export default function ModalLayout({ toggle, data, getUpdatedData }) {
    const { t, i18n } = useTranslation();
    // react-select styles
    const customStyles = {
      option: (defaultStyles) => ({
        ...defaultStyles,
        fontSize: "12px",
      }),
      singleValue: (defaultStyles) => ({
        ...defaultStyles,
        fontSize: "12px",
      }),
  
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          fontSize: "12px",
        };
      },
    };
  
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({
      value: data.paymentStatus,
      label: data.paymentStatus,
    });
    const updateStatus = () => {
      setLoading(true);
      updateTransferStatus({
        ibanNumber: data.ibanNumber,
        paymentStatus: status,
        id: data._id,
      })
        .then((res) => {
          setLoading(false);
          toggle();
          getUpdatedData();
        })
        .catch((err) => {
          setLoading(false);
        }).finally(
          ()=>{
            toggle(true);
          }
        );
    };

    return (
      <div>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Transaction Id")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>{data.transactionId}</span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Date")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {new Date(data.createdAt).toDateString()}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("IBAN")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {data.ibanNumber}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Amount")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {new Intl.NumberFormat().format(data.amountToTransfer)} AKZ
            </span>
          </Col>
        </Row>
        <Row>
  <Col md={6}>
    <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
      {t("Status")}
    </span>
  </Col>
  <Col md={4}>
    { data.paymentStatus === "PENDING" ? (
      <Select
        styles={customStyles}
        options={[
          { value: "APPROVED", label: "Approve" },
          { value: "REJECTED", label: "Rejected" },
        ]}
        onChange={({ value }) => setStatus(value)}
      />
    ) : (
      <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
        {data.paymentStatus}
      </span>
    )}
  </Col>
  <Col md={2} className={{ padding: "0px" }}>
    {data.paymentStatus === "PENDING" && (
      <button
        type="button"
        style={{
          backgroundColor: "#049EFF",
          color: "#fff",
          border: "none",
          fontSize: "12px",
          padding: "8px",
          borderRadius: "4px",
          fontWeight: "bold",
        }}
        onClick={updateStatus}
      >
        {loading ? (
          <Spinner
            size="sm"
            style={{ color: "white" }}
            animation="border"
          />
        ) : (
          t("Update")
        )}
      </button>
    )}
  </Col>
</Row>

      </div>
    );
}


// const ModalLayout = ({ toggle, data, getUpdatedData }) => {
//     const { t, i18n } = useTranslation();
//     // react-select styles
//     const customStyles = {
//       option: (defaultStyles) => ({
//         ...defaultStyles,
//         fontSize: "12px",
//       }),
//       singleValue: (defaultStyles) => ({
//         ...defaultStyles,
//         fontSize: "12px",
//       }),
  
//       placeholder: (defaultStyles) => {
//         return {
//           ...defaultStyles,
//           fontSize: "12px",
//         };
//       },
//     };
  
//     const [loading, setLoading] = useState(false);
//     const [status, setStatus] = useState("");
//     const updateStatus = () => {
//       setLoading(true);
//       updateTransferStatus({
//         ibanNumber: data.ibanNumber,
//         paymentStatus: status,
//         id: data._id,
//       })
//         .then((res) => {
//           setLoading(false);
//           toggle();
//           getUpdatedData();
//           
//         })
//         .catch((err) => {
//           setLoading(false);
//           
//         });
//     };
  
//     return (
//       <div>
//         <Row>
//           <Col md={6}>
//             <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
//               {t("Transaction Id")}
//             </span>
//           </Col>
//           <Col md={6}>
//             <span style={{ color: "#333", fontSize: "0.8rem" }}>{data._id}</span>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6}>
//             <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
//               {t("Date")}
//             </span>
//           </Col>
//           <Col md={6}>
//             <span style={{ color: "#333", fontSize: "0.8rem" }}>
//               {new Date(data.createdAt).toDateString()}
//             </span>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6}>
//             <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
//               {t("IBAN")}
//             </span>
//           </Col>
//           <Col md={6}>
//             <span style={{ color: "#333", fontSize: "0.8rem" }}>
//               {data.ibanNumber}
//             </span>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6}>
//             <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
//               {t("Amount")}
//             </span>
//           </Col>
//           <Col md={6}>
//             <span style={{ color: "#333", fontSize: "0.8rem" }}>
//               {new Intl.NumberFormat().format(data.amountToTransfer)} AKZ
//             </span>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6}>
//             <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
//               {t("Status")}
//             </span>
//           </Col>
//           <Col md={4}>
//             <Select
//               styles={customStyles}
//               options={[
//                 { value: "approved", label: "Approve" },
//                 { value: "pending", label: "Pending" },
//               ]}
//               onChange={({ value }) => setStatus(value)}
//             />
//           </Col>
//           <Col md={2} className={{ padding: "0px" }}>
//             {status !== "" && (
//               <button
//                 type="button"
//                 style={{
//                   backgroundColor: "#049EFF",
//                   color: "#fff",
//                   border: "none",
//                   fontSize: "12px",
//                   padding: "8px",
//                   borderRadius: "4px",
//                   fontWeight: "bold",
//                 }}
//                 onClick={updateStatus}
//               >
//                 {loading ? (
//                   <Spinner
//                     size="sm"
//                     style={{ color: "white" }}
//                     animation="border"
//                   />
//                 ) : (
//                   t("Update")
//                 )}
//               </button>
//             )}
//           </Col>
//         </Row>
//       </div>
//     );
//   };