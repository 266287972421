import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  Spinner,
  InputGroup,
  Toast,
} from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";

import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import uploadImg from "../../Assets/images/uploadfile.png";
import noDataFound from "../../Assets/images/nodata.png";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "react-select";
import { Storage } from "aws-amplify";
import {
  createAd,
  getAds,
  updateAdd,
  deleteAdd,
} from "../../../api/Advertisements/api";
import { v4 as uuid } from "uuid";

import Pages from "../../Components/Pagination/Pagination";
import { useTranslation } from "react-i18next";

const adPlateform = [
  { label: "Web", value: "Web" },
  { label: "Mobile", value: "Mobile" },
  { label: "Both", value: "Both" },
];
const adType = [
  { label: "Banner", value: "Banner" },
  { label: "Side", value: "Side" },
];

export default function CreateAdModal({ toggle }) {
    const { t, i18n } = useTranslation();
    // path of the uploaded image
    const [path, setPath] = useState(null);
    // current blog category
  
    // fields
    const [index, setIndex] = useState("");
    const [image, setImageUrl] = useState("");
    const [type, setType] = useState("");
    const [plateform, setPlateform] = useState("");
    const [imageUploaded, setImageUploaded] = useState(false);
  
    const [showInfo, setShowInfo] = useState(false);
  
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [draftLoading, setDraftLoading] = useState(false);
  
    // called on image upload
    const onFileChange = async (e) => {
      const filePath = URL.createObjectURL(e.target.files[0]);
      setPath(filePath);
      try {
        await Storage.put(
          `BayqiAds/${e.target.files[0].name}`,
          e.target.files[0]
        ).then((res) => {
          setImageUrl(`${process.env.REACT_APP_S3_BUCKET}/public/${res.key}`);
          setImageUploaded(true);
        });
      } catch (error) {
      }
    };
    // publishAd
    const publishAd = () => {
      setLoading(true);
      setError("");
      if (index === "" || image === "" || type === "" || plateform === "") {
        setError(t("(*) fields are mandatory."));
        setLoading(false);
      } else {
        const body = {
          index,
          image,
          type,
          platform: plateform,
          status: "active",
        };
  
        createAd(body)
          .then((res) => {
            setLoading(false);
            toggle();
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    };
  
    return (
      <>
        <Row>
          <Col>
            <label
              style={{ display: "block", fontSize: "12px", fontWeight: "bold" }}
            >
              {t("Ad Image")}
            </label>
          </Col>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Col sm={12}>
            {!path ? (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  multiple
                  onClick={() => setShowInfo(false)}
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Image")}</p>
                </label>
              </>
            ) : (
              <>
                <img
                  alt=""
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}
                  htmlFor="imgUpload"
                  src={path}
                  width={300}
                  height={200}
                  style={{ borderRadius: "8px", cursor: "pointer" }}
                  // onClick={() => setPath(null)}
                />
                {/* {!imageUploaded && <p>please wait while uploading image</p>} */}
                {/* <p
                  style={{
                    visibility: showInfo ? "visible" : "hidden",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  *click to delete image*
                </p> */}
              </>
            )}
          </Col>
        </Row>
  
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Platform")} *
            </label>
            <Select
              options={adPlateform}
              onChange={({ value }) => setPlateform(value)}
            />
          </Col>
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Type")} *
            </label>
            <Select options={adType} onChange={({ value }) => setType(value)} />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Index")} *
            </label>
            <input
              value={index}
              onChange={(e) => setIndex(e.target.value)}
              maxLength={"256"}
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              id="title"
            />
          </Col>
        </Row>
        {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
        <Row>
          <Col>
            <ButtonToggle
              disabled={loading || !imageUploaded}
              onClick={publishAd}
              style={{
                margin: "20px 0px",
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "#fff" }}>
                {loading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  t("Publish")
                )}{" "}
              </span>
            </ButtonToggle>
          </Col>
        </Row>
      </>
    );
}


