import React, { useState } from "react";
import {
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  addDomain,
} from "../../../api/Services/api";
import { useTranslation } from "react-i18next";

export default function AddModal({ toggle, getDomains }) {
    const { t, i18n } = useTranslation();
    const [creatingDomain, setCreatingDomain] = useState(false);
    const [apiError, setApiError] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState("");
  
    const createDomain = () => {
      setCreatingDomain(true);
      if (name === "" || status === "" || description === "") {
        setApiError(t("(*) fields are mandatory."));
        setCreatingDomain(false);
      } else {
        const body = {
          name: name,
          description: description,
          status: status,
        };
  
        addDomain(body)
          .then((res) => {
            setCreatingDomain(false);
            toggle();
            getDomains();
          })
          .catch((err) => {
            setCreatingDomain(false);
          });
      }
    };
  
    return (
      <>
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              {t("Name")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <input
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                paddingLeft: "10px",
                height: "33px",
              }}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
        </Row>
  
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              {t("Description")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Col>
        </Row>
  
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label htmlFor="lastName" style={{ display: "block", width: "100%" }}>
              {t("State")}
              <p style={{ color: "red", display: "inline" }}> *</p>
            </label>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "0.9rem",
                  }}
                >
                  {status ? status : "Select"}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px", width: "100%" }}>
                <DropdownItem onClick={() => setStatus("Active")}>
                  {t("Active")}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => setStatus("Inactive")}>
                  {t("Inactive")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
  
        {apiError && <p style={{ color: "red", fontSize: "12px" }}>{apiError}</p>}
        <ButtonToggle
          onClick={createDomain}
          disabled={creatingDomain}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {creatingDomain ? (
            <Spinner
              animation="border"
              style={{ color: "white", margin: "4px" }}
              size="sm"
            />
          ) : (
            <span style={{ color: "#fff" }}>{t("Add")} </span>
          )}
        </ButtonToggle>
      </>
    );
}

