import React, { useState, useEffect } from "react";

import Router from "./App/Navigation/Router";

import { AppContext } from "./App/lib/contextLib";

import { Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";

// import { Amplify } from "aws-amplify";

import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
    }

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <Router />
      </AppContext.Provider>
    )
  );
}

export default App;
