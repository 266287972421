import React, { useState } from "react";
import { Row, Col, Table, Pagination, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { baseUrl } from "../../../config";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { getWalletBalanceRecords } from "../../../api/WalletBalance/api";
import { useTranslation } from "react-i18next";
import ModalLayout from "./ModalLayout";

const WalletBalance = () => {
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [currRecord, setCurrRecord] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = React.useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [noOfPages, setNoOfPages] = useState([]);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    setNoOfPages([]);
    setLoading(true);
    getWalletBalanceRecords(startDate, endDate, activePage, limit)
      .then((res) => {
        if (res.data.success) setTableData(res.data.data);
        setLoading(false);
        let num = res.data.count / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          for (let i = 0; i < num; i++) {
            setNoOfPages((oldArray) => [...oldArray, i + 1]);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [startDate, endDate, activePage, limit]);

  const exportCsv = () => {
    saveAs(
      `${baseUrl}/wallet/1/customers/wallet/upload/exportCSV?startDate=${startDate}&endDate=${endDate}&status=APPROVED`,
      ""
    );
  };

  if (loading) {
    return (
      <span className="spinnerContainer">
        <Spinner animation="border" variant="primary" />
      </span>
    );
  }

  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader">
        <Row style={{ width: "100%" }}>
          <Col
            xl={3}
            lg={3}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: "1.5rem",
                  marginLeft: "1rem",
                }}
              >
                {t("Wallet Balance")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
            <UncontrolledDropdown
              style={{ width: "100%", visibility: "hidden" }}
            >
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span className="showAllSpan">{t("Show All")}</span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem>{t("Another Action")}</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>{t("Another Action")}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "30%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>
            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => {
                  setActivePage(1);
                  setLimit(10);
                  // if (e.target.value === "") setStartDate(today);
                  // else
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>
            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => {
                  setActivePage(1);
                  setLimit(10);
                  // if (e.target.value === "") setEndDate(today);
                  // else
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>

      {/* Table Content */}
      <Table
        style={{
          cursor: "pointer",
          marginTop: "25px",
          backgroundColor: "#f7f7f7",
          borderRadius: "25px",
        }}
        borderless={true}
        hover
      >
        <thead>
          <tr>
            <th style={{ padding: "20px", fontSize: "14px" }}>
              {t("Date & Time")}
            </th>
            <th style={{ padding: "20px", fontSize: "14px" }}>
              {t("Transaction ID")}
            </th>
            <th style={{ padding: "20px", fontSize: "14px" }}>{t("Name")}</th>
            <th style={{ padding: "20px", fontSize: "14px" }}>
              {t("Wallet Number")}
            </th>
            <th style={{ padding: "20px", fontSize: "14px" }}>
              {t("Description")}
            </th>
            <th style={{ padding: "20px", fontSize: "14px" }}>
              {t("Amount Add")}
            </th>
            <th style={{ padding: "20px", fontSize: "14px" }}>
              {t("Wallet Balance")}
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr
              onClick={() => {
                setCurrRecord(item);
                toggle();
              }}
              style={{
                backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                fontSize: "12px",
                color: "#4f4f4f",
                fontFamily: "sans-serif",
              }}
            >
              <td style={{ padding: "20px" }}>
                {new Date(item.createdAt).toLocaleDateString()}
                {" - "}
                {new Date(item.createdAt).toLocaleTimeString()}
              </td>
              <td style={{ padding: "20px" }}>{item.transactionId}</td>
              <td
                style={{ padding: "20px" }}
              >{`${item.customerDetails.firstName} ${item.customerDetails.surName}`}</td>
              <td style={{ padding: "20px" }}>{item.walletId}</td>
              <td style={{ padding: "20px" }}>
                {`You have added ${item.amount} AKZ in the wallet`}
              </td>
              <td style={{ padding: "20px" }}>
                {new Intl.NumberFormat().format(item.amount)} AKZ
              </td>
              <td style={{ padding: "20px" }}>
                {new Intl.NumberFormat().format(
                  item.customerDetails.walletBalance
                )}{" "}
                AKZ
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div style={{ height: "100px" }}>
        <Row style={{ display: "block", paddingBottom: "0px" }}>
          <div>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* Pagination */}
                <Pagination>
                  {noOfPages.map((item, index) => (
                    <Pagination.Item
                      onClick={() => {
                        setActivePage(item);
                      }}
                      active={activePage === item}
                      key={index}
                    >
                      {item}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </div>
              {/* No of products on page toggler */}
              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <UncontrolledDropdown style={{ width: "100%" }}>
                  <DropdownToggle
                    style={{
                      backgroundColor: "#fff",
                      color: "#333",
                      borderColor: "#CED4DA",
                      fontSize: "0.9rem",
                      width: "100%",
                      marginTop: "2px",
                    }}
                    caret
                  >
                    <span
                      style={{
                        color: "#333",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("Items per page")}
                    </span>
                    <span
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      {limit}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu style={{ marginTop: "5px" }}>
                    <DropdownItem
                      onClick={() => {
                        setLimit(1);
                        setActivePage(1);
                      }}
                    >
                      1
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setLimit(5);
                        setActivePage(1);
                      }}
                    >
                      5
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setLimit(10);
                        setActivePage(1);
                      }}
                    >
                      10
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      {/* Transactions Details Modal */}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <ModalLayout item={currRecord} />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default WalletBalance;
