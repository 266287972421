import instance from "./axios";

export const getRefundOrders = async (
  limit,
  activePage,
  startDate,
  endDate,
  selectedStatus,
  selectedMethod
) => {
  try {
    return instance.get(
      `/orders/refund?limit=${limit}&page=${activePage}&orderId=&customerId=&startDate=${startDate}&endDate=${endDate}&status=${selectedStatus}&paymentMethod=${selectedMethod}`
    );
  } catch (err) {}
};

export const updateRefundOrder = async (id) => {
  try {
    return instance.put(`/orders/refund`, {
      id: id,
    });
  } catch (err) {}
};
