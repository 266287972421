import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import noDataFound from "../../Assets/images/nodata.png";
import {
  getDeliveryZones,
} from "../../../api/DeliveryZones/api";
import DetailsModal from "./DetailsModal";
import DeleteModal from "./DeleteModal";

const DeliveryZones = () => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([0, 1, 2, 4]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = useState(false);

  const [currItemId, setCurrItemId] = useState("");
  // del modal
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const [currItem, setCurrItem] = useState("");

  const [limit, setLimit] = useState(10);
  const [noOfPages, setNoOfPages] = useState([]);

  // this effect only geting/updating total no of pages to show for pagination
  React.useEffect(() => {
    // axios
    //   .get(`${baseUrl}/delivery-zones`)
    getDeliveryZones()
      .then((res) => {
        let num = res.data.result.length / 10;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          const tempArray = [];
          for (let i = 0; i < num; i++) {
            tempArray.push(i + 1);
          }
          setNoOfPages(tempArray);
        }
      })
      .catch((err) => {
      });
  }, [activePage]);

  // fetching data
  React.useEffect(() => {
    setLoading(true);
    setNoOfPages([]);
    // axios
    //   .get(`${baseUrl}/delivery-zones?page=${activePage}&limit=10`)
    getDeliveryZones(activePage, 10)
      .then((res) => {
        setLoading(false);
        setTableData(res.data.result);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [activePage]);

  const getUpdatedData = () => {
    setNoOfPages([]);

    setLoading(true);
    // axios
    //   .get(`${baseUrl}/delivery-zones`)
    getDeliveryZones()
      .then((res) => {
        setLoading(false);
        setTableData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <span className="spinnerContainer">
        <Spinner animation="border" variant="primary" />
      </span>
    );
  }

  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader">
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingTop: "20px",
            paddingRight: "20px",
          }}
        >
          <Col
            xl={3}
            lg={3}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div>
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                {t("Delivery Zones")}
              </span>
            </div>
          </Col>
          {/* //TODO: add button here */}
          {/* <Col>
                <Button>click</Button>
              </Col> */}
        </Row>
      </div>

      {tableData.length <= 0 && (
        <div style={{ textAlign: "center" }}>
          <img src={noDataFound} width="200px" />
          <p>{t("No data available")}</p>
        </div>
      )}

      {/* table content */}
      {tableData.length > 0 && (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "16px" }}>{t("Date")}</th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Seller/Company Name")}
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Seller/Company Phone")}
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Title")}
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Price")}
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Delivery time")} (Est.)
              </th>
              <th style={{ padding: "20px", fontSize: "16px" }}>
                {t("Status")}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.slice(0, limit).map((item, index) => (
              <tr
                onClick={() => {
                  setCurrItem(item);
                  toggle();
                }}
                style={{
                  backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                  fontSize: "12px",
                  color: "#4f4f4f",
                  fontFamily: "sans-serif",
                }}
              >
                <td style={{ padding: "20px" }}>
                  {new Date(item.createdAt).toDateString()}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.sellerData?.businessInfo?.storeName}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.sellerData?.businessInfo?.phoneNumber}
                </td>
                <td style={{ padding: "20px" }}>{item.title}</td>
                <td style={{ padding: "20px" }}>
                  {new Intl.NumberFormat().format(item.price)} AKZ
                </td>
                {/* <td style={{ padding: "20px" }}>{item.est} day(s)</td> */}
                <td style={{ padding: "20px" }}>
                  {item.est > 0 ? `${item.est} day(s)` : t("Same day")}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.status === "approved" ? t("Active") : t("Inactive")}
                </td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrItemId(item._id);
                    toggleDeleteModal();
                  }}
                  style={{
                    padding: "20px",
                    color: "red",
                    textDecoration: "underline",
                  }}
                >
                  {t("Delete")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {/* below table */}
      {tableData.length > 0 && (
        <div style={{ height: "100px" }}>
          <Row style={{ display: "block", paddingBottom: "0px" }}>
            <div>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pagination>
                    {noOfPages.map((item, index) => (
                      <Pagination.Item
                        onClick={() => {
                          setLimit(10);
                          setActivePage(item);
                        }}
                        active={activePage === item}
                      >
                        {item}
                      </Pagination.Item>
                    ))}
                  </Pagination>
                </div>
                <div
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <UncontrolledDropdown style={{ width: "100%" }}>
                    <DropdownToggle
                      style={{
                        backgroundColor: "#fff",
                        color: "#333",
                        borderColor: "#CED4DA",
                        fontSize: "0.9rem",
                        width: "100%",
                      }}
                      caret
                    >
                      <span
                        style={{
                          color: "#333",
                          fontSize: "0.7rem",
                        }}
                      >
                        {t("Items per page")}
                      </span>
                      <span
                        style={{
                          marginLeft: "5px",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {limit}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu style={{ marginTop: "5px" }}>
                      <DropdownItem onClick={() => setLimit(1)}>1</DropdownItem>
                      <DropdownItem onClick={() => setLimit(5)}>5</DropdownItem>
                      <DropdownItem onClick={() => setLimit(10)}>
                        10
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Col>
            </div>
          </Row>
        </div>
      )}
      {/* delete modal */}
      <Modal size="sm" centered isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>
          {t("Are you sure to delete?")}
        </ModalHeader>
        <ModalBody>
          <DeleteModal
            toggle={toggleDeleteModal}
            currId={currItemId}
            getUpdatedData={getUpdatedData}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>

      {/* modal */}
      <Modal isOpen={modal} toggle={toggle} className="position-relative">
        <AiFillCloseCircle
          onClick={toggle}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "red",
            cursor: "pointer",
          }}
          size={"1.5rem"}
        />
        <ModalHeader>{t("Details")}</ModalHeader>
        <ModalBody className="px-5">
          <DetailsModal
            item={currItem}
            toggle={toggle}
            getUpdatedData={getUpdatedData}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeliveryZones;
