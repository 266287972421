import React, { useState} from "react";
import {
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { v4 as uuid } from "uuid";

import {
  ButtonToggle,
} from "reactstrap";
import Select from "react-select";
import { Storage } from "aws-amplify";
import {
  addBlog
} from "../../../api/Blogs/api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

export default function CreateAdModal({ toggle, options }) {
    const { t, i18n } = useTranslation();
  
   
  
    // path of the uploaded image
    const [path, setPath] = useState(null);
  
    // fields
    const [currCat, setCurrCat] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageUrl, setImageUrl] = useState("");
  
    const [showInfo, setShowInfo] = useState(false);
  
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [draftLoading, setDraftLoading] = useState(false);
  
    // called on image upload
    const onFileChange = async (e) => {
      const filePath = URL.createObjectURL(e.target.files[0]);
      setPath(filePath);
      try {
        await Storage.put(e.target.files[0].name, e.target.files[0]).then(
          (res) => {
            setImageUrl(`${process.env.REACT_APP_S3_BUCKET}/public/${res.key}`);
          }
        );
      } catch (error) {
      }
    };
    // publishBlog
    const publishBlog = () => {
      setLoading(true);
      setError("");
      if (currCat === "" || title === "" || description === "") {
        setError(t("(*) fields are mandatory."));
        setLoading(false);
      } else {
        const body = {
          title,
          image: imageUrl,
          categoryId: currCat,
          description,
          status: "active",
        };
        addBlog(body)
          .then((res) => {
            setLoading(false);
            toggle();
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    };
  
    // draftBlog
    const draftBlog = () => {
      setDraftLoading(true);
      setError("");
      if (currCat === "" || title === "" || description === "") {
        setError(t("(*) fields are mandatory."));
        setDraftLoading(false);
      } else {
        const body = {
          image: imageUrl,
          title,
          categoryId: currCat,
          description,
          status: "inactive",
        };
        addBlog(body)
          .then((res) => {
            setDraftLoading(false);
          })
          .catch((error) => {
            setDraftLoading(false);
          });
      }
    };
  
    const modules = {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
    };
  
    return (
      <>
        <Row>
          <Col>
            <label
              style={{ display: "block", fontSize: "12px", fontWeight: "bold" }}
            >
              {t("Select Post Image")}
            </label>
          </Col>
          <Col>
            <label
              style={{ display: "block", fontSize: "12px", fontWeight: "bold" }}
            >
              {t("Category")}*
            </label>
          </Col>
        </Row>
        <Row className="align-items-start">
          <Col>
            {!path ? (
              <>
                <input
                  style={{ display: "none" }}
                  id="imageUpload"
                  type="file"
                  multiple
                  onClick={() => setShowInfo(false)}
                  onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="imageUpload" className="imgUploadLbl">
                  <span>+</span>
                  <p>{t("Add Image")}</p>
                </label>
              </>
            ) : (
              <>
                <img
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}
                  htmlFor="imgUpload"
                  src={path}
                  width={65}
                  height={65}
                  style={{ borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setPath(null)}
                  alt=""
                />
                <p
                  style={{
                    visibility: showInfo ? "visible" : "hidden",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  *{t("click to delete image")}*
                </p>
              </>
            )}
          </Col>
          <Col className="mt-1">
            <Select
              options={options}
              onChange={({ value }) => setCurrCat(value)}
            />
          </Col>
        </Row>
  
        <Row
          style={{
            marginTop: "2%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{
                display: "block",
                width: "100%",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("Title")}*
            </label>
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              maxLength={"256"}
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                padding: "6px",
              }}
              id="title"
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <Col md={12}>
            <label
              htmlFor="lastName"
              style={{ display: "block", fontSize: "12px", fontWeight: "bold" }}
            >
              {t("Description")}*
            </label>
            <ReactQuill
              modules={modules}
              value={description}
              onChange={(value) => setDescription(value)}
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                overflow: "hidden",
                height: "300px",
              }}
            />
            {/* <TextareaAutosize
              style={{
                width: "100%",
                border: "1px solid #CED4DA",
                borderRadius: "4px",
                overflow: "hidden",
                height: "300px",
              }}
              id="description"
              aria-label="minimum height"
              rowsMin={3}
              maxLength={"5000"}
              placeholder={"\n\n\n"}
            /> */}
          </Col>
        </Row>
        {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
        <Row>
          <Col>
            <ButtonToggle
              onClick={draftBlog}
              disabled={draftLoading ? true : false}
              style={{
                margin: "20px 0px",
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "#fff" }}>
                {draftLoading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  t("Draft")
                )}{" "}
              </span>
            </ButtonToggle>
          </Col>
          <Col>
            <ButtonToggle
              disabled={loading ? true : false}
              onClick={publishBlog}
              style={{
                margin: "20px 0px",
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "#fff" }}>
                {loading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  t("Publish")
                )}{" "}
              </span>
            </ButtonToggle>
          </Col>
        </Row>
      </>
    );
}

