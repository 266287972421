import React, { useState } from "react";
import {
  Row,
  Col
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  updateDeliveryStatus,
} from "../../../api/DeliveryZones/api";

export default function DetailsModal({ item, toggle, getUpdatedData }) {
    const { t, i18n } = useTranslation();
    // react-select styles
    const customStyles = {
      option: (defaultStyles) => ({
        ...defaultStyles,
        fontSize: "12px",
      }),
      singleValue: (defaultStyles) => ({
        ...defaultStyles,
        fontSize: "12px",
      }),
  
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          fontSize: "12px",
        };
      },
    };
  
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const updateStatus = () => {
      setLoading(true);
      // axios
      //   .put(`${baseUrl}/delivery-zones/${item._id}`, {
      //     status,
      //   })
      updateDeliveryStatus(item._id, status)
        .then((res) => {
          setLoading(false);
          toggle();
          getUpdatedData();
        })
        .catch((err) => {
          setLoading(false);
        });
    };
  
    return (
      <div>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Date")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {new Date(item.createdAt).toDateString()}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Title")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {item.title}
            </span>
          </Col>
        </Row>
  
        {/* <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              Coordinates
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              ({item.latitude}, {item.longitude})
            </span>
          </Col>
        </Row> */}
  
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Price")}
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {new Intl.NumberFormat().format(item.price)} AKZ
            </span>
          </Col>
        </Row>
  
        {/* <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              Radius (Km)
            </span>
          </Col>
          <Col md={6}>
            <span style={{ color: "#333", fontSize: "0.8rem" }}>
              {item.radius}
            </span>
          </Col>
        </Row> */}
  
        <Row>
          <Col md={6}>
            <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
              {t("Status")}
            </span>
          </Col>
          <Col md={6}>
            {/* <Select
              styles={customStyles}
              options={[
                { value: "approved", label: "Approve" },
                { value: "pending", label: "Pending" },
              ]}
              onChange={({ value }) => setStatus(value)}
            /> */}
            {item.status === "approved" ? t("Active") : t("Inactive")}
          </Col>
          {/* <Col md={2} className={{ padding: "0px" }}>
            {status !== "" && (
              <button
                disabled={loading}
                type="button"
                style={{
                  backgroundColor: "#049EFF",
                  color: "#fff",
                  border: "none",
                  fontSize: "12px",
                  padding: "8px",
                  borderRadius: "4px",
                  fontWeight: "bold",
                }}
                onClick={updateStatus}
              >
                {loading ? (
                  <Spinner
                    size="sm"
                    style={{ color: "white" }}
                    animation="border"
                  />
                ) : (
                  "update"
                )}
              </button>
            )}
          </Col> */}
        </Row>
      </div>
    );
}

