import instance from "./axios";

export const getWalletBalanceRecords = async (
  startDate = "",
  endDate = "",
  page = 1,
  limit = 10
) => {
  return instance
    .get(
      `/customers/wallet/upload?startDate=${startDate}&endDate=${endDate}&status=APPROVED&page=${page}&limit=${limit}`
    )
    .then((res) => res);
};
