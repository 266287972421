import React, { useState, useEffect } from "react";
import { Row, Col, Table, Pagination, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { baseUrl } from "../../../config";
// import { getWalletTransferData } from "../../../common/api";
import noDataFound from "../../Assets/images/nodata.png";
import Pages from "../../Components/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import {
  customerTransferApproval,
  getWalletTransferData,
} from "../../../api/BankTransferUser/api";

const ModalLayout = ({ toggle, item, getWalletTransferData,loadTransferRequest }) => {
  const { t, i18n } = useTranslation();
  // react-select styles
  const customStyles = {
    option: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: "12px",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: "12px",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "12px",
      };
    },
  };

  const [status, setStatus] = useState({
    value: item.status,
    label: item.status,
  });
  const [loading, setLoading] = useState(false);

  const updateStatus = () => {
    setLoading(true);
    customerTransferApproval(item._id, { status: status.value })
    
      .then((res) => {
        setLoading(false);
        toggle();
        getWalletTransferData();
      })
      .catch((err) => {
        setLoading(false);
      }).finally(
        ()=>{
          toggle(true);
          loadTransferRequest();
        }
      );
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Wallet ID")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.walletId}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Date & Time")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {" "}
            {new Date(item.createdAt).toLocaleDateString()}
            {" - "}
            {new Date(item.createdAt).toLocaleTimeString()}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Bank")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.bankName}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Name")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.customerDetail.firstName} {item.customerDetail.surName}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Phone Number")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {item.customerDetail.phoneNumber}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Amount")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item.amount)} AKZ
          </span>
        </Col>
      </Row>{" "}
      <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Wallet Balance")}
          </span>
        </Col>
        <Col md={6}>
          <span style={{ color: "#333", fontSize: "0.8rem" }}>
            {new Intl.NumberFormat().format(item.customerDetail.walletBalance)}{" "}
            AKZ
          </span>
        </Col>
      </Row>{" "}
      {/* <Row>
        <Col md={6}>
          <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
            {t("Status")}
          </span>
        </Col>
        <Col md={4}>
          <Select
            styles={customStyles}
            value={status}
            options={[{ value: "COMPLETED", label: "COMPLETED" },
            { value: "REJECTED", label: "REJECTED" }
            ]}
            onChange={(item) => setStatus(item)}
          />
        </Col>
        <Col md={2} className={{ padding: "0px" }}>
          {status.value !== item.status && (
            <button
              type="button"
              style={{
                backgroundColor: "#049EFF",
                color: "#fff",
                border: "none",
                fontSize: "12px",
                padding: "8px",
                borderRadius: "4px",
                fontWeight: "bold",
              }}
              onClick={updateStatus}
            >
              {loading ? (
                <Spinner
                  size="sm"
                  style={{ color: "white" }}
                  animation="border"
                />
              ) : (
                t("Update")
              )}
            </button>
          )}
        </Col>
      </Row> */}
      <Row>
  <Col md={6}>
    <span style={{ fontWeight: "bold", color: "#333", fontSize: "1rem" }}>
      {t("Status")}
    </span>
  </Col>
  <Col md={4}>
    {status.value === "PENDING" ? (
      <Select
        styles={customStyles}
        value={status}
        options={[
          { value: "COMPLETED", label: "COMPLETED" },
          { value: "REJECTED", label: "REJECTED" }
        ]}
        onChange={(item) => setStatus(item)}
      />
    ) : (
      <span>{status.value}</span>
    )}
  </Col>
  <Col md={2} className={{ padding: "0px" }}>
    {status.value !== item.status && (
      <button
        type="button"
        style={{
          backgroundColor: "#049EFF",
          color: "#fff",
          border: "none",
          fontSize: "12px",
          padding: "8px",
          borderRadius: "4px",
          fontWeight: "bold",
        }}
        onClick={updateStatus}
      >
        {loading ? (
          <Spinner
            size="sm"
            style={{ color: "white" }}
            animation="border"
          />
        ) : (
          t("Update")
        )}
      </button>
    )}
  </Col>
          </Row>
    </div>
  );
};

const BankTransferUser = () => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const toggle = () => setModal(!modal);
  const [loading, setLoading] = React.useState(true);
  const [currRecord, setCurrRecord] = useState(null);
  const [dropDownValue, setDropDownValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [noOfPages, setNoOfPages] = useState([]);
  const loadTransferRequest= ()=>{
    setNoOfPages([]);
    setLoading(true);
    getWalletTransferData(startDate, endDate, dropDownValue, activePage, limit)
      .then((res) => {
        setTableData(res.data.result);
        setLoading(false);
        let num = res.data.total / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          for (let i = 0; i < num; i++) {
            setNoOfPages((oldArray) => [...oldArray, i + 1]);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  useEffect(() => {
    // setNoOfPages([]);
    // setLoading(true);
    // getWalletTransferData(startDate, endDate, dropDownValue, activePage, limit)
    //   .then((res) => {
    //     
    //     setTableData(res.data.result);
    //     setLoading(false);
    //     let num = res.data.total / limit;
    //     if (num < 1) setNoOfPages([1]);
    //     else {
    //       num = Math.ceil(num);
    //       for (let i = 0; i < num; i++) {
    //         setNoOfPages((oldArray) => [...oldArray, i + 1]);
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     
    //   });
    loadTransferRequest()
  }, [startDate, endDate, dropDownValue, limit, activePage]);

  const exportCsv = () => {
    try {
      saveAs(
        `${baseUrl}/wallet/1/customers/wallet/transfer/exportCSV?startDate=${startDate}&endDate=${endDate}&status=${dropDownValue}`,
        ""
      );
    } catch (e) {
    }
    // axios
    //   .get(
    //     `${baseUrl}/customers/wallet/transfer/exportCSV`
    //   )
    //   .catch((error) => {
    //     
    //   });
  };

  // if (loading) {
  //   return (
  //     <span className="spinnerContainer">
  //       <Spinner animation="border" variant="primary" />
  //     </span>
  //   );
  // }

  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader" style={{ width: "100%" }}>
        <Row style={{ width: "100%" }}>
          <Col
            xl={3}
            lg={3}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: "1.5rem",
                  marginLeft: "1rem",
                }}
              >
                {t("Bank Transfer User")}
              </span>
            </div>
          </Col>
          <Col xl={1} lg={1}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "30%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Status
            </span>
            <UncontrolledDropdown style={{ width: "100%" }}>
              <DropdownToggle
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#CED4DA",
                  color: "#333",
                  fontSize: "0.9rem",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                caret
              >
                <span className="showAllSpan">
                  {dropDownValue === ""
                    ? t("Show All")
                    : dropDownValue?.charAt(0).toUpperCase() +
                      dropDownValue?.slice(1).toLowerCase()}
                </span>
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: "5px" }}>
                <DropdownItem onClick={() => setDropDownValue("")}>
                  {t("Show All")}
                </DropdownItem>
                <DropdownItem onClick={() => setDropDownValue("COMPLETED")}>
                  {t("Completed")}
                </DropdownItem>
                <DropdownItem onClick={() => setDropDownValue("PENDING")}>
                  {t("Pending")}
                </DropdownItem>
                <DropdownItem onClick={() => setDropDownValue("REJECTED")}>
                  {t("Rejected")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>
            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => {
                  setActivePage(1);
                  setLimit(10);
                  // if (e.target.value === "") setStartDate(today);
                  // else
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </Col>

          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>
            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => {
                  setActivePage(1);
                  setLimit(10);
                  // if (e.target.value === "") setEndDate(today);
                  // else
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>

      {/* Table Content */}
      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : tableData.length > 0 ? (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Date & Time")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Transaction ID")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Name")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Wallet Number")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Bank Name")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("IBAN")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Amount")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Phone Number")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Transfer Type")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Expenses STC")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("TAX")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Status")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Wallet Balance")}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr
                onClick={() => {
                  setCurrRecord(item);
                  toggle();
                }}
                style={{
                  backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                  fontSize: "12px",
                  color: "#4f4f4f",
                  fontFamily: "sans-serif",
                }}
              >
                <td style={{ padding: "20px" }}>
                  {" "}
                  {new Date(item.createdAt).toLocaleDateString()}
                  {" - "}
                  {new Date(item.createdAt).toLocaleTimeString()}
                </td>
                <td style={{ padding: "20px" }}>{item.transactionId}</td>
                <td style={{ padding: "20px" }}>
                  {item.customerDetail.firstName} {item.customerDetail.surName}
                </td>
                <td style={{ padding: "20px" }}>{item.walletId}</td>
                <td style={{ padding: "20px" }}>{item.bankName}</td>
                <td style={{ padding: "20px" }}>{item.accountNumber}</td>
                <td style={{ padding: "20px" }}>
                  {new Intl.NumberFormat().format(item.amount)} AKZ
                </td>
                <td style={{ padding: "20px" }}>
                  {item.customerDetail.phoneNumber}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.bankName === "Banco BNI" ? "Intrabank" : "Interbank"}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.commission ? item.commission : "NaN"}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.iva ? item.iva : "NaN"}
                </td>
                <td style={{ padding: "20px" }}>
                  {item.status === "PENDING"
                    ? "Pending"
                    : item.status === "COMPLETED"
                    ? "Completed"
                    : item.status === "REJECTED"
                    ? "Rejected"
                    : item.status
                    }
                </td>
                <td style={{ padding: "20px" }}>
                  {new Intl.NumberFormat().format(
                    item.customerDetail.walletBalance
                  )}{" "}
                  AKZ
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="noDataContainer">
          <img src={noDataFound} width="200px" alt="" />
          <p>{t("No data available")}</p>
        </div>
      )}

      {tableData.length > 0 && (
        <div style={{ height: "100px" }}>
          <Row style={{ display: "block", paddingBottom: "0px" }}>
            <div>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pages
                    noOfPages={noOfPages}
                    activePage={activePage}
                    setActivePage={setActivePage}
                  />
                </div>
                {/* No of products on page toggler */}
                {tableData.length > 0 ? (
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <UncontrolledDropdown style={{ width: "100%" }}>
                      <DropdownToggle
                        style={{
                          backgroundColor: "#fff",
                          color: "#333",
                          borderColor: "#CED4DA",
                          fontSize: "0.9rem",
                          width: "100%",
                          marginTop: "2px",
                        }}
                        caret
                      >
                        <span
                          style={{
                            color: "#333",
                            fontSize: "0.7rem",
                          }}
                        >
                          {t("Items per page")}
                        </span>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {limit}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu style={{ marginTop: "5px" }}>
                        <DropdownItem onClick={() => setLimit(1)}>
                          1
                        </DropdownItem>
                        <DropdownItem onClick={() => setLimit(5)}>
                          5
                        </DropdownItem>
                        <DropdownItem onClick={() => setLimit(10)}>
                          10
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                ) : null}
              </Col>
            </div>
          </Row>
        </div>
      )}
      {/* Transactions Details Modal */}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Transaction Details")}</ModalHeader>
        <ModalBody>
          <ModalLayout
            toggle={toggle}
            item={currRecord}
            getWalletTransferData={getWalletTransferData}
            loadTransferRequest={loadTransferRequest}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default BankTransferUser;
