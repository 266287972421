import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { baseUrl } from "../../../config";
import { Row, Col, Table, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useDebounce } from "use-debounce";
import Pages from "../../Components/Pagination/Pagination";
import {
  fetchAllUsers,
  updateUserStatus,
} from "../../../api/Users/api";
import {getTotalCustomerBalance} from "../../../api/Kpi/api"
import { useTranslation } from "react-i18next";
import ModalLayout from "./ModalLayout";

const Users = ({ increase = true }) => {
  const [tableData, setTableData] = useState();
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const toggle = () => setModal(!modal);
  const [data, setData] = useState(null);
  const [currUser, setCurrUser] = useState(null);
  const [loading, setLoading] = React.useState(true);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [noOfPages, setNoOfPages] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [debounceNumber, setDebounceNumber] = useState("");
  const [number] = useDebounce(debounceNumber, 1000);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [bayqiUserBalance, setBayqiUserBalance] = useState(0);
  const { t, i18n } = useTranslation();

  const fetchUsers = () => {
    setNoOfPages([]);
    setLoading(true);
    // fetch all users
    fetchAllUsers(limit, activePage, startDate, endDate, number).then((res) => {
      if (res.data.success) {
        setData(res.data.data);
        setTotalCustomers(res.data.totalCustomers);
        setLoading(false);
        let num = res.data.totalCustomers / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          for (let i = 0; i < num; i++) {
            setNoOfPages((oldArray) => [...oldArray, i + 1]);
          }
        }
      } else {
        // set error here
      }
    });
  };

  React.useEffect(() => {
    // axios
    //   .get(`${baseUrl}/kpis/customer`)
    getTotalCustomerBalance()
      .then((res) => {
        setBayqiUserBalance(res.data.customerTotalBalance);
      })
      .catch((err) => setBayqiUserBalance(err.message));
  }, []);
  
  React.useEffect(() => {
    fetchUsers();
  }, [limit, activePage, startDate, endDate, number]);

  const updateStatus = () => {
    setUpdateLoading(true);
    updateUserStatus(currUser._id, status)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          setUpdateLoading(false);
          toggle();
          fetchUsers();
          setStatus("");
        } else {
        }
      })
      .catch(() => {
        setUpdateLoading(false);
        setStatus("");
        alert("Failed to Update Status");
      });
  };

  const exportCsv = () => {
    saveAs(`${baseUrl}/customers/1/customers/exportCSV`, "");
  };
  const formattedCurrency = bayqiUserBalance.toLocaleString({
    style: 'currency',
    currency: 'AKZ',
  });

  return (
    <div className="">
      {/* Header */}
      <div className="financesHeader">
        <Row style={{ width: "100%" }}>
          <Col
            xl={4}
            lg={4}
            style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: "1.5rem",
                  marginLeft: "1rem",
                }}
              >
                {t("Users")}
              </span>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>

            <div className="d-grid">
              <Button
                onClick={exportCsv}
                type="button"
                style={{
                  backgroundColor: "#f4f4f4",
                  color: "black",
                  border: "none",
                }}
              >
                {t("Export")}{" "}
                <FiDownload
                  style={{ color: "black", position: "relative", left: "30%" }}
                  size={"1.2rem"}
                />
              </Button>
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                visibility: "hidden",
              }}
            >
              show all
            </span>
            <div class="input-group rounded">
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search Phone"
                aria-label="Search"
                aria-describedby="search-addon"
                value={debounceNumber}
                onChange={(e) => setDebounceNumber(e.target.value)}
              />
            </div>
          </Col>

          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Start Date")}
            </span>
            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={startDate}
                onChange={(e) => {
                  setActivePage(1);
                  // if (e.target.value === "") setStartDate(today);
                  // else
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col xl={2} lg={2}>
            <span style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("End Date")}
            </span>
            <div class="input-group rounded">
              <input
                style={{ fontSize: "0.9rem" }}
                type="date"
                class="form-control rounded"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                value={endDate}
                onChange={(e) => {
                  setActivePage(1);
                  // if (e.target.value === "") setStartDate(today);
                  // else
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>

      {/* Stats card */}
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "25%",
            minHeight: "140px",
            padding: "15px",
            marginTop: "60px",
            borderRadius: "4px",
            cursor: "context-menu",
          }}
          className="bg-white border position-relative"
        >
          <h5 className="text-muted mb-0 card-title">BAYQI USER BALANCE</h5>
          <p className="h2 font-weight-bold mb-0" style={{overflowWrap: "break-word"}}>{formattedCurrency} AKZ</p>

          <p
            className="text-muted text-sm mt-3 mb-0"
            style={{ position: "absolute", bottom: "15px" }}
          >
            {/* <span style={{ marginRight: "2px" }}>
              {increase ? (
                <AiOutlineArrowUp
                  style={{
                    color: "#198754",
                    fontSize: "18px",
                    marginTop: "-5px",
                    stroke: "#198754",
                    strokeWidth: "40px",
                  }}
                />
              ) : (
                <AiOutlineArrowDown
                  style={{
                    fontSize: "18px",
                    marginTop: "-5px",
                    color: "#DC3545",
                    stroke: "#DC3545",
                    strokeWidth: "40px",
                  }}
                />
              )}
            </span>
            <span
              style={{
                marginRight: "5px",
                color: increase ? "#198754" : "#DC3545",
              }}
            >
              3.48
            </span>
            <span className="text-nowrap">last month</span> */}
          </p>
        </div>

        <div
          style={{
            width: "25%",
            minHeight: "140px",
            padding: "15px",
            marginLeft: "10px",
            marginTop: "60px",
            borderRadius: "4px",
            cursor: "context-menu",
          }}
          className="bg-white border position-relative"
        >
          <h5 className="text-muted mb-0 card-title">{t("Total Customers")}</h5>
          <p className="h2 font-weight-bold mb-0">{totalCustomers}</p>

          <p
            className="text-muted text-sm mt-3 mb-0"
            style={{ position: "absolute", bottom: "15px" }}
          >
            {/* <span style={{ marginRight: "2px" }}>
            {increase ? (
              <AiOutlineArrowUp
                style={{
                  color: "#198754",
                  fontSize: "18px",
                  marginTop: "-5px",
                  stroke: "#198754",
                  strokeWidth: "40px",
                }}
              />
            ) : (
              <AiOutlineArrowDown
                style={{
                  fontSize: "18px",
                  marginTop: "-5px",
                  color: "#DC3545",
                  stroke: "#DC3545",
                  strokeWidth: "40px",
                }}
              />
            )}
          </span> */}
          </p>
        </div>
      </div>
      {/* Table Content */}
      {loading ? (
        <span className="spinnerContainer">
          <Spinner animation="border" variant="primary" />
        </span>
      ) : (
        <Table
          style={{
            cursor: "pointer",
            marginTop: "25px",
            backgroundColor: "#f7f7f7",
            borderRadius: "25px",
          }}
          borderless={true}
          hover
        >
          <thead>
            <tr>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Registration Date")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Registration Number")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>{t("Name")}</th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Nationality")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Date of Birth")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Gender")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Identity Type")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Identity Number")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Wallet Number")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Wallet Balance")}
              </th>
              <th style={{ padding: "20px", fontSize: "14px" }}>
                {t("Status")}
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => (
                <tr
                  onClick={() => {
                    setCurrUser(item);
                    toggle();
                  }}
                  style={{
                    backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                    fontSize: "12px",
                    color: "#4f4f4f",
                    fontFamily: "sans-serif",
                  }}
                >
                  <td style={{ padding: "20px" }}>
                    {new Date(item.createdAt).toDateString()}
                  </td>
                  <td
                    style={{ padding: "20px" }}
                  >{`0${item?.regNumber.toString()}`}</td>
                  <td
                    style={{ padding: "20px" }}
                  >{`${item.firstName} ${item.surName}`}</td>
                  <td style={{ padding: "20px" }}>{item.nationality}</td>
                  <td style={{ padding: "20px" }}>
                    {item.dob && new Date(item.dob).toDateString()}
                  </td>
                  <td style={{ padding: "20px" }}>{item.gender}</td>
                  <td style={{ padding: "20px" }}>{item.identificationType}</td>
                  <td style={{ padding: "20px" }}>
                    {item.identificationNumber}
                  </td>
                  <td style={{ padding: "20px" }}>{item.phoneNumber}</td>
                  <td style={{ padding: "20px" }}>
                    {new Intl.NumberFormat().format(item.walletBalance)} AKZ
                  </td>
                  <td style={{ padding: "20px" }}>
                    {item.isActive ? "Active" : "Disabled"}
                  </td>
                  {/* <td style={{ padding: "20px" }}>08 August 2021</td>
                <td style={{ padding: "20px" }}>--</td> */}
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      <div style={{ height: "100px" }}>
        <Row style={{ display: "block", paddingBottom: "0px" }}>
          <div>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* Pagination */}
                <Pages
                  noOfPages={noOfPages}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              </div>
              {/* No of products on page toggler */}
              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <UncontrolledDropdown style={{ width: "100%" }}>
                  <DropdownToggle
                    style={{
                      backgroundColor: "#fff",
                      color: "#333",
                      borderColor: "#CED4DA",
                      fontSize: "0.9rem",
                      width: "100%",
                      marginTop: "2px",
                    }}
                    caret
                  >
                    <span
                      style={{
                        color: "#333",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("Items per page")}
                    </span>
                    <span
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      {limit}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu style={{ marginTop: "5px" }}>
                    <DropdownItem
                      onClick={() => {
                        setLimit(1);
                        setActivePage(1);
                      }}
                    >
                      1
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setLimit(5);
                        setActivePage(1);
                      }}
                    >
                      5
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setLimit(10);
                        setActivePage(1);
                      }}
                    >
                      10
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      {/* User Details Modal */}
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("User Details")}</ModalHeader>
        <ModalBody>
          <ModalLayout
            data={currUser}
            updateStatus={updateStatus}
            setStatus={setStatus}
            status={status}
            updateLoading={updateLoading}
          />
        </ModalBody>
        <div style={{ height: "5px", width: "100%" }} />
      </Modal>
    </div>
  );
};

export default Users;
