import React, { useState } from "react";
import {
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  ButtonToggle,
} from "reactstrap";
import {
  deleteDeliveryZones,
} from "../../../api/DeliveryZones/api";

export default function DeleteModal({ toggle, currId, getUpdatedData }) {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const deleteItem = () => {
      setLoading(true);
      // axios
      //   .delete(`${baseUrl}/delivery-zones/${currId}`)
      deleteDeliveryZones(currId)
        .then((res) => {
          setLoading(false);
          toggle();
          getUpdatedData();
        })
        .catch((err) => {
          setLoading(false);
        });
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonToggle
          onClick={deleteItem}
          disabled={loading}
          style={{
            margin: "20px 0px",
            backgroundColor: "#E65B65",
            borderWidth: 0,
            marginRight: "10px",
          }}
        >
          {loading ? (
            <Spinner animation="border" style={{ color: "white" }} size="sm" />
          ) : (
            t("Yes")
          )}
        </ButtonToggle>
  
        <ButtonToggle
          onClick={toggle}
          style={{
            margin: "20px 0px",
            backgroundColor: "#049EFF",
            borderWidth: 0,
          }}
        >
          {t("No")}
        </ButtonToggle>
      </div>
    );
}

