import React, { useState, useEffect } from "react";
import { Row, Col, Table, Modal, Spinner } from "react-bootstrap";
import {
  UncontrolledDropdown,
  ButtonToggle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Button from "react-bootstrap/Button";
import { blue } from "../../Globals/colors";
import noDataFound from "../../Assets/images/nodata.png";
import Select from "react-select";

import { deletePostOffice } from "../../../api/BayqiLogistics/api";
import { useTranslation } from "react-i18next";

const DeletePostOfficeModal = ({ toggle, id }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const removePostOffice = () => {
    setLoading(true);
    deletePostOffice(id)
      .then((res) => {
        setLoading(false);
        toggle(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonToggle
        onClick={removePostOffice}
        style={{
          margin: "20px 0px",
          backgroundColor: "#E65B65",
          borderWidth: 0,
          marginRight: "10px",
        }}
      >
        <span style={{ color: "#fff" }}>
          {loading ? <Spinner size="sm" animation="border" /> : t("Yes")}{" "}
        </span>
      </ButtonToggle>

      <ButtonToggle
        onClick={() => toggle()}
        style={{
          margin: "20px 0px",
          backgroundColor: "#049EFF",
          borderWidth: 0,
        }}
      >
        {t("No")}
      </ButtonToggle>
    </div>
  );
};

export default DeletePostOfficeModal;
