import instance from "./axios";

export const fetchAllUsers = async (
  limit = 10,
  page = 1,
  startDate = "",
  endDate = "",
  number = ""
) => {
  try {
    return await instance.get(
      `/customers?limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}&number=${number}`
    );
  } catch (err) {
    return err;
  }
};

export const updateUserStatus = async (id, status) => {
  try {
    return await instance.put(`/customers/${id}`, {
      isActive: status,
    });
  } catch (error) {
    return error;
  }
};

// export const getTotalCustomerBalance = async () => {
//   try {
//     return instance.get(`/kpis/customers`);
//   } catch (err) {
//     return err;
//   }
// };
