import instance from "./axios";

export const addBlog = async (data) => {
  try {
    return instance.post(`/blogs`, data);
  } catch (error) {
    return error;
  }
};

export const getBlogs = async () => {
  try {
    return instance.get(`/blogs?searchBy=`);
  } catch (e) {
    return e;
  }
};

export const updateBlog = (id, data) => {
  return instance.put(`/blogs/${id}`, data).then((res) => res);
};

export const deleteBlog = async (id) => {
  return instance.delete(`/blogs/${id}`).then((res) => res);
};

export const getBlogCategories = async () => {
  try {
    return instance.get(`/blog-categories?searchValue=`);
  } catch (error) {
    return error;
  }
};

export const createBlogCategory = async (catTitle, status) => {
  try {
    return instance.post(`/blog-categories`, {
      title: catTitle,
      status: status,
    });
  } catch (error) {
    return error;
  }
};

export const deleteBlogCategory = async (id) => {
  try {
    return instance.delete(`/blog-categories/${id}`);
  } catch (error) {
    return error;
  }
};

export const updateBlogCategory = async (id, data) => {
  return instance.put(`/blog-categories/${id}`, data).then((res) => res);
};
