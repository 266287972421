import React, { useState, useMemo } from "react";
import {
  Row,
  Col,
  Table,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { baseUrl } from "../../../config";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Pages from "../../Components/Pagination/Pagination";
// import { fetchAllSellers, updateSellerStatus } from "../../../common/api";
import { fetchAllSellers, updateSellerStatus } from "../../../api/Sellers/api";
import StatsCard from "../Finances/StatsCard";
import { useDebounce } from "use-debounce/lib";
import { useTranslation } from "react-i18next";
import ModalLayout from "./ModalLayout";

const Sellers = () => {
  const { t, i18n } = useTranslation();
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const toggle = () => setModal(!modal);
  const [currVendorData, setCurrVendorData] = useState();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [apiFailed, setApiFailed] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [nameSearch, setNameSearch] = useState("");
  const [nameDebounce] = useDebounce(nameSearch, 1000);
  const [storeNameSearch, setStoreNameSearch] = useState("");
  const [storeNameDebounce] = useDebounce(storeNameSearch, 1000);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [noOfPages, setNoOfPages] = useState([]);

  //* cards logic starts here
  const [cardData, setCardData] = React.useState([]);

  const cards = useMemo(() => {
    const cards = cardData.map((card) => {
      return {
        title: card.title,
        amount: card.data,
        ...(card.currency && { currency: card.currency }),
      };
    });

    return cards;
  }, [cardData]);

  //* cards logic ends here

  const [currTab, setCurrTab] = React.useState("individual");
  const tabs = ["individual", "company"];
  const updateStatus = () => {
    setUpdateLoading(true);
    updateSellerStatus(currVendorData._id, status)
      .then((res) => {
        // const { success } = res.data;
        // if (success) {
        toggle();
        setUpdateLoading(false);
        fetchSellers();
        // } else {
        //   
        // }
      })
      .catch(() => alert("got error"));
  };

  const exportCsv = () => {
    saveAs(
      `${baseUrl}/sellers/1/sellers/exportCSV?startDate=${startDate}&endDate=${endDate}&accountType=${currTab}`,
      ""
    );
  };

  const fetchSellers = () => {
    setNoOfPages([]);
    setLoading(true);
    fetchAllSellers(
      currTab,
      startDate,
      endDate,
      activePage,
      limit,
      nameSearch,
      storeNameSearch
    )
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          // set cards data
          setCardData([
            { title: t("Total Sellers"), data: res.data.count },
            {
              title: t("Total Revenue"),
              data: res.data.totalFee,
              currency: true,
            },
          ]);
        }
        setLoading(false);
        let num = res.data.count / limit;
        if (num < 1) setNoOfPages([1]);
        else {
          num = Math.ceil(num);
          for (let i = 0; i < num; i++) {
            setNoOfPages((oldArray) => [...oldArray, i + 1]);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        // setApiFailed(true);
      });
  };

  React.useEffect(() => {
    // setApiFailed(false);
    fetchSellers();
  }, [
    currTab,
    startDate,
    endDate,
    activePage,
    limit,
    nameDebounce,
    storeNameDebounce,
  ]);

  // if (loading) {
  //   return (
  //     <span className="spinnerContainer">
  //       <Spinner animation="border" variant="primary" />
  //     </span>
  //   );
  // }

  return (
    <Tabs
      defaultActiveKey={currTab}
      id="uncontrolled-tab-example"
      className="mb-3"
      onSelect={(value) => setCurrTab(value)}
    >
      {tabs.map((item) => (
        <Tab
          key={item}
          eventKey={item}
          title={item.charAt(0).toUpperCase() + item.slice(1)}
        >
          {loading ? (
            <span className="spinnerContainer">
              <Spinner animation="border" variant="primary" />
            </span>
          ) : (
            <div className="">
              {/* Header */}
              <div className="financesHeader">
                <Row style={{ width: "100%" }}>
                  <Col
                    xl={2}
                    lg={2}
                    style={{
                      padding: "0px",
                      paddingLeft: "24px",
                      margin: "0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          visibility: "hidden",
                        }}
                      >
                        show all
                      </span>

                      <span
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          marginTop: "1.5rem",
                        }}
                      >
                        {currTab === "individual"
                          ? t("Individual")
                          : t("Company")}
                      </span>
                    </div>
                  </Col>
                  <Col xl={2} lg={2}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        visibility: "hidden",
                      }}
                    >
                      show all
                    </span>

                    <div className="d-grid">
                      <Button
                        onClick={exportCsv}
                        type="button"
                        style={{
                          backgroundColor: "#f4f4f4",
                          color: "black",
                          border: "none",
                        }}
                      >
                        {t("Export")}{" "}
                        <FiDownload
                          style={{
                            color: "black",
                            position: "relative",
                            left: "20%",
                          }}
                          size={"1.2rem"}
                        />
                      </Button>
                    </div>
                  </Col>
                  <Col xl={2} lg={2}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("Search By Store Name")}
                    </span>
                    <div class="input-group rounded">
                      <input
                        type="search"
                        class="form-control rounded"
                        placeholder="Store Name"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        onChange={(e) => setStoreNameSearch(e.target.value)}
                        value={storeNameSearch}
                      />
                    </div>
                  </Col>
                  <Col xl={2} lg={2}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("Search By Seller Name")}
                    </span>
                    <div class="input-group rounded">
                      <input
                        type="search"
                        class="form-control rounded"
                        placeholder="Owner Name"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        onChange={(e) => setNameSearch(e.target.value)}
                        value={nameSearch}
                      />
                    </div>
                  </Col>
                  <Col xl={2} lg={2}>
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {t("Start Date")}
                    </span>

                    <div class="input-group rounded">
                      <input
                        style={{ fontSize: "0.9rem" }}
                        type="date"
                        class="form-control rounded"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        value={startDate}
                        onChange={(e) => {
                          setActivePage(1);
                          setLimit(10);
                          // if (e.target.value === "") setStartDate(today);
                          // else
                          setStartDate(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col xl={2} lg={2}>
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {t("End Date")}
                    </span>

                    <div class="input-group rounded">
                      <input
                        style={{ fontSize: "0.9rem" }}
                        type="date"
                        class="form-control rounded"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        value={endDate}
                        onChange={(e) => {
                          setActivePage(1);
                          setLimit(10);
                          // if (e.target.value === "") setEndDate(today);
                          // else
                          setEndDate(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              {/* Stats Cards */}
              <div className="mt-4">
                {cards.map((card) => (
                  <StatsCard {...card} />
                ))}
              </div>
              {/* Table Content */}
              <Table
                style={{
                  cursor: "pointer",
                  marginTop: "25px",
                  backgroundColor: "#f7f7f7",
                  borderRadius: "25px",
                }}
                borderless={true}
                hover
              >
                <thead>
                  <tr>
                    <th style={{ padding: "20px", fontSize: "14px" }}>
                      {t("Registration Date")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "14px" }}>
                      {t("Registration Number")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "14px" }}>
                      {t("Seller ID")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "14px" }}>
                      {t("Account Type")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "14px" }}>
                      {t("Store Name")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "14px" }}>
                      {t("Seller Category")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "14px" }}>
                      {t("Registration Fee")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "14px" }}>
                      {t("Billing Method")}
                    </th>
                    <th style={{ padding: "20px", fontSize: "14px" }}>
                      {t("Account Status")}
                    </th>
                    {/* <th style={{ padding: "20px", fontSize: "14px" }}>
                      {t("Phone Number")}
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr
                        onClick={() => {
                          setCurrVendorData(item);
                          toggle();
                        }}
                        style={{
                          backgroundColor: index % 2 ? "#f2f2f2" : "#fff",
                          fontSize: "12px",
                          color: "#4f4f4f",
                          fontFamily: "sans-serif",
                        }}
                      >
                        <td style={{ padding: "20px" }}>
                          {new Date(item.createdAt).toDateString()}
                        </td>
                        <td style={{ padding: "20px" }}>
                          {item?.regNumber && `${item?.regNumber}`}
                        </td>
                        <td style={{ padding: "20px" }}>
                          {item.businessInfo.phoneNumber}
                        </td>
                        <td style={{ padding: "20px" }}>{item.account}</td>
                        <td style={{ padding: "20px" }}>
                          {item.businessInfo.storeName}
                        </td>
                        <td style={{ padding: "20px" }}>
                          {item.businessCategory}
                        </td>
                        <td style={{ padding: "20px" }}>
                          {item.account === "individual"
                            ? "1,000 AKZ"
                            : "5,000 AKZ"}
                        </td>
                        <td style={{ padding: "20px" }}>
                          {item.bankDetails.bankName}
                        </td>
                        <td style={{ padding: "20px" }}>
                          {item.status
                            ? item.status?.charAt(0).toUpperCase() +
                              item.status?.slice(1).toLowerCase()
                            : "..."}
                        </td>
                        {/* <td style={{ padding: "20px" }}>
                          {item.businessInfo.phoneNumber}
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </Table>

              <div style={{ height: "100px" }}>
                <Row style={{ display: "block", paddingBottom: "0px" }}>
                  <div>
                    <Col
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {/* Pagination */}
                        <Pages
                          noOfPages={noOfPages}
                          activePage={activePage}
                          setActivePage={setActivePage}
                        />
                      </div>
                      {/* No of products on page toggler */}
                      <div
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        <UncontrolledDropdown style={{ width: "100%" }}>
                          <DropdownToggle
                            style={{
                              backgroundColor: "#fff",
                              color: "#333",
                              borderColor: "#CED4DA",
                              fontSize: "0.9rem",
                              width: "100%",
                              marginTop: "2px",
                            }}
                            caret
                          >
                            <span
                              style={{
                                color: "#333",
                                fontSize: "0.7rem",
                              }}
                            >
                              {t("Items per page")}
                            </span>
                            <span
                              style={{
                                marginLeft: "5px",
                                fontWeight: "bold",
                                marginRight: "5px",
                              }}
                            >
                              {limit}
                            </span>
                          </DropdownToggle>
                          <DropdownMenu style={{ marginTop: "5px" }}>
                            <DropdownItem
                              onClick={() => {
                                setLimit(1);
                                setActivePage(1);
                              }}
                            >
                              1
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setLimit(5);
                                setActivePage(1);
                              }}
                            >
                              5
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setLimit(10);
                                setActivePage(1);
                              }}
                            >
                              10
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </Col>
                  </div>
                </Row>
              </div>
              {/* seller Details Modal */}
              <Modal
                centered
                isOpen={modal}
                toggle={toggle}
                onClosed={() => setStatus("")}
              >
                <ModalHeader toggle={toggle}>{t("Seller Details")}</ModalHeader>
                <ModalBody>
                  <ModalLayout
                    data={currVendorData}
                    updateStatus={updateStatus}
                    setStatus={setStatus}
                    status={status}
                    updateLoading={updateLoading}
                  />
                </ModalBody>
                <div style={{ height: "5px", width: "100%" }} />
              </Modal>
            </div>
          )}
        </Tab>
      ))}
    </Tabs>
  );
};

export default Sellers;
