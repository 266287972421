import React, { useState } from "react";
import { baseUrl } from "../../../config";
import axios from "axios";
import { Row, Col, Spinner } from "react-bootstrap";
import { ButtonToggle } from "reactstrap";
import { useTranslation } from "react-i18next";

import { createCommission } from "../../../api/Commisson/api";

const CreateCommissionModal = ({ toggle }) => {
  const { t, i18n } = useTranslation();
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [commission, setCommission] = useState(null);
  const [commissionType, setCommissionType] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const postCommission = () => {
    setLoading(true);
    if (
      name !== "" &&
      type !== "" &&
      commissionType !== "" &&
      commission !== ""
    ) {
      const body = {
        commission_type: type,
        commission_name: name,
        commission_rate: commission,
        type: commissionType,
      };

      // axios
      //   .post(`${baseUrl}/admin/commission`, body)
      createCommission(body)
        .then((res) => {
          toggle(true);
        })
        .catch((err) => {
        });
    } else {
      setError(t("All fields are required"));
    }
  };

  return (
    <>
      <Row>
        <Col>
          <label
            style={{ display: "block", fontSize: "12px", fontWeight: "bold" }}
          >
            {t("Service Type")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <input
            value={type}
            onChange={(e) => setType(e.target.value)}
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            id="service_type"
          />
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Col md={12}>
          <label
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Service Name")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            id="service_name"
          />
        </Col>
        <Col md={12}>
          <label
            htmlFor="lastName"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Commission Type")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <div>
            <input
              id="fixed"
              name="Fixed"
              type="radio"
              value="fixed"
              onChange={(e) => setCommissionType(e.target.value)}
              checked={commissionType === "fixed"}
            />
            <label htmlFor="fixed" style={{ marginLeft: "5px" }}>
              {t("Fixed")}
            </label>
          </div>
          <div>
            <input
              id="percentage"
              name="Percentage"
              type="radio"
              value="percentage"
              onChange={(e) => setCommissionType(e.target.value)}
              checked={commissionType === "percentage"}
            />
            <label htmlFor="percentage" style={{ marginLeft: "5px" }}>
              {t("Percentage")}
            </label>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Col md={12}>
          <label
            htmlFor="lastName"
            style={{
              display: "block",
              width: "100%",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {t("Commission")}
            <p style={{ color: "red", display: "inline" }}> *</p>
          </label>
          <input
            value={commission}
            onChange={(e) => setCommission(e.target.value)}
            type="number"
            style={{
              width: "100%",
              border: "1px solid #CED4DA",
              borderRadius: "4px",
              padding: "6px",
            }}
            id="commission"
          />
        </Col>
      </Row>
      {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
      <Row>
        <Col>
          <ButtonToggle
            disabled={loading}
            onClick={postCommission}
            style={{
              margin: "20px 0px",
              backgroundColor: "#049EFF",
              borderWidth: 0,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "#fff" }}>
              {loading ? <Spinner size="sm" animation="border" /> : t("Save")}
            </span>
          </ButtonToggle>
        </Col>
      </Row>
    </>
  );
};

export default CreateCommissionModal;
