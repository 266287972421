import React from "react";
import { Row, Col } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ArrowBack } from "@material-ui/icons";
import "./OrderDetails.scss";

export default function OrderDetails() {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState();
  React.useEffect(() => {
    
    setData(location.state.details);
  }, []);

  return (
    <div>
      {/* header */}
      <div className="financesHeader pt-3 ps-2">
        <Row className="justify-content-between">
          <Col style={{ padding: "0px", paddingLeft: "24px", margin: "0px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                Order Details
              </span>
            </div>
          </Col>
          <Col className="d-flex justify-content-end me-3">
            <ArrowBack
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
            />
          </Col>
        </Row>
      </div>
      {/* data */}
      <Row style={{ alignItems: "flex-start", justifyContent: "flex-start" }}>
        {/* products col */}
        <Col sm={4} style={{ marginTop: "10px", marginLeft: "20px" }}>
          <p
            style={{
              fontSize: "18px",
              textAlign: "center",
              fontWeight: "bold",
              color: "gray",
              fontFamily: "monospace",
            }}
          >
            Products
          </p>
          {data &&
            data.products.map((item) => {
              return (
                <Row
                  style={{
                    marginBottom: "10px",
                    padding: "10px",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Col>
                    <img
                      src={item.Images ? item.Images[0] : item.image}
                      alt="product"
                      width="40px"
                      height="40px"
                    />
                  </Col>
                  <Col style={{ cursor: "default" }}>
                    <Row>{item.Name}</Row>
                    <Row style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {item.Price
                        ? new Intl.NumberFormat().format(item.Price)
                        : new Intl.NumberFormat().format(item.price)}{" "}
                      AKZ
                    </Row>
                  </Col>
                </Row>
              );
            })}
        </Col>
        {/* shipping details col */}
        <Col sm={1}></Col>
        {data && (
          <Col
            sm={4}
            style={{
              backgroundColor: "#fff",
              paddingLeft: "30px",
              marginRight: "20px",
              marginTop: "10px",
              paddingBottom: "100px",
              borderRadius: "8px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "bold",
                color: "gray",
                fontFamily: "monospace",
                paddingTop: "20px",
              }}
            >
              Delivery Details
            </p>
            <hr />
            <Row className="justify-content-start">
              <p style={{ padding: "0px", margin: "0px", fontSize: "14px" }}>
                Customer name:{" "}
                <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                  {data.customerName}
                </span>
              </p>
            </Row>
            {/* <Row className="justify-content-start">
              <p style={{ padding: "0px", margin: "0px", fontSize: "14px" }}>
                Delivery method:{" "}
                <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                  {data.deliveryMethod}
                </span>
              </p>
            </Row> */}
            <Row className="justify-content-start">
              <p style={{ padding: "0px", margin: "0px", fontSize: "14px" }}>
                Delivery status:{" "}
                <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                  {data.status}
                </span>
              </p>
            </Row>
            <Row className="justify-content-start">
              <p style={{ padding: "0px", margin: "0px", fontSize: "14px" }}>
                Address:{" "}
                <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                  {data.shippingAddress ? data.shippingAddress : "NaN"}
                </span>
              </p>
            </Row>
            <Row className="">
              <p style={{ padding: "0px", margin: "0px", fontSize: "14px" }}>
                Total price:{" "}
                <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                  {new Intl.NumberFormat().format(data.totalPrice)} AKZ
                </span>
              </p>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
}
